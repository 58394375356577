import { IMsalContext, useMsal } from '@azure/msal-react';
import { DefaultButton, Label, MessageBar, MessageBarType, PrimaryButton, Spinner, SpinnerSize, Stack, TextField, useTheme, TooltipHost, Image, Icon, Shimmer, ShimmerElementType, mergeStyles, ThemeProvider, IShimmerElement, FontSizes, Coachmark, DirectionalHint, TeachingBubbleContent, DialogContent, DialogType, Dialog } from '@fluentui/react'
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Constants, PropertyConstants } from '../../../Models/Constants';
import { IDesignSession } from '../../../Models/IDesignSession'
import { IMultiSectionUnitConfiguration, IUnitBaseConfiguration, IUnitConfiguration } from '../../../Models/IUnitConfiguration';
import { generalStackStyles } from '../../../Models/StackStyling';
import { ApiService } from '../../../Services/ApiService';
import { getAppInsights, getFlowTypeUnits, getLocalStorageItem, getPreferredUnits, hasRole, setLocalStorageItem } from '../../../Services/Global';
import { haveHotMediaOutputFields, haveColdMediaOutPutFields, getUnitOperationTargetValueName, getPHEType } from "../../../Utils/UnitConfigurationHelper";
import { Badge } from '@fluentui/react-components';
import { IRangeValidation } from '../../../Models/IRangeValidation';
import { ConditionalTextFieldInputs } from '../../Atom/ConditionalTextFields/ConditionalTextFieldInputs';
import ALICEinstruction from '../.../../../../Assets/ALICEIntegration/ALICEinstruction.png';
import { IPHEPlateType } from '../../../Models/IPHEPlateType';
import { AvailableProductLine } from '../../../Models/Enums';
import { IMultiSectionProperties } from '../../../Models/IMultiSectionProperties';
import { ConfirmationDialog } from "../../../Components/Atom/Dialogs/ConfirmationDialog";
import BeerCoolingWarningImage from '../../../Assets/PNG/beerCoolingWarning.png';
import AditionStepsInfoImage from '../../../Assets/JPEG/CopyXmlInfo.jpeg';
import { ImagePreview } from '../../Atom/image/ImagePreview';

export const ProductDesignForm: React.FC<{ showConditionalFields: boolean }> = (props) => {

    let appInsights = getAppInsights();
    let theme = useTheme();
    const { search } = useLocation();
    let query = useMemo(() => new URLSearchParams(search), [search]);
    let navigate = useNavigate();

    const [showTutorial, setShowTutorial] = useState<boolean>(getLocalStorageItem('showTutorial') === 'true' ? true : false);

    const ctx: IMsalContext = useMsal();
    const apiService = useMemo(() => new ApiService(ctx), [ctx]);

    const [designSession, setDesignSession] = useState<IDesignSession[]>([]);
    const [unitConfiguration, setUnitConfiguration] = useState<IMultiSectionUnitConfiguration>();
    const [amountOfSections, setAmountOfSections] = useState(1);
    const [section, setSection] = useState<IMultiSectionUnitConfiguration>({});
    const [sectionCollection, setSectionCollection] = useState<IMultiSectionUnitConfiguration[]>([{ sectionNumber: 1 }]);
    const [userInputs, setUserInputs] = useState<IMultiSectionProperties[]>();
    const [rangeValidations, setRangeValidations] = useState<IRangeValidation[]>();

    const [coldMediaFlowrate, setcoldMediaFlowrate] = useState<number | undefined>();
    const [coldMediaTempIn, setColdMediaTempIn] = useState<number | undefined>();
    const [coldMediaTempOut, setColdMediaTempOut] = useState<number | undefined>();
    const [coldMediaPressureDrop, setColdMediaPressureDrop] = useState<number | undefined>();

    const [hotMediaTempIn, setHotMediaTempIn] = useState<number | undefined>();
    const [hotMediaTempOut, setHotMediaTempOut] = useState<number | undefined>();
    const [hotMediaPressureDrop, setHotMediaPressureDrop] = useState<number | undefined>();
    const [hotMediaFlowrate, sethotMediaFlowrate] = useState<number | undefined>();

    const [responseReceived, setResponseReceived] = useState(false);
    const [haveColdMediaOutputs, setHaveColdMediaOutputs] = useState(false);
    const [haveHotMediaOutputs, setHaveHotMediaOutputs] = useState(false);

    const [designReceived, setdesignReceived] = useState(false);
    const [formHasErrors, setFormHasErrors] = useState(false);

    const [pageland, setPageland] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isXmlUsable, setIsXmlUsable] = useState(false);
    const [showXmlMessage, setShowXmlMessage] = useState(false);
    const [xmlMessage, setXmlMessage] = useState('This is the initial message\nnew line');
    const [firstInteraction, setFirstInteraction] = useState<Date>();
    const [submitText, setSubmitText] = useState<string>('Calculate');
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [iosContent, setIosContent] = useState('');
    const [showWarningForPHE, setShowWarningForPHE] = useState(false);
    const [disableCopyButton, setDisableCopyButton] = useState(false);
    const [showAdditionalSteps, setShowAdditionalSteps] = useState(false);

    const [warningMessage, setWarningMessage] = useState<JSX.Element>();
    const [hotMediaFlowrateError, sethotMediaFlowrateError] = useState('');
    const [hotMediaTempInError, setHotMediaTempInError] = useState('');
    const [hotMediaTempOutError, setHotMediaTempOutError] = useState('');
    const [temperatureError, setTemperatureError] = useState<string>('');

    const [coldMediaFlowrateError, setcoldMediaFlowrateError] = useState('');
    const [coldMediaTempInError, setColdMediaTempInError] = useState('');
    const [coldMediaTempOutError, setColdMediaTempOutError] = useState('');
    const [coldMediaPressureDropError, setColdMediaMassPressureDropError] = useState('');
    const [hotMediaPressureDropError, setHotMediaPressureDropError] = useState('');

    const [pheTypes, setPHETypes] = useState<IPHEPlateType[]>([]);
    const [flowUnit, setFlowUnit] = useState('');

    const [readOnlyfields, setReadOnlyFields] = useState<string[]>([]);

    const preferredUnit = getPreferredUnits().preferredUnits;
    const temperatureUnit = preferredUnit === 'us' ? '°F' : '°C';
    const pressureUnit = preferredUnit === 'us' ? 'PSI' : 'kPa';
    const [showWarningDialog, setShowWarningDialog] = useState(false);
    const targetButton = useRef<HTMLDivElement>(null);
    const [showInformation, setShowInformation] = useState(true);
    const [inputValues, setInputValues] = useState<{ [key: string]: any }>({});

    useEffect(() => {
        if (!pageland) {
            setPageland(true);
            setDisableCopyButton(false);
            appInsights.trackPageView({ name: 'ProductDesignForm' });
        }
    });

    useEffect(() => {
        validateTemprature();
        formValidation();
    }, [inputValues]);

    useEffect(() => {
        setLocalStorageItem('productdesign', `/productdesign?process=${query.get('process') as string}&productline=${query.get('productline') as string}`);

        async function getUnitConfigurationAsync() {
            const response = await apiService.getAsync(`settings/unitconfiguration/${query.get('process') as string}/${query.get('productline') as string}`);
            if (!response.ok) {
                throw new Error(`Could not get unit configuration from API. ${response.status} - ${response.statusText}`);
            }
            const result: IUnitBaseConfiguration = await response.json();
            setUnitConfiguration(result);

            const sectionResult = await getSectionsAsync(result);

            await getRangeValidations(result, sectionResult);
            await getPHETypes();
            setFlowUnit(getFlowTypeUnits(result.flowType!));
        }

        async function getPHETypes() {
            const response = await apiService.getAsync(`settings/pheplatetypes`);
            if (!response.ok) {
                throw new Error(`Could not get PHE types. ${response.status} ${response.statusText}`);
            }
            const pheTypes: IPHEPlateType[] = await response.json();
            setPHETypes(pheTypes);
        }

        async function getRangeValidations(unitInput: IUnitBaseConfiguration, sections: IMultiSectionUnitConfiguration[]) {
            const response = await apiService.getAsync(`settings/rangeValidation`, unitInput);
            if (!response.ok) {
                throw new Error(`Could not get range configurations from API. ${response.status} - ${response.statusText}`);
            }
            const result = await response.json();
            setRangeValidations(result);
            setDefaultValues(result);
            if (unitInput.isMultiSection) {
                await getPropertySpecs(unitInput, sections, result);
            }

            setInitialLoading(false);
        }

        function getPropRange(propName: string, propRanges: IRangeValidation[]) {
            return (propRanges?.find(v => v.propertyName === propName) as IRangeValidation);
        }

        async function setDefaultValues(propRanges: IRangeValidation[]) {

            if (propRanges !== undefined && propRanges.length > 0) {

                const coldMediaTempOutRange: IRangeValidation = getPropRange(Constants.ColdMediaTempOut, propRanges);
                if (coldMediaTempOutRange !== undefined && coldMediaTempOutRange.greaterThan === coldMediaTempOutRange.lessThan) {
                    setColdMediaTempOut(coldMediaTempOutRange.greaterThan);
                }
                let readOnlies: string[] = [];
                const coldMediaTempInRange: IRangeValidation = getPropRange(Constants.ColdMediaTempIn, propRanges);
                if (coldMediaTempInRange !== undefined && coldMediaTempInRange.greaterThan === coldMediaTempInRange.lessThan) {
                    setColdMediaTempIn(coldMediaTempInRange.greaterThan);
                    readOnlies.push(Constants.ColdMediaTempIn);
                }

                const coldMediaFlowrateRange: IRangeValidation = getPropRange(Constants.ColdMediaFlowrate, propRanges);
                if (coldMediaFlowrateRange !== undefined && coldMediaFlowrateRange.greaterThan === coldMediaFlowrateRange.lessThan) {
                    setcoldMediaFlowrate(coldMediaFlowrateRange.greaterThan);
                    readOnlies.push(Constants.ColdMediaFlowrate);
                }

                const coldMediaPressureDropRange: IRangeValidation = getPropRange(Constants.ColdMediaPressureDrop, propRanges);
                if (coldMediaPressureDropRange !== undefined && coldMediaPressureDropRange.greaterThan === coldMediaPressureDropRange.lessThan) {
                    setColdMediaPressureDrop(coldMediaPressureDropRange.greaterThan);
                    readOnlies.push(Constants.ColdMediaPressureDrop);
                }

                const hotMediaTempOutRange: IRangeValidation = getPropRange(Constants.HotMediaTempOut, propRanges);
                if (hotMediaTempOutRange !== undefined && hotMediaTempOutRange.greaterThan === hotMediaTempOutRange.lessThan) {
                    setHotMediaTempOut(hotMediaTempOutRange.greaterThan);
                    readOnlies.push(Constants.HotMediaTempOut);
                }

                const hotMediaTempInRange: IRangeValidation = getPropRange(Constants.HotMediaTempIn, propRanges);
                if (hotMediaTempInRange !== undefined && hotMediaTempInRange.greaterThan === hotMediaTempInRange.lessThan) {
                    setHotMediaTempIn(hotMediaTempInRange.greaterThan);
                    readOnlies.push(Constants.HotMediaTempIn);
                }

                const hotMediaFlowrateRange: IRangeValidation = getPropRange(Constants.HotMediaFlowrate, propRanges);
                if (hotMediaFlowrateRange !== undefined && hotMediaFlowrateRange.greaterThan === hotMediaFlowrateRange.lessThan) {
                    sethotMediaFlowrate(hotMediaFlowrateRange.greaterThan);
                    readOnlies.push(Constants.HotMediaFlowrate);
                }

                const hotMediaPressureDropRange: IRangeValidation = getPropRange(Constants.HotMediaPressureDrop, propRanges);
                if (hotMediaPressureDropRange !== undefined && hotMediaPressureDropRange.greaterThan === hotMediaPressureDropRange.lessThan) {
                    setHotMediaPressureDrop(hotMediaPressureDropRange.greaterThan);
                    readOnlies.push(Constants.HotMediaPressureDrop);
                }

                setReadOnlyFields(readOnlies);
            }

        }

        async function getSectionsAsync(unitConfiguration: IUnitBaseConfiguration): Promise<IMultiSectionUnitConfiguration[]> {
            const response = await apiService.getAsync(`settings/unitconfigurations/sections`, unitConfiguration);
            if (!response.ok) {
                throw new Error("Couldn't get sections for current unit configuration.");
            }

            const data: IMultiSectionUnitConfiguration[] = await response.json();

            const sortedData = data.sort((a, b) => b.sectionNumber! - a.sectionNumber!)
            setSectionCollection(sortedData);
            setAmountOfSections(sortedData.length);
            setSection(sortedData[0]);

            return sortedData;
        }


        async function getPropertySpecs(unitConfiguration: IMultiSectionUnitConfiguration, sections: IMultiSectionUnitConfiguration[], rangeValidation: IRangeValidation[]) {
            let propData: any = {};

            const propResponse = await apiService.getAsync(`settings/unitPropertySpecifications`, unitConfiguration);
            try {
                propData = await propResponse.json();
            } catch (error) {

            }

            if (!propResponse.ok) {
                setIsLoading(false);
                throw new Error("Couldn't get unit property specifications for current unit configuration.");
            }

            // Declare the multiSecProps variable with the correct type
            let multiSecProps: IMultiSectionProperties[] = [];
            if (propData && propData.userInputProperties && propData.userInputProperties.length > 0) {
                // Iterate over userinput properties
                propData.userInputProperties.forEach((prop: string) => {
                    if (prop !== Constants.UnitOperationTargetValue) {
                        const match = prop.match(/Sec(\d+)/);
                        let trimmedProp = prop.replace(/Sec\d+$/, '');
                        const sectionNumber = match ? match![1] : '';
                        //check if the pro
                        let inputType = prop.toLowerCase().startsWith("hot") ? "hot" : "cold";
                        let mediaType = inputType === 'hot' ?
                            sections.filter(x => x.sectionNumber === parseInt(sectionNumber))[0].hotMediaType
                            :
                            sections.filter(x => x.sectionNumber === parseInt(sectionNumber))[0].coldMediaType;

                        const mappings: { [key: string]: string } = {
                            'TempIn': 'temperature inlet',
                            'TempOut': 'temperature outlet',
                            'Flowrate': 'flowrate',
                            'PressureDrop': 'pressure drop'
                        };

                        let property = prop;

                        for (const key in mappings) {
                            if (prop.includes(key)) {
                                property = `${mappings[key]}`;
                                break;
                            }
                        }

                        let multiProperty: IMultiSectionProperties = {
                            property: property,
                            sectionNumber: parseInt(sectionNumber),
                            name: prop,
                            trimmedName: trimmedProp,
                            flowUnit: '',
                            mediaType: mediaType ? mediaType : '',
                            errorMessage: '',
                            greaterThan: rangeValidation.find(v => v.propertyName === prop)?.greaterThan,
                            lessThan: rangeValidation.find(v => v.propertyName === prop)?.lessThan,
                            isDecimal: rangeValidation.find(v => v.propertyName === prop)?.isDecimal ?? true
                        }

                        if (prop.toLowerCase().includes('flow')) {
                            multiProperty.flowUnit = getFlowTypeUnits(unitConfiguration.flowType!)
                        } else if (prop.toLowerCase().includes('temp')) {
                            multiProperty.flowUnit = temperatureUnit;
                        }
                        multiSecProps.push(multiProperty);
                    }
                    else {

                        let multiProperty: IMultiSectionProperties = {
                            property: sections[0].unitOperationTargetName !== undefined ? getUnitOperationTargetValueName(sections[0].unitOperationTargetName) : "Unit Operation Target Value",
                            sectionNumber: 0,
                            name: Constants.UnitOperationTargetValue,
                            trimmedName: Constants.UnitOperationTargetValue,
                            flowUnit: sections[0].unitOperationTargetValueUnit !== undefined ? sections[0].unitOperationTargetValueUnit : 'Integer',
                            mediaType: '',
                            errorMessage: '',
                            greaterThan: rangeValidation.find(v => v.propertyName === prop)?.greaterThan,
                            lessThan: rangeValidation.find(v => v.propertyName === prop)?.lessThan
                        }
                        multiSecProps.push(multiProperty);
                    }
                });

                setUserInputs(multiSecProps);
            }
        }

        setWarningMessage(<></>);
        setShowWarningForPHE(false);

        getUnitConfigurationAsync();


        localStorage.removeItem('modelSuggestion');
    }, [query])

    function setErrorMessage(lessThan: number, greaterThan: number) {
        return `The value is not within ${lessThan} and ${greaterThan}.`;
    }

    const handleChange = (e: React.FormEvent<HTMLInputElement> | any) => {

        // Hide copy button if an input changes to make it clear that current form values aren't taken into account.
        setdesignReceived(false);
        setShowXmlMessage(false);

        if (firstInteraction === undefined) {
            setFirstInteraction(new Date());
            appInsights.trackEvent({ name: 'useraction:FirstInteraction', properties: { 'timestamp': new Date().toISOString() } });
            appInsights.flush();
        }

        setDisableSubmit(!document.forms[0].checkValidity())

        let val = e.currentTarget.value;
        let inputName = e.currentTarget.name;
        let range: IRangeValidation;
        let inputError: boolean = false;

        setInputValues(prevValues => ({
            ...prevValues,
            [inputName]: val
        }));

        if (!unitConfiguration?.isMultiSection) {

            switch (inputName) {

                case Constants.ColdMediaTempIn:
                    let error = '';
                    if (rangeValidations?.length !== 0) {
                        range = rangeValidations?.find(v => v.propertyName === Constants.ColdMediaTempIn) as IRangeValidation;
                        if (!range.isDecimal && val.indexOf('.') > -1) {
                            inputError = true;
                            setColdMediaTempInError(Constants.VALUE_SHOULD_NOT_BE_DECIMAL);
                        }

                        if (val < range?.lessThan || val > range?.greaterThan) {
                            inputError = true;
                            error = setErrorMessage(range?.lessThan, range?.greaterThan);

                        }

                        setColdMediaTempInError(error);
                    }

                    setColdMediaTempIn(val);
                    validateInputFields()
                    break;
                case Constants.ColdMediaTempOut:
                    if (rangeValidations?.length !== 0) {
                        range = rangeValidations?.find(v => v.propertyName === Constants.ColdMediaTempOut) as IRangeValidation;
                        if (!range.isDecimal && val.indexOf('.') > -1) {
                            inputError = true;
                            setColdMediaTempOutError(Constants.VALUE_SHOULD_NOT_BE_DECIMAL);
                        }
                        else if (val < range?.lessThan || val > range?.greaterThan) {
                            inputError = true;
                            setColdMediaTempOutError(setErrorMessage(range?.lessThan, range?.greaterThan));
                        } else {
                            setColdMediaTempOutError('');
                        }
                    }
                    setColdMediaTempOut(val);
                    validateInputFields()
                    break;
                case Constants.ColdMediaFlowrate:
                    if (rangeValidations?.length !== 0) {
                        range = rangeValidations?.find(v => v.propertyName === Constants.ColdMediaFlowrate) as IRangeValidation;
                        if (!range.isDecimal && val.indexOf('.') > -1) {
                            inputError = true;
                            setcoldMediaFlowrateError(Constants.VALUE_SHOULD_NOT_BE_DECIMAL);
                        }
                        else if (val < range?.lessThan || val > range?.greaterThan) {
                            setcoldMediaFlowrateError(setErrorMessage(range?.lessThan, range?.greaterThan));
                        } else {
                            setcoldMediaFlowrateError('');
                        }
                    }
                    setcoldMediaFlowrate(val);
                    validateInputFields()
                    break;
                case Constants.ColdMediaPressureDrop:
                    if (rangeValidations?.length !== 0) {
                        range = rangeValidations?.find(v => v.propertyName === Constants.ColdMediaPressureDrop) as IRangeValidation;
                        if (!range.isDecimal && val.indexOf('.') > -1) {
                            inputError = true;
                            setColdMediaMassPressureDropError(Constants.VALUE_SHOULD_NOT_BE_DECIMAL);
                        }
                        else if (val < range?.lessThan || val > range?.greaterThan) {
                            inputError = true;
                            setColdMediaMassPressureDropError(setErrorMessage(range?.lessThan, range?.greaterThan));
                        } else {
                            setColdMediaMassPressureDropError('');
                        }
                    }
                    setColdMediaPressureDrop(val);
                    validateInputFields()
                    break;

                case Constants.HotMediaTempIn:
                    if (rangeValidations?.length !== 0) {
                        range = rangeValidations?.find(v => v.propertyName === Constants.HotMediaTempIn) as IRangeValidation;
                        if (!range.isDecimal && val.indexOf('.') > -1) {
                            inputError = true;
                            setHotMediaTempInError(Constants.VALUE_SHOULD_NOT_BE_DECIMAL);
                        }
                        else if (val < range?.lessThan || val > range?.greaterThan) {
                            inputError = true;
                            setHotMediaTempInError(setErrorMessage(range?.lessThan, range?.greaterThan));
                        } else {
                            setHotMediaTempInError('');
                        }
                    }

                    setHotMediaTempIn(val);
                    validateInputFields()
                    break;
                case Constants.HotMediaTempOut:

                    if (rangeValidations?.length !== 0) {
                        range = rangeValidations?.find(v => v.propertyName === Constants.HotMediaTempOut) as IRangeValidation;
                        if (!range.isDecimal && val.indexOf('.') > -1) {
                            inputError = true;
                            setHotMediaTempOutError(Constants.VALUE_SHOULD_NOT_BE_DECIMAL);
                        }
                        else if (val < range?.lessThan || val > range?.greaterThan) {
                            inputError = true;
                            setHotMediaTempOutError(setErrorMessage(range?.lessThan, range?.greaterThan));
                        } else {
                            setHotMediaTempOutError('');
                        }
                    }

                    setHotMediaTempOut(val);
                    validateInputFields()
                    break;
                case Constants.HotMediaFlowrate:
                    if (rangeValidations?.length !== 0) {
                        range = rangeValidations?.find(v => v.propertyName === Constants.HotMediaFlowrate) as IRangeValidation;
                        if (!range.isDecimal && val.indexOf('.') > -1) {
                            inputError = true;
                            sethotMediaFlowrateError(Constants.VALUE_SHOULD_NOT_BE_DECIMAL);
                        }
                        else if (val < range?.lessThan || val > range?.greaterThan) {
                            inputError = true;
                            sethotMediaFlowrateError(setErrorMessage(range?.lessThan, range?.greaterThan));
                        }
                        else {
                            sethotMediaFlowrateError('');
                        }
                    }

                    sethotMediaFlowrate(val);
                    validateInputFields()
                    break;
                case Constants.HotMediaPressureDrop:
                    if (rangeValidations?.length !== 0) {
                        range = rangeValidations?.find(v => v.propertyName === Constants.HotMediaPressureDrop) as IRangeValidation;
                        if (!range.isDecimal && val.indexOf('.') > -1) {
                            inputError = true;
                            setHotMediaPressureDropError(Constants.VALUE_SHOULD_NOT_BE_DECIMAL);
                        }
                        else if (val < range?.lessThan || val > range?.greaterThan) {
                            inputError = true;
                            setHotMediaPressureDropError(setErrorMessage(range?.lessThan, range?.greaterThan));
                        } else {
                            setHotMediaPressureDropError('');
                        }
                    }
                    setHotMediaPressureDrop(val);
                    validateInputFields(inputError)
                    break;
                default:
                    const prop = userInputs?.find(x => x.name === inputName);
                    if (prop) {
                        if (prop.lessThan !== undefined && prop.greaterThan !== undefined) {
                            setUserInputs((prevInputs) =>
                                prevInputs!.map((input) =>
                                    input.name === inputName
                                        ? { ...input, errorMessage: "" }
                                        : input
                                )
                            );
                            if (val !== '' && (parseFloat(val) < prop.lessThan || parseFloat(val) > prop.greaterThan)) {
                                setUserInputs((prevInputs) =>
                                    prevInputs!.map((input) =>
                                        input.name === inputName
                                            ? { ...input, errorMessage: setErrorMessage(prop.lessThan!, prop.greaterThan!) }
                                            : input
                                    )
                                );
                            }
                        }
                    }
                    break;
            }
        }
        else if (unitConfiguration?.isMultiSection) {
            const prop = userInputs?.find(x => x.name === inputName);
            if (prop) {
                setUserInputs((prevInputs) =>
                    prevInputs!.map((input) =>
                        input.name === inputName
                            ? { ...input, errorMessage: '', value: val }
                            : input
                    )
                );
                if (!prop.isDecimal && val.indexOf('.') > -1) {
                    setUserInputs((prevInputs) =>
                        prevInputs!.map((input) =>
                            input.name === inputName
                                ? { ...input, errorMessage: Constants.VALUE_SHOULD_NOT_BE_DECIMAL }
                                : input
                        )
                    );
                }
                else if (prop.lessThan !== undefined && prop.greaterThan !== undefined) {
                    if (val !== '' && (parseFloat(val) < prop.lessThan || parseFloat(val) > prop.greaterThan)) {
                        setUserInputs((prevInputs) =>
                            prevInputs!.map((input) =>
                                input.name === inputName
                                    ? { ...input, errorMessage: setErrorMessage(prop.lessThan!, prop.greaterThan!) }
                                    : input
                            )
                        );
                    }
                }
            }
        }

    }

    function validateTemprature() {
        let erroMsg: string = "";
        const TempApproachHotInletCurr = inputValues[Constants.HotMediaTempIn] - inputValues[Constants.ColdMediaTempOut];
        const TempApproachHotOutletCurr = inputValues[Constants.HotMediaTempOut] - inputValues[Constants.ColdMediaTempIn];
        const DeltaTemperatureColdSideCurr = inputValues[Constants.ColdMediaTempOut] - inputValues[Constants.ColdMediaTempIn];
        const DeltaTemperatureHotSideCurr = inputValues[Constants.HotMediaTempIn] - inputValues[Constants.HotMediaTempOut];

        const TempApproachHotInlet = rangeValidations?.find(x => x.propertyName === PropertyConstants.TempApproachHotInlet);
        const TempApproachHotOutlet = rangeValidations?.find(x => x.propertyName === PropertyConstants.TempApproachHotOutlet);
        const DeltaTemperatureColdSide = rangeValidations?.find(x => x.propertyName === PropertyConstants.DeltaTemperatureColdSide);
        const DeltaTemperatureHotSide = rangeValidations?.find(x => x.propertyName === PropertyConstants.DeltaTemperatureHotSide);

        if (TempApproachHotInlet && !Number.isNaN(TempApproachHotInletCurr) && (TempApproachHotInlet.lessThan !== 0 && TempApproachHotInlet.greaterThan !== 0) && (TempApproachHotInletCurr < TempApproachHotInlet.lessThan || TempApproachHotInletCurr > TempApproachHotInlet.greaterThan)) {
            erroMsg = `${unitConfiguration?.coldMediaType} outlet temp must be between ${TempApproachHotInlet.lessThan}${temperatureUnit} to ${TempApproachHotInlet.greaterThan}${temperatureUnit}  lower than the ${unitConfiguration?.hotMediaType} inlet temperature.`
        }
        if (TempApproachHotOutlet && !Number.isNaN(TempApproachHotOutletCurr) && (TempApproachHotOutlet.lessThan !== 0 && TempApproachHotOutlet.greaterThan !== 0) && (TempApproachHotOutletCurr < TempApproachHotOutlet.lessThan || TempApproachHotOutletCurr > TempApproachHotOutlet.greaterThan)) {
            erroMsg = `${unitConfiguration?.coldMediaType} inlet temp must be between ${TempApproachHotOutlet.lessThan}${temperatureUnit} to ${TempApproachHotOutlet.greaterThan}${temperatureUnit}  lower than the ${unitConfiguration?.hotMediaType} outlet temperature.`
        }
        if (DeltaTemperatureColdSide && !Number.isNaN(DeltaTemperatureColdSideCurr) && (DeltaTemperatureColdSide.lessThan !== 0 && DeltaTemperatureColdSide.greaterThan !== 0) && (DeltaTemperatureColdSideCurr < DeltaTemperatureColdSide.lessThan || DeltaTemperatureColdSideCurr > DeltaTemperatureColdSide.greaterThan)) {
            erroMsg = `${unitConfiguration?.coldMediaType} outlet temp must be between ${DeltaTemperatureColdSide.lessThan}${temperatureUnit} to ${DeltaTemperatureColdSide.greaterThan}${temperatureUnit} higher than the ${unitConfiguration?.coldMediaType} inlet temperature`
        }
        if (DeltaTemperatureHotSide && !Number.isNaN(DeltaTemperatureHotSideCurr) && (DeltaTemperatureHotSide.lessThan !== 0 && DeltaTemperatureHotSide.greaterThan !== 0) && (DeltaTemperatureHotSideCurr < DeltaTemperatureHotSide.lessThan || DeltaTemperatureHotSideCurr > DeltaTemperatureHotSide.greaterThan)) {
            erroMsg = `${unitConfiguration?.hotMediaType} outlet temp must be between ${DeltaTemperatureHotSide.lessThan}${temperatureUnit} to ${DeltaTemperatureHotSide.greaterThan}${temperatureUnit} lower than the ${unitConfiguration?.hotMediaType} inlet temperature.`
        }

        setTemperatureError(erroMsg)
    }

    function validateInputFields(hasError: boolean = false) {
        if (hasError) {
            setFormHasErrors(true);
            return;
        }
        if (coldMediaTempInError !== '' ||
            coldMediaTempOutError !== '' ||
            coldMediaFlowrateError !== '' ||
            coldMediaPressureDropError !== '' ||
            hotMediaTempInError !== '' ||
            hotMediaTempOutError !== '' ||
            hotMediaFlowrateError !== '' ||
            hotMediaPressureDropError !== '' ||
            temperatureError !== ''
        ) {
            setFormHasErrors(true);
        }
        else {
            setFormHasErrors(false);
        }
    }

    function isRelevantInputField(field: string) {
        return unitConfiguration?.inputs?.find(x => x === field) !== undefined;
    }

    function isRelevantOutputField(field: string) {
        return unitConfiguration?.inputs?.find(x => x === field) === undefined && unitConfiguration?.nonUsedProperties?.find(x => x === field) === undefined;
    }

    function renderHelpToolTip() {
        return (
            <Stack>
                <Stack.Item align='center'>
                    <Image style={{ height: 250 }} src={ALICEinstruction} alt="How to use copied content in Alice" />
                </Stack.Item>
                <Stack.Item align='center'>
                    <p style={{ fontSize: 14 }}>After clicking Copy, return to configurator and use the content using "Paste from CAS".</p>
                </Stack.Item>
            </Stack>
        );
    }

    async function submitForm() {
        setShowWarningDialog(false);
        setWarningMessage(<></>);
        setShowWarningForPHE(false);
        setIsLoading(true);
        setdesignReceived(false);
        setShowXmlMessage(false);
        setDisableCopyButton(false);
        let body: any = {}

        body.industry = unitConfiguration?.industry;
        body.subIndustry = unitConfiguration?.subIndustry;
        body.application = unitConfiguration?.application;
        body.subApplication = unitConfiguration?.subApplication;
        body.alfaLavalPosition = unitConfiguration?.alfaLavalPosition;
        body.processStage = unitConfiguration?.processStage;
        body.productLine = unitConfiguration?.productLine;
        body.flowType = unitConfiguration?.flowType;
        body.sectionCount = unitConfiguration?.sectionCount;
        body.sections = [];

        // When creating the body, we check for which fields are used for the selected position.
        // If it is not, then we should not add it to the body.
        if (!unitConfiguration?.isMultiSection) {
            let singleSection: any = { sectionNumber: 1 };

            if (isRelevantInputField(Constants.ColdMediaType)) {
                singleSection.coldMediaType = parseInt(unitConfiguration?.coldMediaType!);
            }
            if (isRelevantInputField(Constants.ColdMediaFlowrate)) {
                singleSection.coldMediaFlowrate = parseInt(coldMediaFlowrate?.toString()!);
            }
            if (isRelevantInputField(Constants.ColdMediaTempIn)) {
                singleSection.coldMediaTempIn = parseFloat(coldMediaTempIn?.toString()!);
            }
            if (isRelevantInputField(Constants.ColdMediaTempOut)) {
                singleSection.coldMediaTempOut = parseFloat(coldMediaTempOut?.toString()!);
            }
            if (isRelevantInputField(Constants.ColdMediaPressureDrop)) {
                singleSection.coldMediaPressureDrop = parseFloat(coldMediaPressureDrop?.toString()!);
            }

            if (isRelevantInputField(Constants.HotMediaType)) {
                singleSection.hotMediaType = parseInt(unitConfiguration?.hotMediaType?.toString()!);
            }
            if (isRelevantInputField(Constants.HotMediaFlowrate)) {
                singleSection.hotMediaFlowrate = parseFloat(hotMediaFlowrate?.toString()!);
            }
            if (isRelevantInputField(Constants.HotMediaTempIn)) {
                singleSection.hotMediaTempIn = parseFloat(hotMediaTempIn?.toString()!);
            }
            if (isRelevantInputField(Constants.HotMediaTempOut)) {
                singleSection.hotMediaTempOut = parseFloat(hotMediaTempOut?.toString()!);
            }
            if (isRelevantInputField(Constants.HotMediaPressureDrop)) {
                singleSection.hotMediaPressureDrop = parseInt(hotMediaPressureDrop?.toString()!);
            }

            body.sections.push(singleSection);
        }
        else if (unitConfiguration?.isMultiSection) {

            const unitOperationTargetValue = userInputs?.find(x => x.name === Constants.UnitOperationTargetValue);

            if (unitOperationTargetValue !== undefined) {
                body.UnitOperationTargetValue = unitOperationTargetValue?.value !== undefined ? parseInt(unitOperationTargetValue?.value) : 0;
            }

            sectionCollection.forEach((section) => {
                const sectionNumber = section.sectionNumber;
                const sectionInputs = userInputs?.filter(x => x.sectionNumber === sectionNumber);

                let multiSectionSection: any = { sectionNumber: sectionNumber };

                sectionInputs?.forEach((input) => {
                    multiSectionSection[input.trimmedName] = input.value ? parseFloat(input.value) : undefined;
                });

                body.sections.push(multiSectionSection);
            });
        }

        const response = await apiService.postAsync(body, `designsuggestion/design`, undefined, () => { setIsLoading(false); setdesignReceived(false) });

        appInsights.trackEvent({ name: 'useraction:DesignSubmit', properties: { 'timestamp': new Date().toISOString() } });
        const result: IDesignSession[] = await response.json();
        setWarningMessageForPHEType(result[0]);
        appInsights.trackEvent({ name: 'useraction:DesignResponse', properties: { 'timestamp': new Date().toISOString() } });
        localStorage.setItem('modelSuggestion', JSON.stringify(result, undefined, 2));
        setDesignSession(result);
        setHaveColdMediaOutputs(haveColdMediaOutPutFields(unitConfiguration!));
        setHaveHotMediaOutputs(haveHotMediaOutputFields(unitConfiguration!));
        setResponseReceived(true);
        setIsLoading(false);
        setdesignReceived(true);
        setSubmitText('Recalculate');
    }

    function setWarningMessageForPHEType(result: IDesignSession) {

        if (unitConfiguration?.productLine === Constants.ProductLine_Industrial) {
            //check model's phe type
            const targetedPheType = pheTypes.filter(c => c.key === result.phePlateType)[0];
            if (targetedPheType != undefined && targetedPheType.availableProductLine == AvailableProductLine.Hygienic) {
                setWarningMessage(
                    <div>
                        <pre>
                            AI Design Wizard has recommended a PHE type that is not available for the Industrial line.
                            <br />It is recommended that you use Hygienic plate-and-frame heat exchanger configurator while copying the model response.
                        </pre>
                    </div>);
                setShowWarningForPHE(true);
            }

        }
        else {
            //check model's phe type
            const targetedPheType = pheTypes.filter(c => c.key === result.phePlateType)[0];
            if (targetedPheType != undefined && targetedPheType.availableProductLine == AvailableProductLine.Industrial) {
                setDisableCopyButton(true);
                setWarningMessage(
                    <div>
                        <pre>
                            AI Design Wizard has recommended a {getPHEType(targetedPheType.key)} which is not available for the Hygienic PHE configurator.
                            <br />Copy the plate pack configuration and given temperatures and flow rates to use {unitConfiguration?.calculationType ? unitConfiguration?.calculationType : ''} in the CAS tool to configure the heat exchanger.
                        </pre>
                    </div>);
                setShowWarningForPHE(true);
            }
        }

        //TO DO: Check if this is required or not
        if (unitConfiguration?.productLine === Constants.ProductLine_Hygienic && unitConfiguration.processStage === "CIP" && unitConfiguration.alfaLavalPosition === "Utility Heater") {
            if (result.phePlateType === "M10M" || result.phePlateType == "dual_inlet_M10M") {
                let body = encodeURIComponent(`Dear support, \n
            Please support with the selection of an appropriate PHE design for the following duty 
            Steam temperature inlet ${result.hotMediaTempIn ?? ' '} ${temperatureUnit ?? ' '}
            Steam temperature outlet ${result.hotMediaTempOut ?? ' '} ${temperatureUnit ?? ' '}
            
            Water flowrate ${result.coldMediaFlowrate ?? ' '} ${pressureUnit ?? ' '}
            Water temperature inlet ${result.coldMediaTempIn ?? ' '} ${temperatureUnit ?? ' '}
            Water temperature outlet ${result.coldMediaTempOut ?? ' '} ${temperatureUnit ?? ' '}

            I have used the AI design support and received the following design suggestion with an PHE type that is marked for obsoletion.
            ${result.phePlateType ?? ' No Phe plate type '} ${result.platePackConfiguration ?? ' No plate pack configuration'}

            Please support with a PHE design from the current product range.`);
                setWarningMessage(
                    <div>
                        <pre>
                            AI Design Wizard has recommended an M10M unit for this duty. M10M is marked as an obsolete unit type.
                            <br />It is recommended that you contact
                            <a href={`mailto:DairyGPHE.Technical@alfalaval.com?subject=Support on PHE design for a dairy CIP utility steam heater&body=${body}`}>DairyGPHE.Technical@alfalaval.com</a> to
                            receive a PHE design from the current product range.
                        </pre>
                    </div>)
                setShowWarningForPHE(true);
            }
        }
    }

    async function copyToClipBoard() {
        setShowAdditionalSteps(false);
        appInsights.trackEvent({ name: 'useraction:DesignCopy', properties: { 'timestamp': new Date().toISOString() } });
        if (localStorage.getItem('modelSuggestion') === null || localStorage.getItem('modelSuggestion') === undefined) {
            setXmlMessage('The output does not match a supported model in ALICE. To configure the heat exchanger fully, use these outputs manually in CAS before proceeding to ALICE.')
            throw new Error('CAS content not found. Nothing was copied to clipboard.');
        }
        else {
            const response = await apiService.postAsync(designSession, `designsuggestion/designxml`);
            if (!response.ok) {
                const res = await response.text();
                throw Error(`Could not send response. ${response.status} ${res}`);
            }
            const result: { responseMessage: string, usable: boolean, xml: string } = await response.json();


            const targetedPheType = pheTypes.filter(c => c.key === designSession[0].phePlateType)[0];
            setIsXmlUsable(targetedPheType.isAvailableInALICE);
            setShowXmlMessage(true);
            if (targetedPheType.isAvailableInALICE) {
                // navigator.clipboard.writeText(result.xml);
                setIosContent(result.xml);
                setXmlMessage(Constants.SuccessMessageForXMLCopy);
                iosCopyToClipboard();
            }
            else {
                // navigator.clipboard.writeText(result.responseMessage);
                setIosContent(result.responseMessage);
                setXmlMessage(!!targetedPheType.informationForUser ?
                    targetedPheType.informationForUser
                    :
                    Constants.XMLCopyDefaultMessage.replace('{pheType}', targetedPheType.text).replace('{calculationMode}', unitConfiguration?.calculationType ?? 'Performance'));
                iosCopyToClipboard();
            }
        }
    }

    function iosCopyToClipboard() {
        const input = document.getElementById('ios-copy') as any;
        const isIOSDevice = navigator.userAgent.match(/ipad|iphone/i);

        if (isIOSDevice) {
            input.setSelectionRange(0, input.value.length);
        } else {
            input.select();
        }

        document.execCommand("copy");
    }

    const stackStyles = generalStackStyles(theme);

    const warmStyling = {
        border: '2px solid ' + theme.palette.redDark,
        margin: 5,
        borderRadius: 5,
        padding: 5
    }

    const coldStyling = {
        border: '2px solid ' + theme.palette.themePrimary,
        margin: 5,
        borderRadius: 5,
        padding: 5
    }

    const wrapperClass = mergeStyles({
        padding: 2,
        selectors: {
            '& > .ms-Shimmer-container': {
                margin: '10px 0',
            },
        },
    });
    const labelShimmer: IShimmerElement[] = [
        { type: ShimmerElementType.line, height: 15, width: '30%', verticalAlign: 'top' },
        { type: ShimmerElementType.gap, height: 15, width: '70%', verticalAlign: 'top' }
    ];
    const valueShimmer: IShimmerElement[] = [
        { type: ShimmerElementType.line, height: 30, width: '100%', verticalAlign: 'top' }
    ];

    if (!hasRole(...['Alice'])) {
        return (
            <Stack horizontalAlign='center' style={{ marginTop: 50 }}>
                <Label style={{ fontSize: FontSizes.large }}>
                    You don't have the right permissions to access this tool.
                </Label>
            </Stack>)
    }



    function calculationWarningMessage() {
        const process = query.get('process') as string
        const singleDecodedString = decodeURIComponent(process);
        const originalString = decodeURIComponent(singleDecodedString);
        return (originalString?.toLowerCase().indexOf(Constants.BEER_COOLING) > -1) ? WARNING_BEER_COOLING_JSX : WARNING_BEER_PASTEURIZER_JSX;
    }

    function calculationWarningImage() {
        const process = query.get('process') as string
        const singleDecodedString = decodeURIComponent(process);
        const originalString = decodeURIComponent(singleDecodedString);
        if (originalString?.toLowerCase().indexOf(Constants.BEER_COOLING) > -1) {
            return <><img src={BeerCoolingWarningImage} style={{ width: '300px', objectFit: 'contain' }} /><br /><div className="al-container-vflex al-container-vcenter mb-1"><ImagePreview imageSource={BeerCoolingWarningImage ?? ''} /></div></>

        }
    }

    function toShowWarning() {
        const process = query.get('process') as string
        const singleDecodedString = decodeURIComponent(process);
        const originalString = decodeURIComponent(singleDecodedString);
        return (showWarningDialog && originalString.toLowerCase().indexOf(Constants.BEER_COOLING) > -1) ? true : false
    }
    const WARNING_BEER_COOLING_JSX = (
        <div dangerouslySetInnerHTML={{ __html: Constants.WARNING_BEER_COOLING_HTML }} />
    );
    const WARNING_BEER_PASTEURIZER_JSX = (
        <div dangerouslySetInnerHTML={{ __html: Constants.WARNING_BEER_PASTEURIZER_HTML }} />
    );

    function isBeerPasteurizerInfo() {
        const process = query.get('process') as string
        const singleDecodedString = decodeURIComponent(process);
        const originalString = decodeURIComponent(singleDecodedString);
        return (originalString?.toLowerCase().indexOf(Constants.BEER_PASTEURIZER) > -1) ? true : false
    }

    function isBeerCoolingInfo() {
        const process = query.get('process') as string
        const singleDecodedString = decodeURIComponent(process);
        const originalString = decodeURIComponent(singleDecodedString);
        return (originalString?.toLowerCase().indexOf(Constants.BEER_COOLING) > -1) ? true : false
    }

    function formValidation() {
        const form = document.getElementById("designForm");
        let isFormFilled = false;
        if (form) {
            form.querySelectorAll("input, textarea").forEach(function (i: any) {
                if (i.value === "") {
                    isFormFilled = true;
                }
            });
        }
        return isFormFilled;
    }

    const ADDITIONAL_STEPS_JSX = (
        <div dangerouslySetInnerHTML={{ __html: Constants.ADDITIONAL_STEPS_HTML }} />
    );

    return (
        <>
            <Stack grow horizontalAlign='center'>
                {
                    initialLoading ?
                        <Spinner style={{ marginTop: 50 }} size={SpinnerSize.large} label='Loading...' />
                        :
                        <>
                            {
                                !unitConfiguration &&
                                <Stack style={{ padding: '50px 20px' }} horizontalAlign='center'>
                                    <Stack.Item>
                                        <h3>
                                            No unit found
                                        </h3>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Label>
                                            Process: {query.get('process')}
                                        </Label>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Label>
                                            Product Line: {query.get('productline')}
                                        </Label>
                                    </Stack.Item>
                                </Stack>
                            }
                            {
                                showTutorial &&
                                <Coachmark
                                    target={targetButton.current}
                                    delayBeforeCoachmarkAnimation={1000}
                                    positioningContainerProps={{ directionalHint: DirectionalHint.leftTopEdge, doNotLayer: false }}
                                    ariaAlertText="A coachmark has appeared"
                                    ariaDescribedBy="coachmark-desc1"
                                    ariaLabelledBy="coachmark-label1"
                                    ariaDescribedByText="Press enter or alt + C to open the coachmark notification"
                                    ariaLabelledByText="Coachmark notification"
                                >
                                    <TeachingBubbleContent
                                        headline="AI Design Wizard"
                                        hasCloseButton
                                        closeButtonAriaLabel="Close"
                                        primaryButtonProps={{ text: 'Learn more', onClick: () => navigate('/about'), }}
                                        secondaryButtonProps={{ text: 'Close', onClick: () => setShowTutorial(false) }}
                                        onDismiss={() => setShowTutorial(false)}
                                        ariaDescribedBy="example-description1"
                                        ariaLabelledBy="example-label1"
                                    >
                                        Welcome to AI Design Wizard. To learn more about this tool, click on the 'Learn more' button.
                                    </TeachingBubbleContent>
                                </Coachmark>
                            }
                            <div ref={targetButton}>

                                {
                                    unitConfiguration &&
                                    <Stack horizontal styles={stackStyles} style={{ maxWidth: 450, width: 450 }}>
                                        <Stack.Item grow={1}>
                                            <Stack horizontal>
                                                <Stack.Item grow style={{ paddingRight: 20 }}>
                                                    <h3>
                                                        {unitConfiguration.alfaLavalPosition}
                                                    </h3>
                                                    <Label>
                                                        {unitConfiguration.productLine}
                                                    </Label>
                                                </Stack.Item>

                                                <Stack.Item>

                                                    <Stack>
                                                        <Stack.Item>
                                                            <Badge style={{ background: theme.palette.redDark, margin: 2 }}>
                                                                Hot media: {unitConfiguration.hotMediaType}
                                                            </Badge>

                                                        </Stack.Item>
                                                        <Stack.Item>
                                                            <Badge style={{ background: theme.palette.themePrimary, margin: 2 }}>
                                                                Cold media: {unitConfiguration.coldMediaType}
                                                            </Badge>
                                                        </Stack.Item>
                                                    </Stack>
                                                </Stack.Item>
                                            </Stack>


                                            <Stack.Item>
                                                <form id="designForm">
                                                    <ConditionalTextFieldInputs
                                                        handleChange={handleChange}
                                                        originPage={Constants.ProductDesignForm}
                                                        propertySpecs={userInputs!}
                                                        unitConfiguration={unitConfiguration}
                                                        coldMediaFlowrate={coldMediaFlowrate?.toString()}
                                                        coldMediaTempIn={coldMediaTempIn?.toString()}
                                                        coldMediaTempOut={coldMediaTempOut?.toString()}
                                                        coldMediaPressureDrop={coldMediaPressureDrop?.toString()}
                                                        hotMediaFlowrate={hotMediaFlowrate?.toString()}
                                                        hotMediaTempIn={hotMediaTempIn?.toString()}
                                                        hotMediaTempOut={hotMediaTempOut?.toString()}
                                                        hotMediaPressureDrop={hotMediaPressureDrop?.toString()}
                                                        coldMediaTempInError={coldMediaTempInError}
                                                        hotMediaFlowrateError={hotMediaFlowrateError}
                                                        hotMediaTempInError={hotMediaTempInError}
                                                        hotMediaTempOutError={hotMediaTempOutError}
                                                        hotMediaPressureDropError={hotMediaPressureDropError}
                                                        coldMediaFlowrateError={coldMediaFlowrateError}
                                                        coldMediaTempOutError={coldMediaTempOutError}
                                                        coldMediaPressureDropError={coldMediaPressureDropError}
                                                        temperatureError={temperatureError}
                                                        readOnlyFields={readOnlyfields}
                                                        addStyling={true}
                                                    />
                                                </form>

                                            </Stack.Item>

                                        </Stack.Item>
                                    </Stack>
                                }
                            </div>

                            {/* RESULTS */}
                            {
                                isLoading &&
                                <Stack grow styles={stackStyles} style={{ width: 270, height: 172 }}>
                                    <ThemeProvider className={wrapperClass}>
                                        <Shimmer shimmerElements={labelShimmer} />
                                        <Shimmer shimmerElements={valueShimmer} />
                                        <Shimmer shimmerElements={labelShimmer} />
                                        <Shimmer shimmerElements={valueShimmer} />
                                    </ThemeProvider>
                                </Stack>
                            }
                            {
                                responseReceived && !isLoading &&
                                <Stack grow styles={stackStyles}>
                                    <Stack>
                                        <Stack.Item>
                                            <Label>PHE Type</Label>
                                            <TextField value={getPHEType(designSession![0].phePlateType)} />
                                        </Stack.Item>
                                        {
                                            designSession.map((section) => {
                                                return (
                                                    <Stack.Item key={section.sectionNumber}>
                                                        <Label>Plate Pack Configuration{designSession.length > 1 ? " Section " + section.sectionNumber : ''}</Label>
                                                        <TextField value={section.platePackConfiguration} />
                                                    </Stack.Item>
                                                );
                                            })
                                        }
                                    </Stack>
                                    {props.showConditionalFields &&
                                        <Stack>
                                            {haveHotMediaOutputs &&
                                                <Stack style={warmStyling}>
                                                    {
                                                        isRelevantOutputField(Constants.HotMediaFlowrate) &&
                                                        <Stack.Item>
                                                            <Label>{designSession![0].hotMediaType!} flow rate</Label>
                                                            <TextField value={designSession![0].hotMediaFlowrate} readOnly suffix={flowUnit} />
                                                        </Stack.Item>
                                                    }
                                                    {
                                                        isRelevantOutputField(Constants.HotMediaTempIn) &&

                                                        <Stack.Item>
                                                            <Label>{designSession![0].hotMediaType!} temperature inlet</Label>
                                                            <TextField value={designSession![0].hotMediaTempIn} readOnly suffix={temperatureUnit} />
                                                        </Stack.Item>
                                                    }
                                                    {
                                                        isRelevantOutputField(Constants.HotMediaTempOut) &&
                                                        <Stack.Item>
                                                            <Label>{designSession![0].hotMediaType!} temperature outlet</Label>
                                                            <TextField value={designSession![0].hotMediaTempOut} readOnly suffix={temperatureUnit} />
                                                        </Stack.Item>
                                                    }
                                                    {
                                                        isRelevantOutputField(Constants.HotMediaPressureDrop) &&
                                                        <Stack.Item>
                                                            <Label>{designSession![0].hotMediaType!} pressure drop</Label>
                                                            <TextField value={designSession![0].hotMediaPressureDrop?.toString()} readOnly suffix={pressureUnit} />
                                                        </Stack.Item>
                                                    }
                                                </Stack>
                                            }
                                            {haveColdMediaOutputs &&
                                                <Stack style={coldStyling}>
                                                    {
                                                        isRelevantOutputField(Constants.ColdMediaFlowrate) &&
                                                        <Stack.Item>
                                                            <Label>{designSession![0].coldMediaType!} flow rate</Label>
                                                            <TextField value={designSession![0].coldMediaFlowrate} readOnly suffix={flowUnit} />
                                                        </Stack.Item>
                                                    }
                                                    {
                                                        isRelevantOutputField(Constants.ColdMediaTempIn) &&

                                                        <Stack.Item>
                                                            <Label>{designSession![0].coldMediaType!} temperature inlet</Label>
                                                            <TextField value={designSession![0].coldMediaTempIn} readOnly suffix={temperatureUnit} />
                                                        </Stack.Item>
                                                    }
                                                    {
                                                        isRelevantOutputField(Constants.ColdMediaTempOut) &&

                                                        <Stack.Item>
                                                            <Label>{designSession![0].coldMediaType!} temperature outlet</Label>
                                                            <TextField value={designSession![0].coldMediaTempOut} readOnly suffix={temperatureUnit} />
                                                        </Stack.Item>
                                                    }

                                                    {
                                                        isRelevantOutputField(Constants.ColdMediaPressureDrop) &&
                                                        <Stack.Item>
                                                            <Label>{designSession![0].coldMediaType!} pressure drop</Label>
                                                            <TextField value={designSession![0].coldMediaPressureDrop?.toString()} readOnly suffix={pressureUnit} />
                                                        </Stack.Item>
                                                    }

                                                </Stack>
                                            }
                                        </Stack>
                                    }
                                </Stack>
                            }
                            <Stack horizontalAlign='center'>
                                <Stack.Item>
                                    <PrimaryButton disabled={disableSubmit || isLoading || formValidation() ||
                                        (coldMediaTempInError !== '' ||
                                            coldMediaTempOutError !== '' ||
                                            coldMediaFlowrateError !== '' ||
                                            coldMediaPressureDropError !== '' ||
                                            hotMediaTempInError !== '' ||
                                            hotMediaTempOutError !== '' ||
                                            hotMediaFlowrateError !== '' ||
                                            hotMediaPressureDropError !== '' ||
                                            temperatureError !== '')} style={{ margin: 10, width: 100 }} text={submitText} onClick={() => isBeerCoolingInfo() ? setShowWarningDialog(true) : submitForm()} />
                                    {
                                        isLoading &&
                                        <Spinner size={SpinnerSize.large} label='Calculating design...' />
                                    }
                                    {
                                        designReceived &&
                                        <>
                                            <DefaultButton disabled={isLoading || disableCopyButton} style={{ marginTop: 3, marginBottom: 10, marginLeft: 10, marginRight: 10, width: 100 }} onClick={() => isBeerPasteurizerInfo() ? setShowAdditionalSteps(true) : copyToClipBoard()} >Copy</DefaultButton>
                                            {/* iOS workaround. */}
                                            <input onChange={() => { return; }} style={{ opacity: 0, position: 'fixed', zIndex: -10000 }} id='ios-copy' value={iosContent} />

                                            <TooltipHost content={renderHelpToolTip()} styles={{ root: { display: 'inline-block' } }}>
                                                <Icon style={{ cursor: 'pointer', color: theme.palette.themePrimary, fontSize: 16 }} iconName='Info' />
                                            </TooltipHost>
                                        </>
                                    }
                                </Stack.Item>
                                {
                                    showXmlMessage &&
                                    <Stack.Item align='center'>
                                        <MessageBar messageBarType={isXmlUsable ? MessageBarType.success : MessageBarType.warning} messageBarIconProps={{ iconName: isXmlUsable ? 'CheckMark' : 'Warning' }} >
                                            {xmlMessage}
                                        </MessageBar>
                                    </Stack.Item>
                                }
                                {
                                    showWarningForPHE &&
                                    <Stack.Item align='center'>
                                        <MessageBar messageBarType={MessageBarType.warning} messageBarIconProps={{ iconName: 'Warning' }} >
                                            {warningMessage}
                                        </MessageBar>
                                    </Stack.Item>
                                }
                            </Stack>
                        </>
                }
            </Stack>
            <ConfirmationDialog
                title='Calculation warning'
                content={<DialogContent>{calculationWarningMessage()}</DialogContent>}
                content2={<DialogContent>{calculationWarningImage()}</DialogContent>}
                open={toShowWarning()}
                onAccept={() => submitForm()}
                onDecline={() => setShowWarningDialog(false)}
                acceptText='Confirm and calculate'
                declineText='Cancel'
                bodyStyle={{ display: "block", maxHeight: "80vh", overflow: "auto" }}
            />
            <ConfirmationDialog
                title='Calculation information'
                content={<DialogContent>{calculationWarningMessage()}</DialogContent>}
                open={isBeerPasteurizerInfo() && showInformation}
                onDecline={() => setShowInformation(false)}
                declineText='Ok'
                bodyStyle={{ display: "block", maxHeight: "80vh", overflow: "auto" }}

            />
            <ConfirmationDialog
                title='Additional steps'
                content={<DialogContent>{ADDITIONAL_STEPS_JSX}</DialogContent>}
                content2={<DialogContent>{<div><img src={AditionStepsInfoImage} style={{ width: "300px", objectFit: 'contain' }} /><br /><div className="al-container-vflex al-container-vcenter mb-1"><ImagePreview imageSource={AditionStepsInfoImage ?? ''} /></div></div>}</DialogContent>}
                open={showAdditionalSteps}
                onAccept={() => copyToClipBoard()}
                onDecline={() => setShowAdditionalSteps(false)}
                acceptText='Confirm and copy'
                declineText='Cancel'
                bodyStyle={{ display: "block", maxHeight: "80vh", overflow: "auto" }}
            />

        </>

    )
}


