import dairy from "../../../Assets/SVG/dairy.svg";
import HVAC from "../../../Assets/SVG/HVAC.svg";
import { useNavigate } from 'react-router';

export default function ChooseIndustry() {
  let navigate = useNavigate();

  return (
    <div className="emelie-flex">
      <div
        className="emelie-radio"
        onClick={() => {
          navigate("/ChooseMachine");
        }}
      >
        <div className="emelie-flex-center">
          <img src={dairy} alt="dairy" />
          <div className="emelie-f-24--regular e-pl-30">Dairy</div>
        </div>
        <input
          type="radio"
          value="Dairy"
          name="Dairy"
          className="emelie-radio--input"
        />
      </div>
      <div className="emelie-radio">
        <div className="emelie-flex-center">
          <img src={HVAC} alt="HVAC" />
          <div className="emelie-f-24--regular e-pl-30">HVAC</div>
        </div>
        <input
          type="radio"
          value="Dairy"
          name="Dairy"
          className="emelie-radio--input"
        />
      </div>
    </div>
  );
}
