import { useEffect, useRef } from 'react';
import { TextField, ITextFieldProps } from '@fluentui/react';

const TextFieldNoScroll = (props: ITextFieldProps) => {
    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const div = divRef.current;
        if (div && div.firstChild) {
            const inputElement = div.firstChild.firstChild as HTMLInputElement;
            inputElement.addEventListener('wheel', handleWheel, { passive: false });

            return () => {
                inputElement.removeEventListener('wheel', handleWheel);
            };
        }
    }, []);

    const handleWheel = (e: WheelEvent) => {
        e.preventDefault();
    };

    return (
        <div ref={divRef}>
            <TextField {...props} />
        </div>
    );
};

export default TextFieldNoScroll;