import React from 'react';
import { IMsalContext, useMsal } from "@azure/msal-react";
import { DefaultButton, Dialog, DialogType, IDropdownOption, Icon, IconButton, Label, PrimaryButton, Spinner, SpinnerSize, Stack, Text, TextField, TooltipHost, useTheme } from "@fluentui/react"
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Constants, PropertyConstants } from "../../../Models/Constants";
import { IDataSample } from "../../../Models/IDataSample";
import { IMultiSectionUnitConfiguration, IUnitBaseConfiguration, IUnitConfiguration } from "../../../Models/IUnitConfiguration";
import { generalStackStyles, informationLabelStyle } from "../../../Models/StackStyling";
import { ApiService } from "../../../Services/ApiService";
import { deCapitalizeFirstLetter, getFlowTypeUnits, getPreferredUnits } from "../../../Services/Global";
import { SourceType } from "../../../Utils/SourceType";
import { ConditionalTextFieldInputs } from '../../Atom/ConditionalTextFields/ConditionalTextFieldInputs';
import { ConditionalTextFieldOutputs } from '../../Atom/ConditionalTextFields/ConditionalTextFieldOutputs';
import { PheTypePlatePackInputForm } from '../SME/DesignSessionReview/PheTypePlatePackInputForm';
import { SMEDesignGuidelines } from '../../Atom/SMEDesignGuidelines/SMEDesignGuidelines';
import { Badge } from '@fluentui/react-components';
import { MultiSectionVisualization } from '../../Atom/MultiSectionVisualization/MultiSectionVisualization';
import { IPlatePackConfiguration } from '../../../Models/IPlatePackConfiguration';
import { checkPlatePackMultiSectionValidity } from '../../../Utils/PlatePackConfgiurationHelper';
import { MultiSectionFlowchart } from '../../Atom/MultiSectionVisualization/MultiSectionFlowchart';

export const ModelDataSampleForm: React.FC<{}> = () => {
    let theme = useTheme();
    let navigate = useNavigate();
    const location = useLocation();
    let state = location.state as any;
    let params = useParams();

    const ctx: IMsalContext = useMsal();
    const apiService = useMemo(() => new ApiService(ctx), [ctx]);

    const [unitConfiguration, setUnitConfiguration] = useState<IUnitConfiguration>(state.unitConfiguration as IUnitConfiguration);
    const [multiSectionConfig, setMultiSectionConfig] = useState<IMultiSectionUnitConfiguration[]>([]);
    const [activeIndex, setActiveIndex] = useState(1);
    const [flowChartSelectableProperties, setFlowChratSelectableProperties] = useState<string[]>();
    const activeSection = multiSectionConfig.find(x => x.sectionNumber === activeIndex) ?? {} as IMultiSectionUnitConfiguration;
    const [dataSamples, setDataSamples] = useState<IDataSample[]>([]);
    const [pheTypes, setPheTypes] = useState<IDropdownOption[]>([]);

    const [pheType, setPheType] = useState('');
    const [platePackConfigs, setPlatePackConfigs] = useState<IPlatePackConfiguration[]>([]);
    const [comment, setComment] = useState('');

    const [isLoading, setIsLoading] = useState(true);
    const [copyMessage, setCopyMessage] = useState('');
    const [formIsValid, setFormIsValid] = useState(false);
    const [SMEDesignGuidelineId, setSMEDesignGuidelineId] = useState<bigint>();

    const currentSectionIndex = activeIndex - 1;

    const stackStyles = generalStackStyles(theme);

    async function getDataSamplesAsync() {
        const response = await apiService.getAsync(`settings/unitconfigurations/sections`, unitConfiguration);
        if (!response.ok) {
            throw new Error(`Could not get sections. ${response.status} ${response.statusText}`);
        }

        const sections: IMultiSectionUnitConfiguration[] = await response.json();
        const orderedSections = sections.sort((a, b) => { return b.sectionNumber! - a.sectionNumber! });
        setMultiSectionConfig(orderedSections);
        let selectableProps = orderedSections[0].inputs?.concat(orderedSections[0].outputs ?? []);
        setFlowChratSelectableProperties(selectableProps);
        setPlatePackConfigs(new Array(sections.length).fill({} as IPlatePackConfiguration));

        let currentDataSamples: IDataSample[] = [];

        console.log(unitConfiguration, 'config')
        if (sections[0].isMultiSection === true) {
            const dataSampleResponse = await apiService.getAsync(`modeling/datasamples/sections/${state.dataSample.sectionId}`);
            if (!dataSampleResponse.ok) {
                throw new Error(`Could not get data samples. ${dataSampleResponse.status} ${dataSampleResponse.statusText}`);
            }

            currentDataSamples = await dataSampleResponse.json();
        }
        else {
            const dsResponse = await apiService.getAsync(`modeling/datasamples/${state.dataSample.id}`);
            if (!dsResponse.ok) {
                throw new Error(`Could not get data sample. ${dsResponse.status} ${dsResponse.statusText}`);
            }

            const ds: IDataSample = await dsResponse.json();
            currentDataSamples = [ds];
        }

        console.log('data samples', currentDataSamples);



        let currentConfigs = platePackConfigs;
        for (let i = 0; i < currentDataSamples.length; i++) {
            currentConfigs[i] = {
                cold_1_channels_per_pass_type_1: currentDataSamples[i].cold_1_channels_per_pass_type_1,
                cold_1_channels_per_pass_type_2: currentDataSamples[i].cold_1_channels_per_pass_type_2,
                cold_1_passes: currentDataSamples[i].cold_1_passes,
                cold_2_channels_per_pass: currentDataSamples[i].cold_2_channels_per_pass,
                cold_2_passes: currentDataSamples[i].cold_2_passes,
                cold_plate_pattern_type_1: currentDataSamples[i].cold_plate_pattern_type_1,
                cold_plate_pattern_type_2: currentDataSamples[i].cold_plate_pattern_type_2,
                hot_1_channels_per_pass_type_1: currentDataSamples[i].hot_1_channels_per_pass_type_1,
                hot_1_channels_per_pass_type_2: currentDataSamples[i].hot_1_channels_per_pass_type_2,
                hot_1_passes: currentDataSamples[i].hot_1_passes,
                hot_2_channels_per_pass: currentDataSamples[i].hot_2_channels_per_pass,
                hot_2_passes: currentDataSamples[i].hot_2_passes,
                hot_plate_pattern_type_1: currentDataSamples[i].hot_plate_pattern_type_1,
                hot_plate_pattern_type_2: currentDataSamples[i].hot_plate_pattern_type_2,
                type: currentDataSamples[i].hot_1_channels_per_pass_type_2 !== undefined && currentDataSamples[i].hot_1_channels_per_pass_type_2 !== null ?
                    Constants.VaryingTheta :
                    currentDataSamples[i].hot_2_channels_per_pass !== undefined && currentDataSamples[i].hot_2_channels_per_pass !== null ?
                        Constants.VaryingChannelsPasses : Constants.BasicPass
            }
        }

        setPlatePackConfigs(currentConfigs);
        setDataSamples(currentDataSamples);
        setPheType(currentDataSamples[0].phePlateType ?? '');
        setIsLoading(false);
    }

    useEffect(() => {
        // setDataSample(state);
        if (state.dataSample === undefined) {
            getDataSamplesAsync();
        };

        // get the unit's user inputs to filter them out of the form for SME review
        async function getUnitConfiguration() {
            const response = await apiService.getAsync(`settings/unitconfigurations/sections`, unitConfiguration);
            if (!response.ok) {
                throw new Error(`Could not get unit configuration. ${response.status} ${response.statusText}`);
            }

            const result: IUnitConfiguration[] = await response.json();
            setUnitConfiguration(result[0]);
            await getPHETypes(result[0]);

            await getDataSamplesAsync();
        }

        async function getPHETypes(unitConfig: IUnitConfiguration) {
            let pheTypes: IDropdownOption[] = [];
            const response = await apiService.getAsync(`settings/phetypes/available`, unitConfig);

            if (!response.ok) {
                throw new Error(`Could not get PHE types. ${response.status} ${response.statusText}`);
            }
            const types: any[] = await response.json();
            types.sort((a, b) => a.text.localeCompare(b.text));
            for (let i = 0; i < types.length; i++) {
                pheTypes.push({
                    key: types[i].key,
                    text: types[i].text
                })
            }

            setPheTypes(pheTypes);
        }

        getUnitConfiguration();

    }, [state]);

    // for edit mode form validation on load
    useEffect(() => {

        //  validateForm(true);

    }, [platePackConfigs, dataSamples]);

    const getSMEGiudelineId = (id?: bigint) => {
        setSMEDesignGuidelineId(id);
        let currentDataSamples = dataSamples;
        currentDataSamples.forEach((session: any, index) => {
            session['smeDesignGuideLineId'] = id;
        });
    }

    const validateForm = (input?: boolean) => {
        if ((input === true || input === undefined) &&
            checkMultiSectionValidity() === true &&
            (document.forms[0] === undefined || (document.forms[0] !== undefined && document.forms[0].checkValidity() === true))) {
            setFormIsValid(true);
        }
        else {
            setFormIsValid(false);
        }
    }

    function checkMultiSectionValidity() {
        let isValid = true;
        dataSampleLoop: for (let dataSample of dataSamples) {
            if (dataSample.phePlateType == null || dataSample.phePlateType == undefined || dataSample.phePlateType === '') {
                isValid = false;
                break dataSampleLoop;
            }
        }

        if (isValid === true) {
            isValid = checkPlatePackMultiSectionValidity(platePackConfigs);
        }
        return isValid;
    }

    const handleChange = (property: string, value: any) => {
        let current = dataSamples[currentSectionIndex];

        if (value.currentTarget !== undefined) {
            value = value.currentTarget.value;
        }

        //assign same property to all datasamples if it is phe plate type and SME comment
        let currentSamples = dataSamples;
        if (property === PropertyConstants.PHEPlateType || property === PropertyConstants.SMEComment) {
            currentSamples.forEach((dataSample: any, index) => {
                dataSample[`${deCapitalizeFirstLetter(property)}`] = value;
            });
        }
        else {
            current = { ...current, [`${deCapitalizeFirstLetter(property)}`]: value };
        }

        currentSamples[currentSectionIndex] = current;
        setDataSamples([...currentSamples]);
        validateForm();
    }

    const handlePlatePackConfigurationChange = (config: IPlatePackConfiguration) => {
        let currentConfigs = platePackConfigs;
        currentConfigs[currentSectionIndex] = config;
        setPlatePackConfigs([...currentConfigs]);
    }

    function isRelevantOutputField(field: string) {
        return multiSectionConfig[currentSectionIndex]?.inputs?.find(x => x.toLowerCase() === field.toLowerCase()) === undefined && multiSectionConfig[currentSectionIndex]?.nonUsedProperties?.find(x => x.toLowerCase() === field.toLowerCase()) === undefined;
    }

    async function submitForm(source: SourceType) {
        setIsLoading(true);

        for (let i = 0; i < multiSectionConfig.length; i++) {
            // phePlateType is universal for all sections
            let body: any = { ...dataSamples[i], ...platePackConfigs[i], phePlateType: pheType }

            const response = await apiService.putAsync(body, `modeling/positions/${dataSamples[i]?.id}`);
            if (!response.ok) {
                throw Error(`Could not send response. ${response.status} ${response.statusText}`);
            }
        }

        setIsLoading(false);

        navigate(-1);
    }

    function handleCopyId(id: string) {
        navigator.clipboard.writeText(id);
        setCopyMessage('Copied to clipboard!');
        setTimeout(() => {
            setCopyMessage('');
        }, 1000);
    }

    return (
        <Stack>
            <>
                <Stack horizontal>
                    <Stack.Item style={{ margin: 15 }} grow>
                        <div>
                            <DefaultButton style={{ borderRadius: 5 }} iconProps={{ iconName: 'Back' }} text='Back' onClick={() => { navigate(-1) }} />
                            {
                                dataSamples && dataSamples.length > 0 &&
                                <Text style={{ padding: 5 }}>{`${dataSamples[0].industry} / ${dataSamples[0].subIndustry} / ${dataSamples[0].application} / ${dataSamples[0].subApplication} / `}<Text><strong>{dataSamples[0].alfaLavalPosition}</strong></Text></Text>
                            }
                        </div>
                    </Stack.Item>
                    <Stack.Item style={{ padding: 20, position: 'fixed', right: 20, top: 50 }}>
                        <PrimaryButton onClick={() => submitForm(SourceType.SME)} iconProps={{ iconName: 'CheckMark' }} type='submit' style={{ padding: 5 }} text={multiSectionConfig.length > 1 ? 'Submit all sections' : 'Submit'} disabled={isLoading || !formIsValid} />
                    </Stack.Item>
                </Stack>
                {
                    isLoading &&
                    <Stack.Item style={{ margin: 15 }}>
                        <Spinner size={SpinnerSize.large} label='Loading data sample...' />
                    </Stack.Item>
                }
                {
                    !isLoading && dataSamples && dataSamples.length > 0 &&
                    <Stack horizontal>
                        <Stack grow style={{
                            minWidth: 400,
                            maxWidth: 500,
                            backgroundColor: theme.palette.neutralLighterAlt,
                            boxShadow: "none !important",
                            padding: 20
                        }}>
                            {
                                multiSectionConfig && multiSectionConfig.length > 1 &&
                                <Stack>
                                    <Stack horizontal>
                                        <Stack.Item grow>
                                            <DefaultButton iconProps={{ iconName: "ChevronLeft" }} onClick={() => setActiveIndex(activeIndex === dataSamples.length ? dataSamples.length : activeIndex + 1)} />
                                        </Stack.Item>
                                        <Stack.Item grow align='center'>
                                            <Label>Section {activeIndex}</Label>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <DefaultButton iconProps={{ iconName: "ChevronRight" }} onClick={() => setActiveIndex(activeIndex === 1 ? 1 : activeIndex - 1)} />
                                        </Stack.Item>
                                    </Stack>
                                    <Stack style={{ marginLeft: -25 }} horizontal horizontalAlign='center'>
                                        <MultiSectionFlowchart
                                            selectableProperties={flowChartSelectableProperties ?? []}
                                            sectionCount={multiSectionConfig.length}
                                            unitInputConfiguration={multiSectionConfig[0]}
                                            scaling={1 / multiSectionConfig.length * 1.8} />
                                        {/* {
                                            multiSectionConfig.length > 1 &&
                                            multiSectionConfig.sort((a, b) => { return b.sectionNumber! - a.sectionNumber! }).map((section) => {
                                                return <MultiSectionVisualization
                                                    sectionWidth={100}
                                                    onClick={() => { console.log('settingIndes to', section.sectionNumber); setActiveIndex(section.sectionNumber!) }}
                                                    isActive={section.sectionNumber === activeIndex}
                                                    label={`${section.sectionNumber}`}
                                                    leftEnding={section.sectionNumber === multiSectionConfig.length}
                                                    rightEnding={section.sectionNumber === 1} />
                                            })
                                        } */}
                                    </Stack>
                                </Stack>
                            }
                            <h3 style={{ marginTop: 10 }}>Inputs</h3>
                            <Stack.Item style={{ padding: 5 }}>
                                <Stack styles={generalStackStyles(theme)} style={{ borderLeft: "5px solid #65AFAD" }}>
                                    <Label>Product: <Badge appearance='outline' style={{ fontSize: 14, padding: 15, color: '#65AFAD' }}>{activeSection?.hotMediaType}</Badge></Label>
                                    <Label>Process stage: {activeSection?.processStage}</Label>

                                    {
                                        dataSamples[currentSectionIndex] !== undefined && activeSection?.unitOperationTargetName !== undefined &&
                                        <Stack >
                                            <Stack.Item grow>
                                                <Label>Unit operation target: {activeSection.unitOperationTargetName}</Label>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Badge style={{ fontSize: 16, padding: 15, backgroundColor: '#65AFAD' }}>
                                                    {dataSamples[currentSectionIndex].unitOperationTargetValue?.toString()} {activeSection.unitOperationTargetValueUnit}
                                                </Badge>
                                            </Stack.Item>
                                        </Stack>

                                    }
                                </Stack>

                                {
                                    dataSamples[currentSectionIndex] !== undefined && dataSamples[currentSectionIndex].modelerComment !== '' && dataSamples[currentSectionIndex].modelerComment !== undefined && dataSamples[currentSectionIndex].modelerComment !== null &&
                                    <Stack styles={generalStackStyles(theme)} style={{ paddingTop: 5 }}>
                                        <Stack horizontal>
                                            <Stack.Item grow>
                                                <Label>Modeler comment</Label>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Icon iconName='Comment' style={{ marginLeft: 5, fontSize: 18, marginTop: 7 }} />
                                            </Stack.Item>
                                        </Stack>
                                        <p>{dataSamples[currentSectionIndex].modelerComment}</p>
                                    </Stack>
                                }
                            </Stack.Item>
                            {
                                !dataSamples &&
                                <Stack.Item style={{ margin: 15 }}>
                                    <Spinner size={SpinnerSize.large} />
                                </Stack.Item>
                            }
                            {
                                dataSamples[currentSectionIndex] !== undefined && dataSamples &&
                                <ConditionalTextFieldInputs
                                    unitConfiguration={activeSection!}
                                    originPage={Constants.ModelDataSampleForm}
                                    propertySpecs={[]}
                                    coldMediaFlowrate={dataSamples[currentSectionIndex].coldMediaFlowrate?.toString()}
                                    coldMediaTempIn={dataSamples[currentSectionIndex].coldMediaTempIn?.toString()}
                                    coldMediaTempOut={dataSamples[currentSectionIndex].coldMediaTempOut?.toString()}
                                    coldMediaPressureDrop={dataSamples[currentSectionIndex].coldMediaPressureDrop?.toString()}
                                    hotMediaFlowrate={dataSamples[currentSectionIndex].hotMediaFlowrate?.toString()}
                                    hotMediaTempIn={dataSamples[currentSectionIndex].hotMediaTempIn?.toString()}
                                    hotMediaTempOut={dataSamples[currentSectionIndex].hotMediaTempOut?.toString()}
                                    hotMediaPressureDrop={dataSamples[currentSectionIndex].hotMediaPressureDrop?.toString()}
                                    addStyling={true}
                                    handleChange={undefined} />
                            }

                            <Stack styles={generalStackStyles(theme)}>
                                <Stack.Item grow>
                                    <Label>Sampling Id</Label>
                                    <Label styles={informationLabelStyle(theme)}>{dataSamples[currentSectionIndex] !== undefined && params.samplingId}</Label>
                                </Stack.Item>
                                <Stack.Item grow style={{ marginTop: 10 }}>
                                    <Stack horizontal>
                                        <DefaultButton iconProps={{ iconName: 'Copy' }} text={copyMessage ? 'Id copied' : `Id: ${dataSamples[currentSectionIndex] !== undefined && dataSamples[currentSectionIndex].id}`} onClick={() => handleCopyId(dataSamples[currentSectionIndex].id.toString())} />
                                        {
                                            copyMessage ?
                                                <Icon iconName='CompletedSolid' style={{ marginLeft: 5, marginTop: 5, fontSize: 18, color: theme.palette.green }} />
                                                :
                                                <TooltipHost content='To reference an this version in another dataset, use this ID in the ReferenceId field in your csv file.' id='samplingDate' calloutProps={{ gapSpace: 0 }}>
                                                    <Icon iconName='Info' style={{ marginLeft: 5, fontSize: 18, marginTop: 5, cursor: 'pointer', color: theme.palette.themePrimary }} />
                                                </TooltipHost>
                                        }
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                        </Stack>
                        {

                            // dataSamples[currentSectionIndex].dataSampleStatus !== Constants.DATASAMPLESTATUS_Done &&
                            <>
                                <Stack grow styles={stackStyles} style={{
                                    border: "2px solid " + theme.palette.yellow,
                                    minWidth: 450,
                                    maxWidth: 450
                                }}>
                                    <form onSubmit={(e) => { e.preventDefault(); return submitForm(SourceType.SME) }}>
                                        <Stack horizontal>
                                            <Stack.Item grow>
                                                <h3>SME</h3>
                                            </Stack.Item>
                                        </Stack>


                                        <Stack>
                                            <Stack.Item>
                                                {
                                                    platePackConfigs &&
                                                    <PheTypePlatePackInputForm
                                                        existingConfig={platePackConfigs[currentSectionIndex]}
                                                        pheTypes={pheTypes!}
                                                        setConfig={(c) => handlePlatePackConfigurationChange(c)}
                                                        setPheType={(phe) => { setPheType(phe); handleChange(PropertyConstants.PHEPlateType, phe) }}
                                                        existingPheType={pheType ?? ''}
                                                        shouldValidateFormInputs={true}
                                                        validateForm={validateForm}
                                                        label={multiSectionConfig.length > 1 ? `Plate pack configuration section ${currentSectionIndex + 1}` : 'Plate pack configuration'}
                                                    />
                                                }
                                            </Stack.Item>
                                        </Stack>

                                        {
                                            dataSamples !== undefined && dataSamples.length > 0 && dataSamples[currentSectionIndex] &&
                                            <>
                                                <Label>Process outputs</Label>
                                                <ConditionalTextFieldOutputs
                                                    handleChange={(e: any) => handleChange(e.currentTarget.name, e.currentTarget.value)}
                                                    unitConfiguration={activeSection!}
                                                    hotMediaFlowrate={dataSamples[currentSectionIndex].hotMediaFlowrate ?? ''}
                                                    hotMediaTempIn={dataSamples[currentSectionIndex].hotMediaTempIn ?? ''}
                                                    hotMediaTempOut={dataSamples[currentSectionIndex].hotMediaTempOut ?? ''}
                                                    hotMediaPressureDrop={dataSamples[currentSectionIndex].hotMediaPressureDrop ?? ''}
                                                    coldMediaFlowrate={dataSamples[currentSectionIndex].coldMediaFlowrate ?? ''}
                                                    coldMediaTempIn={dataSamples[currentSectionIndex].coldMediaTempIn ?? ''}
                                                    coldMediaTempOut={dataSamples[currentSectionIndex].coldMediaTempOut ?? ''}
                                                    coldMediaPressureDrop={dataSamples[currentSectionIndex].coldMediaPressureDrop ?? ''}
                                                    hotMediaWallTemp={dataSamples[currentSectionIndex].hotMediaWallTemp ?? ''}
                                                    coldMediaWallTemp={dataSamples[currentSectionIndex].coldMediaWallTemp ?? ''}
                                                    addStyling={true}
                                                />
                                            </>

                                        }
                                        <Stack.Item>
                                            <Label>Comment</Label>
                                            <TextField multiline={true} style={{ height: 100 }} onChange={(e) => handleChange(PropertyConstants.SMEComment, e.currentTarget.value)} value={dataSamples[currentSectionIndex].smeComment ?? ''} />
                                        </Stack.Item>
                                    </form>
                                </Stack>
                                {
                                    unitConfiguration !== undefined &&
                                    <Stack style={{ minWidth: 300, maxWidth: 500, maxHeight: 800 }}>
                                        <SMEDesignGuidelines
                                            isReadOnly={false}
                                            setDesignGuidelineId={(e) => getSMEGiudelineId(e)}
                                            dataSample={dataSamples[0]}
                                            unitConfiguration={unitConfiguration} />
                                    </Stack>
                                }
                            </>
                        }
                    </Stack>
                }
            </>
        </Stack>
    )
}