export type NavigationType = 'Industry' | 'SubIndustry' | 'Application' | 'SubApplication'

export enum HotMediaType {
    'Milk',
    'Steam',
    'Unknown',
}

export enum ColdMediaType {
    'Cooling water',
    'CIP Water',
    'Unknown',
}

export enum AlfaLavalPositionType {
    'Milk cooler',
    'Utility Heater'
}

export enum Industry {
    Other,
    FoodDairyAndBeverages
}
export enum SubIndustry {
    Other,
    Dairy,
    Beverages
}
export enum Application {
    Other,
    MilkAndCreamProcessing
}
export enum SubApplication {
    Other,
    PasteurizedMilkProcessing
}
export enum ProcessStage {
    Other,
    MilkReception,
    CIP,
    CreamCooling
}
export enum AlfaLavalPosition {
    MilkCooler,
    UtilityHeater,
    CreamCooler
}
export enum TrainingTarget {
    BlindTest,
    ModelEndpoint
}


export enum Roles {
  None,
  Seller,
  Alice,
  Modeler,
  SME,
  BlindTestUser
}

export enum ColdConditionalFields{
    ColdMediaFlowrate,
    ColdMediaTempIn,
    ColdMediaTempOut,
    ColdMediaPressureDrop,
    ColdMediaWallTemp
}
export enum HotConditionalFields{
    HotMediaFlowrate,
    HotMediaTempIn,
    HotMediaTempOut,
    HotMediaPressureDrop,
    HotMediaWallTemp
}

export enum AvailableProductLine{
    Industrial,
    Hygienic
}
 
