import { useEffect, useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Menu from '../../../Assets/SVG/menu.svg';
import Settings from '../../../Assets/SVG/setting.svg';
import feedback from '../../../Assets/SVG/feedback.svg';
import Home from '../../../Assets/SVG/Home.svg';
import { Coachmark, DefaultButton, DirectionalHint, FontSizes, FontWeights, IStackItemStyles, IStackTokens, Icon, PrimaryButton, Stack, TeachingBubbleContent, useTheme } from '@fluentui/react';
import './Sidebar.scss';
import { getHomeLocation, getLocalStorageItem, hasRole, setLocalStorageItem } from '../../../Services/Global';

export const Sidebar: React.FC<{ showTutorial: boolean }> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => setIsOpen(!isOpen);
  const [showTutorial, setShowTutorial] = useState<boolean>(getLocalStorageItem('showTutorial') === 'true' ? true : false);

  let navigate = useNavigate();
  const toggleStyle = {
    display: isOpen ? '' : 'none',
  };

  let theme = useTheme();

  const stackItemStyles: IStackItemStyles = {
    root: {
      padding: 0,
      width: isOpen ? 190 : 65,
      background: theme.palette.white,
    },
  };

  const tokenStyles: IStackTokens = {
    childrenGap: 0
  };

  useEffect(() => {
    setShowTutorial(props.showTutorial);
  }, [props.showTutorial])

  return (
    <>
      <Stack horizontalAlign='stretch' styles={stackItemStyles} tokens={tokenStyles}>

        <div className='sidebar-item' onClick={handleToggle}>
          <img
            src={Menu}
            alt='menu'
            style={{ width: 25 }}
          />
        </div>

        <NavLink
          to={getHomeLocation() as string}
          className={({ isActive }) => (isActive ? 'active sidebar-item' : 'link sidebar-item')}
        >
          {
            getHomeLocation() as string === '/about' ?
              <>

                <Icon iconName='Info' style={{ fontSize: FontSizes.large, position: 'absolute', fontWeight: FontWeights.regular }} />
                <div className='sidebar-text' style={toggleStyle} >
                  About
                </div>
              </>
              :
              <>
                <img src={Home} alt='Home' style={{ position: 'absolute' }} />
                <div className='sidebar-text' style={toggleStyle} >
                  Home
                </div>
              </>
          }
        </NavLink>


        {
          <NavLink to='/admin'
            className={({ isActive }) => (isActive ? 'active sidebar-item' : 'link sidebar-item')}>
            <img src={Settings} alt='Home' className='sidebar-icon' />
            <div className='sidebar-text' style={toggleStyle}>
              Settings
            </div>
          </NavLink>
        }

        {/* TODO: The Mailto address should be updated when a distribution list has been created */}
        <a href='mailto:magnus.hoffstein@alfalaval.com?subject=AI design wizard user feedback' className='sidebar-item'>
          <img src={feedback} alt='feedback' className='sidebar-icon' style={{ width: 25 }} />
          <div className='sidebar-text' style={toggleStyle}>
            Feedback
          </div>
        </a>


      </Stack>
    </>
  );
}
export default Sidebar;