import { IMsalContext, useMsal } from "@azure/msal-react";
import { DefaultButton, FontWeights, Icon, IconButton, Label, mergeStyleSets, MessageBar, MessageBarType, Modal, Stack, TextField, TooltipHost, useTheme } from "@fluentui/react"
import { useEffect, useMemo, useState } from "react";
import { Constants } from "../../../Models/Constants";
import { IDataSample } from "../../../Models/IDataSample";
import { IDesignSession } from "../../../Models/IDesignSession";
import { ISMEDesignguidelines } from "../../../Models/ISMEDesignGuidelines";
import { IUnitConfiguration } from "../../../Models/IUnitConfiguration";
import { generalStackStyles } from "../../../Models/StackStyling";
import { ApiService } from "../../../Services/ApiService";

export const SMEDesignGuidelines: React.FC<{
    designSession?: IDesignSession,
    dataSample?: IDataSample,
    unitConfiguration?: IUnitConfiguration,
    setDesignGuidelineId?: (id: bigint) => void,
    isReadOnly: boolean
}> = (props) => {
    let theme = useTheme();

    const emelieStyling = {

    }
    const ctx: IMsalContext = useMsal();
    const apiService = useMemo(() => new ApiService(ctx), [ctx]);
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [SMEdesignSuggestion, setSMEDesignSuggestion] = useState<ISMEDesignguidelines>();
    const [designSuggestion, setDesignSuggestion] = useState<string>("");

    const contentStyles = modalStyles(theme);
    useEffect(() => {

        if (props.designSession !== undefined && props.designSession !== null) {
            if (props.designSession!.smeDesignGuideLineId === null && (props.designSession.source === Constants.SOURCE_UNASSIGNED || props.designSession.source === null)) {
                getSMEDesignGiudelines(props.unitConfiguration!);
            }
            else if (props.designSession!.smeDesignGuideLineId !== null) {
                getSMEDesignSuggestionForSession(props.designSession!.smeDesignGuideLineId!);
            }
        }

        if (props.dataSample !== undefined && props.dataSample !== null) {
            if (props.dataSample!.smeDesignGuideLineId === null && props.dataSample.dataSampleStatus !== Constants.DATASAMPLESTATUS_Done) {
                getSMEDesignGiudelines(props.unitConfiguration!);
            }
            else if (props.dataSample!.smeDesignGuideLineId !== null) {
                getSMEDesignSuggestionForSession(props.dataSample!.smeDesignGuideLineId!);
            }
        }

    }, []);

    async function getSMEDesignSuggestionForSession(id: bigint) {

        const response = await apiService.getAsync(`settings/smedesignguidelines/latest`, props.unitConfiguration!);
        const designguideline: ISMEDesignguidelines = await response.json();
        setSMEDesignSuggestion(designguideline);
        props.setDesignGuidelineId?.(designguideline.id!);
    }

    function modalStyles(theme: any) {
        return mergeStyleSets({
            container: {
                display: 'flex',
                flexFlow: 'column nowrap',
                alignItems: 'stretch',
            },
            header: [
                theme.fonts.xLargePlus,
                {
                    flex: '1 1 auto',
                    flexDirection: 'row',
                    borderTop: `4px solid ${theme.palette.themePrimary}`,
                    color: theme.palette.neutralPrimary,
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: FontWeights.bold,
                    padding: '12px 12px 14px 24px',
                    backgroundColor: 'lightgray',
                    flexdirection: 'row'
                },
            ],
            body: {
                display: 'table',
                flex: '4 4 auto',
                borderSpacing: 4,
                padding: '0 24px 24px 24px',
                margin: '4px',
                overflowY: 'hidden',
                selectors: {
                    p: { margin: '14px 0' },
                    'p:first-child': { marginTop: 0 },
                    'p:last-child': { marginBottom: 0 },
                },
            }
        });
    }

    const iconButtonStyles = iconModalStyles(theme);
    function iconModalStyles(theme: any) {
        return {
            root: {
                color: theme.palette.neutralPrimary,
                marginLeft: 'auto',
                marginTop: '4px',
                marginRight: '2px',
            },
            rootHovered: {
                color: theme.palette.neutralDark,
            },
        };
    }

    function showModalForSMeDesignGuidelines() {
        setIsModalOpen(true);
    }

    function hideModal() {
        setIsModalOpen(false);
    }
    const addNewDesignGuideLine = async () => {
        let body: any = {}
        body.designSuggestion = designSuggestion;

        hideModal();
        let response = await apiService.postAsync(body, `settings/createappenddesignsuggestion`, props.unitConfiguration);

        if (!response.ok) {
            const res = await response.text();
            throw Error(`Could not send response. ${response.status} ${res}`);
        }
        getSMEDesignGiudelines(props.unitConfiguration!);
    }

    async function getSMEDesignGiudelines(unitConfig: IUnitConfiguration) {
        const response = await apiService.getAsync(`settings/smedesignguidelines/latest`, unitConfig);
        if (!response.ok) {
            if (response.status === 404) {
                console.log('404')
            }
            else {
                throw new Error('Could not get SME design guidelines. ' + response.status + ' ' + response.statusText);
            }

            setShowMessage(true);
        }
        else {
            const designguideline: ISMEDesignguidelines = await response.json();
            setSMEDesignSuggestion(designguideline);
            props.setDesignGuidelineId?.(designguideline.id!);
        }
    }

    return (
        <Stack
            styles={generalStackStyles(theme)}
            style={{
                border: "2px solid " + theme.palette.themePrimary,
                minWidth: 300,
                maxWidth: 500
            }}
            horizontal={false}>
            <Stack.Item grow>
                <h3 style={{ paddingBottom: 5 }}>SME Design Guidelines</h3>
                {
                    showMessage &&
                    <MessageBar
                        messageBarType={MessageBarType.warning}
                        dismissButtonAriaLabel="Close"
                        onDismiss={() => setShowMessage(false)}>
                        {Constants.SMEDesignguidelineNotFoundforGivenconfiguration}<br />
                        To add SME design guidelines,<a href={'/admin'}>go to settings</a>
                    </MessageBar>

                }
                {!props.isReadOnly && props.designSession !== undefined && props.designSession !== null &&
                    <Stack>
                        <Stack.Item style={{ height: 'auto', paddingTop: 10 }}>
                            {
                                SMEdesignSuggestion === undefined && showMessage &&
                                <MessageBar
                                    messageBarType={MessageBarType.warning}
                                    dismissButtonAriaLabel="Close"
                                    onDismiss={() => setShowMessage(false)}>
                                    {Constants.SMEDesignguidelineNotFoundforGivenconfiguration}<br></br>
                                    {Constants.ClickOnAddButtonToAddORAppendDesignGuidelines}
                                </MessageBar>

                            }
                        </Stack.Item>
                        <Stack.Item style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <DefaultButton id="addSMEDesign" style={{ color: theme.palette.white, backgroundColor: theme.palette.themePrimary, float: 'right' }} text="Add/Append" onClick={() => { showModalForSMeDesignGuidelines() }}></DefaultButton>
                            <Modal titleAriaId="id" isOpen={isModalOpen === true} isModeless={false} onDismiss={hideModal} isBlocking={true} isDarkOverlay={true}
                                containerClassName={contentStyles.container} >
                                <Stack grow className={contentStyles.header}>
                                    <Label>Create or Append design suggestion</Label>
                                    <IconButton styles={iconButtonStyles} iconProps={{ iconName: 'Cancel' }} ariaLabel="Close"
                                        style={{ float: 'right' }} onClick={hideModal} />
                                </Stack>
                                <table className={contentStyles.body}>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <Label>Industry</Label>
                                            </td>
                                            <td style={{ minWidth: 250 }}>
                                                <TextField value={props.unitConfiguration!.industry}></TextField>
                                            </td>
                                            <td style={{ paddingLeft: '30px' }}>
                                                <Label>Subindustry</Label>
                                            </td>
                                            <td style={{ minWidth: 250 }}>
                                                <TextField value={props.unitConfiguration!.subIndustry}></TextField>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Label>Application</Label>
                                            </td>
                                            <td>
                                                <TextField value={props.unitConfiguration!.application}></TextField>
                                            </td>
                                            <td style={{ paddingLeft: '30px' }}>
                                                <Label>Subapplication</Label>
                                            </td>
                                            <td>
                                                <TextField value={props.unitConfiguration!.subApplication}></TextField>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Label>Process stage</Label>
                                            </td>
                                            <td>
                                                <TextField value={props.unitConfiguration!.processStage}></TextField>
                                            </td>
                                            <td style={{ paddingLeft: '30px' }}>
                                                <Label>Product line</Label>
                                            </td>
                                            <td>
                                                <TextField style={{ fontSize: '#00476f' }} value={props.unitConfiguration!.productLine}></TextField>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Label>Alfa laval position</Label>
                                            </td>
                                            <td>
                                                <TextField value={props.unitConfiguration!.alfaLavalPosition}></TextField>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Label>Design suggestion</Label>
                                            </td>
                                            <td colSpan={2}>
                                                <TextField placeholder={SMEdesignSuggestion?.designSuggestion} style={{ height: '50px', fontSize: '14' }} onChange={(e, i) => setDesignSuggestion(i!.toString())} multiline rows={3} >
                                                </TextField>
                                            </td>
                                            <td>
                                                <TooltipHost content='Design suggestion will be appended to existing suggestion.' id='infoAppend' calloutProps={{ gapSpace: 0 }}>
                                                    <Icon iconName='Info' style={{ marginLeft: 5, fontSize: 18, marginTop: 5, cursor: 'pointer', color: theme.palette.themePrimary }} />
                                                </TooltipHost>
                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                                <div style={{ textAlign: 'center', marginBottom: 10 }}>
                                    <DefaultButton id="submitSMEDesign" style={{ color: theme.palette.white, backgroundColor: theme.palette.themePrimary }} text='Submit' iconProps={{ iconName: 'CheckMark' }} onClick={() => addNewDesignGuideLine()} />
                                </div>
                            </Modal>
                        </Stack.Item>
                    </Stack>
                }
                <TextField
                    style={{ overflowY: 'scroll', maxHeight: 743 }}
                    resizable={true}
                    multiline
                    readOnly={true} value={SMEdesignSuggestion?.designSuggestion} autoAdjustHeight />
            </Stack.Item>
        </Stack>
    )
}