import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Menu from '../../../Assets/SVG/menu.svg';
import Settings from '../../../Assets/SVG/setting.svg';
import feedback from '../../../Assets/SVG/feedback.svg';
import admin from '../../../Assets/SVG/admin.svg';
import Home from '../../../Assets/SVG/Home.svg';
import { IStackItemStyles, IStackTokens, Stack, useTheme } from '@fluentui/react';
import { hasRole } from "../../../Services/Global";
import { Roles } from "../../../Models/Enums";

function SMESidebar() {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => setIsOpen(!isOpen);

  const toggleStyle = {
    display: isOpen ? '' : 'none',
  };

  let theme = useTheme();

  const stackItemStyles: IStackItemStyles = {
    root: {
      padding: 0,
      width: isOpen ? 190 : 65,
      background: theme.palette.white,
    },
  };

  const tokenStyles: IStackTokens = {
    childrenGap: 0
  };

  return (
    <>
      <Stack horizontalAlign='stretch' styles={stackItemStyles} tokens={tokenStyles}>

        <div className='sidebar-item' onClick={handleToggle}>
          <img
            src={Menu}
            alt='menu'
            style={{ width: 25 }}
          />
        </div>

        <NavLink
          to='/blindtests/dashboard'
          className={({ isActive }) => (isActive ? 'active sidebar-item' : 'link sidebar-item')}
        >
          <img src={Home} alt='Design List' style={{ position: 'absolute' }} />
          <div className='sidebar-text' style={toggleStyle} >
            Home
          </div>
        </NavLink>

        {
          <NavLink to='/admin'
            className={({ isActive }) => (isActive ? 'active sidebar-item' : 'link sidebar-item')}>
            <img src={Settings} alt='Home' className='sidebar-icon' />
            <div className='sidebar-text' style={toggleStyle}>
              Configurations
            </div>
          </NavLink>
        }
        {/* TODO: The Mailto address should be updated when a distribution list has been created */}
        <a href='mailto:magnus.hoffstein@alfalaval.com?subject=AI design wizard user feedback' className='sidebar-item'>
          <img src={feedback} alt='feedback' className='sidebar-icon' style={{ width: 25 }} />
          <div className='sidebar-text' style={toggleStyle}>
            Feedback
          </div>
        </a>


      </Stack>
    </>
  );
}
export default SMESidebar;