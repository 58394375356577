import { FontSizes, Stack, useTheme } from "@fluentui/react"
import { hoverableStackStyles } from "../../../Models/StackStyling"

export const PositionBadge: React.FC<{
    name: string,
    type: string,
    selected?: boolean,
    disabled?: boolean,
    background?: string,
    fontColor?: string,
    onClick?: () => void
}> = (props) => {
    let theme = useTheme();
    return (
        <Stack horizontal onClick={props.onClick} styles={hoverableStackStyles(theme)} style={{ padding: 4, background: props.background, color: props.fontColor }}>
            <Stack.Item>
                <p style={{ fontSize: FontSizes.small }}>
                    {props.name} <strong>{props.type}</strong>
                </p>
            </Stack.Item>
        </Stack>
    )
}