import { useEffect, useState } from 'react';
import BreadCrumb from '../../Atom/Breadcrumbs/Breadcrumb';
import Final from '../.../../../../Assets/PNG/final.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { IParameters } from '../../../Models/IParameters';
import { DesignSuggestionService } from '../../../Services/DesignSuggestionService';
import { IMsalContext, useMsal } from '@azure/msal-react';
import { getFeatures, getPreferredUnits } from '../../../Services/Global';
import '../../Atom/ButtonProps/ButtonProps';
import { DefaultButton, PrimaryButton, Shimmer, ShimmerElementsGroup, ShimmerElementType, Spinner, Stack, TextField } from '@fluentui/react';
import { EditIcon } from '../../Shared/EditIcon';
import { convertCelsiusToFahrenheit, convertKilogramsToPunds } from '../../../Utils/UnitsHelper';
import { IDesignSession } from '../../../Models/IDesignSession';
import { AlfaLavalPosition, Application, ColdMediaType, HotMediaType, Industry, ProcessStage, SubApplication, SubIndustry } from '../../../Models/Enums';
import { Constants } from '../../../Models/Constants';

export const FinalParameters: React.FC<{}> = () => {
  const location = useLocation();
  const inputParameters: IParameters = location.state as IParameters;

  // Feature flags
  const [pressureDropEnabled, setPressureDropEnabled] = useState(false);
  const [coldTempOutEnabled, setColdTempOutEnabled] = useState(false);
  const [coldFlowrateEnabled, setColdFlowrateEnabled] = useState(false);

  const [designSessionId, setDesignSessionId] = useState(0);

  const [hotMediaFlowrate, sethotMediaFlowrate] = useState('');
  const [hotMediaFlowrateError, sethotMediaFlowrateError] = useState('');

  const [hotMediaTempIn, setHotMediaTempIn] = useState('');
  const [hotMediaTempInError, setHotMediaTempInError] = useState('');

  const [hotMediaTempOut, setHotMediaTempOut] = useState('');
  const [hotMediaTempOutError, setHotMediaTempOutError] = useState('');

  const [coldMediaTempIn, setColdMediaTempIn] = useState('');
  const [coldMediaTempInError, setColdMediaTempInError] = useState('');

  const [isLoading, setIsLoading] = useState(true);

  const preferredFlowrateUnits = getPreferredUnits().preferredUnits === 'us' ? 'lb/h' : 'kg/h';
  const preferredTemperatureUnits = `°${getPreferredUnits().preferredUnits === 'us' ? 'F' : 'C'}`;
  const preferredPressureUnits = getPreferredUnits().preferredUnits === 'us' ? 'PSI' : 'kPa';

  const ctx: IMsalContext = useMsal();
  const dataService = new DesignSuggestionService(ctx);

  let navigate = useNavigate();
  const [designSession, setDesignSession] = useState<IDesignSession>({});

  async function getDesignSuggestion(hotType: HotMediaType, coldType: ColdMediaType, hotFlowrate: string, hotTempIn: string, hotTempOut: string, coldTempIn: string, recordExist: boolean = false, existingId: number | undefined = undefined) {
    // call API with received parameters
    let response: Response | undefined = undefined;

    if (recordExist === false) {
      response = await dataService.fetchDesignSuggestionFromApi(
        Industry.FoodDairyAndBeverages,
        SubIndustry.Dairy,
        Application.MilkAndCreamProcessing,
        SubApplication.PasteurizedMilkProcessing,
        ProcessStage.MilkReception,
        AlfaLavalPosition.MilkCooler,
        hotType,
        coldType,
        hotFlowrate,
        undefined,
        hotTempIn,
        hotTempOut,
        undefined,
        undefined,
        coldTempIn,
        undefined
      );
    }
    else {
      if (existingId === undefined) {
        throw new Error('No id found for current suggestion.');
      }
      response = await dataService.putDesignSessionToApi(
        existingId,
        Industry.FoodDairyAndBeverages,
        SubIndustry.Dairy,
        Application.MilkAndCreamProcessing,
        SubApplication.PasteurizedMilkProcessing,
        ProcessStage.MilkReception,
        AlfaLavalPosition.MilkCooler,
        hotType,
        coldType,
        hotFlowrate,
        undefined,
        hotTempIn,
        hotTempOut,
        undefined,
        undefined,
        coldTempIn,
        undefined
      );
    }

    const p: IDesignSession = await response.json();

    setDesignSession({
      id: p.id,
      coldMediaFlowrate: p.coldMediaFlowrate,
      coldMediaTempIn: inputParameters.coldMediaTempIn,
      coldMediaTempOut: p.coldMediaTempOut,
      coldMediaType: Constants.COLDMEDIATYPES_CoolingWater,
      hotMediaFlowrate: inputParameters.hotMediaFlowrate,
      hotMediaTempIn: inputParameters.hotMediaTempIn,
      hotMediaTempOut: inputParameters.hotMediaTempOut,
      hotMediaType: Constants.HOTMEDIATYPES_Milk,
      phePlateType: p.phePlateType,
      platePackConfiguration: p.platePackConfiguration,
      productMedia: inputParameters.productMedia,
      hotMediaPressureDrop: p.hotMediaPressureDrop,
      coldMediaPressureDrop: p.coldMediaPressureDrop
    });

    const features: Record<string, boolean> = getFeatures();
    setPressureDropEnabled(features["pressureDrop"]);
    setColdFlowrateEnabled(features["coldFlowrate"]);
    setColdTempOutEnabled(features["coldTempOut"]);

    setDesignSessionId(p.id!);
    setIsLoading(false);
  }

  useEffect(() => {
    if (designSession === undefined || inputParameters === undefined) return;

    sethotMediaFlowrate(inputParameters.hotMediaFlowrate!.toString());
    setHotMediaTempIn(inputParameters.hotMediaTempIn!.toString());
    setHotMediaTempOut(inputParameters.hotMediaTempOut!.toString());
    setColdMediaTempIn(inputParameters.coldMediaTempIn!.toString());




    getDesignSuggestion(HotMediaType.Milk!, ColdMediaType['Cooling water'], inputParameters.hotMediaFlowrate!, inputParameters.hotMediaTempIn!, inputParameters.hotMediaTempOut!, inputParameters.coldMediaTempIn!);
  }, []);

  const handleUpdateField = (field: string, value: string) => {
    const useMetric = getPreferredUnits().preferredUnits === 'us' ? false : true;

    let input: number = parseFloat(value);

    switch (field) {
      case 'hotMediaFlowrate':
        if (useMetric === true && (input < 10000 || input > 48000)) {
          sethotMediaFlowrateError('Value needs to be between 10 000 and 48 000');
        }
        else if (useMetric === false &&
          (input < convertKilogramsToPunds(10000, true)
            || input > convertKilogramsToPunds(48000, true))) {

          sethotMediaFlowrateError(`Value needs to be between ${convertKilogramsToPunds(10000, true)} and ${convertKilogramsToPunds(48000, true)}`);

        }
        else {
          sethotMediaFlowrateError('');
        }

        sethotMediaFlowrate(value);
        break;
      case 'hotMediaTempIn':
        if (useMetric === true && (input < 7 || input > 11)) {
          setHotMediaTempInError(
            'Value needs to be between 7.0 and 11.0 °C'
          );
        } else if (
          useMetric === false &&
          (input < convertCelsiusToFahrenheit(7) ||
            input > convertCelsiusToFahrenheit(11))
        ) {
          setHotMediaTempInError(
            `Value needs to be between ${convertCelsiusToFahrenheit(
              7
            )} and ${convertCelsiusToFahrenheit(11)} °F`
          );
        } else {
          setHotMediaTempInError('');
        }
        setHotMediaTempIn(value);
        break;
      case 'hotMediaTempOut':
        if (
          useMetric === true &&
          (input < 3 || input > 4)
        ) {
          setHotMediaTempOutError(
            'Value needs to be between 3.0 and 4.0 °C'
          );
        } else if (
          useMetric === false &&
          (input < convertCelsiusToFahrenheit(3) ||
            input > convertCelsiusToFahrenheit(4))
        ) {
          setHotMediaTempOutError(
            `Value needs to be between ${convertCelsiusToFahrenheit(
              3
            )} and ${convertCelsiusToFahrenheit(4)} °F`
          );
        } else {
          setHotMediaTempOutError('');
        }

        setHotMediaTempOut(value);
        break;
      case 'coldMediaTempIn':
        if (useMetric === true && (input < 0.5 || input > 2.4)) {
          setColdMediaTempInError(
            'Value needs to be between 0.5 and 2.4 °C'
          );
        } else if (
          useMetric === false &&
          (input < convertCelsiusToFahrenheit(0.5) ||
            input > convertCelsiusToFahrenheit(2.4))
        ) {
          setColdMediaTempInError(
            `Value needs to be between ${convertCelsiusToFahrenheit(
              0.5
            )} and ${convertCelsiusToFahrenheit(2.4)} °F`
          );
        } else {
          setColdMediaTempInError('');
        }

        setColdMediaTempIn(value);
        break;
      default:
        break;
    }
  }

  const handleRecalculate = async () => {
    setIsLoading(true);
    await getDesignSuggestion(HotMediaType.Milk, ColdMediaType['Cooling water'], hotMediaFlowrate, hotMediaTempIn, hotMediaTempOut, coldMediaTempIn, true, designSession.id);
  }

  return (
    <div className='e-mb-30'>
      <BreadCrumb />
      <div className='emelie-finalInput e-p-30'>
        <div className='emelie-f-32'>Step.4</div>

        <div className='emelie-flex'>
          {designSession === undefined ? (
            <p>Undefined</p>
          ) : (
            <div className='e-mr-50 e-pt-10'>
              <div className='emelie-f-18'>Process parameters</div>
              {/* input */}
              <div className='emelie-flex emelie-flex-col emelie-justify-sb'>
                <div>
                  <div className='e-flex emelie-flex-col e-pt-10'>
                    <label className='emelie-input-title'>Product Media</label>
                    <TextField
                      type='text'
                      placeholder='Milk'
                      readOnly
                      disabled
                      value={designSession.hotMediaType}
                    />
                  </div>
                  {/* input */}
                  <div className='e-flex emelie-flex-col e-pt-10'>
                    <label className='emelie-input-title'>Milk flow rate</label>
                    <Stack horizontal>
                      <Stack.Item grow>
                        <TextField
                          suffix={preferredFlowrateUnits}
                          type='number'
                          value={hotMediaFlowrate}
                          onChange={(e) => { handleUpdateField('hotMediaFlowrate', e.currentTarget.value) }}
                          errorMessage={hotMediaFlowrateError}
                        />
                      </Stack.Item>
                      <Stack.Item>
                        <EditIcon />
                      </Stack.Item>
                    </Stack>
                  </div>
                  {/* input */}
                  <div className='e-flex emelie-flex-col e-pt-10'>
                    <label className='emelie-input-title'>
                      Milk temperature inlet
                    </label>
                    <Stack horizontal>
                      <Stack.Item grow>
                        <TextField
                          suffix={preferredTemperatureUnits}
                          type='number'
                          value={hotMediaTempIn}
                          onChange={(e) => { handleUpdateField('hotMediaTempIn', e.currentTarget.value) }}
                          errorMessage={hotMediaTempInError}
                        />
                      </Stack.Item>
                      <Stack.Item>
                        <EditIcon />
                      </Stack.Item>
                    </Stack>
                  </div>
                  {/* input */}
                  <div className='e-flex emelie-flex-col e-pt-10'>
                    <label className='emelie-input-title'>
                      Milk temperature outlet
                    </label>
                    <Stack horizontal>
                      <Stack.Item grow>
                        <TextField
                          suffix={preferredTemperatureUnits}
                          type='number'
                          value={hotMediaTempOut}
                          onChange={(e) => { handleUpdateField('hotMediaTempOut', e.currentTarget.value) }}
                          errorMessage={hotMediaTempOutError}
                        />
                      </Stack.Item>
                      <Stack.Item>
                        <EditIcon />
                      </Stack.Item>
                    </Stack>
                  </div>
                  {/* input */}
                  {
                    pressureDropEnabled &&
                    <div className='e-flex emelie-flex-col e-pt-10'>
                      <label className='emelie-input-title'>
                        Milk pressure drop
                      </label>
                      {
                        isLoading ? <Shimmer styles={{ root: { height: '40px' }, shimmerWrapper: { height: '40px' } }} />
                          :
                          <TextField
                            suffix={preferredPressureUnits}
                            type='number'
                            readOnly
                            value={`${designSession!.hotMediaPressureDrop}`}
                          />
                      }
                    </div>
                  }
                  {/* input */}
                  <div className='e-flex emelie-flex-col e-pt-10'>
                    <label className='emelie-input-title'>Cooling Media</label>

                    <TextField
                      type='text'
                      placeholder='Cooling Water'
                      readOnly
                      disabled
                      value={designSession.coldMediaType!}
                    />


                  </div>
                  {/* input */}
                  {
                    coldFlowrateEnabled &&
                    <div className='e-flex emelie-flex-col e-pt-10'>
                      <label className='emelie-input-title'>Water flow rate</label>
                      {
                        isLoading ? <Shimmer styles={{ root: { height: '40px' }, shimmerWrapper: { height: '40px' } }} />
                          :
                          <TextField
                            suffix={preferredFlowrateUnits}
                            type='text'
                            readOnly
                            value={`${designSession.coldMediaFlowrate}`}
                          />
                      }
                    </div>
                  }
                  {/* input */}
                  <div className='e-flex emelie-flex-col e-pt-10'>
                    <label className='emelie-input-title'>
                      Water temperature inlet
                    </label>
                    <Stack horizontal>
                      <Stack.Item grow>
                        <TextField
                          suffix={preferredTemperatureUnits}
                          type='number'
                          value={coldMediaTempIn}
                          onChange={(e) => { handleUpdateField('coldMediaTempIn', e.currentTarget.value) }}
                          errorMessage={coldMediaTempInError}
                        />
                      </Stack.Item>
                      <Stack.Item>
                        <EditIcon />
                      </Stack.Item>
                    </Stack>
                  </div>
                  {/* input */}
                  {
                    coldTempOutEnabled &&
                    <div className='e-flex emelie-flex-col e-pt-10'>
                      <label className='emelie-input-title'>
                        Water temperature outlet
                      </label>
                      {
                        isLoading ? <Shimmer styles={{ root: { height: '40px' }, shimmerWrapper: { height: '40px' } }} />
                          :
                          <TextField
                            suffix={preferredTemperatureUnits}
                            type='text'
                            readOnly
                            value={`${designSession.coldMediaTempOut}`}
                          />
                      }
                    </div>
                  }
                  {/* input */}
                  {
                    pressureDropEnabled &&
                    <div className='e-flex emelie-flex-col e-pt-10'>
                      <label className='emelie-input-title'>
                        Water pressure drop
                      </label>
                      {
                        isLoading ? <Shimmer styles={{ root: { height: '40px' }, shimmerWrapper: { height: '40px' } }} />
                          :
                          <TextField
                            suffix={preferredPressureUnits}
                            type='text'
                            value={`${designSession.coldMediaPressureDrop}`}
                          />
                      }
                    </div>
                  }
                </div>

              </div>
              <div>
                <PrimaryButton
                  className='emelieBtn e-ml-50 emelieBtn--blue e-mt-60'
                  text='Recalculate'
                  onClick={() => {
                    handleRecalculate()
                  }}
                />
              </div>
            </div>
          )}
          {/* vl */}
          <div className='emelie-design--vl'></div>
          {/* final */}
          <div className='e-bg-white e-ml-30 e-pl-16 e-pt-10'>
            <div className='e-flex emelie-justify-sb emelie-f-20'>
              <div className='emelie-lable'>
                <label className='emelie-title'>PHE Type</label>
                {
                  isLoading ? <Shimmer styles={{ root: { height: '40px', marginTop: 10 }, shimmerWrapper: { height: '40px' } }} />
                    :
                    <TextField
                      type='text'
                      value={designSession.phePlateType}
                      className='e-mr-30 e-mt-10 e-flex emelie-f-24--regular'
                      readOnly
                    />
                }
              </div>
              <div className='emelie-final--lable'>
                <label className='emelie-title'>
                  Plate Pack Configuration
                </label>
                {
                  isLoading ? <Shimmer styles={{ root: { height: '40px', marginTop: 10 }, shimmerWrapper: { height: '40px' } }} />
                    :
                    <TextField
                      type='text'
                      value={designSession.platePackConfiguration}
                      readOnly
                      className='e-mr-30 e-mt-10 e-flex emelie-f-24--regular'
                    />
                }
              </div>
            </div>
            <div className='emelie-flex emelie-flex-col'>
              <img src={Final} alt='final' className='emelie-alfaLaval-img' />
            </div>
            <div className='e-design--btn'>
              <DefaultButton
                className='emelieBtn'
                onClick={() => {
                  navigate('/designs/milkcooler');
                }}
              >
                {'Previous'}
              </DefaultButton>
              <PrimaryButton
                className='emelieBtn e-ml-50 emelieBtn--blue e-mb-30 e-width'
                onClick={() => {
                  navigate('/designs/milkcooler');
                }}
              >
                Proceed to mechanic configuration
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};
