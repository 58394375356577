import { IMsalContext } from '@azure/msal-react'
import { IUnitBaseConfiguration, IUnitConfiguration } from '../Models/IUnitConfiguration'
import { AuthenticationService } from './AuthenticationService'
import { getPreferredUnits } from './Global'

export class ApiService {
    private context: IMsalContext
    private apiUrl: string

    constructor(ctx: IMsalContext) {
        this.context = ctx
        this.apiUrl = process.env.REACT_APP_API_URL as string;
    }

    private async handleError(apiResponse: Response, onError?: (message: string) => void) {
        let err = await apiResponse.text();
        let errorText = `${apiResponse.url} - (${apiResponse.status}) `;

        if (apiResponse.status >= 400 && apiResponse.status < 500) {
            let error = JSON.parse(err);
            errorText += (error.title ?? error.Title) ?? error;
            if (error.errors !== undefined) {
                Object.keys(error.errors).sort((a, b) => (a > b ? 1 : -1)).forEach((key) => {
                    errorText += `\n| ${key.replace("$.", "Field with error: ")} | \n\t>> ${error.errors[key]}`;
                });
            }
        }
        else {
            errorText = err;
        }

        onError && onError(errorText);
        throw new Error(errorText);
    }

    public async getAsync(path: string, unitInputConfiguration?: IUnitConfiguration | IUnitBaseConfiguration, onError?: (error: any) => void) {
        const authService = new AuthenticationService(this.context)

        const accessToken = await authService.getAccessToken()

        const requestOptions = {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + accessToken,
                'Units': getPreferredUnits().preferredUnits,
                'UnitInputConfiguration': ''
            },
        }
        if (unitInputConfiguration !== undefined) {
            requestOptions.headers['UnitInputConfiguration'] = JSON.stringify(unitInputConfiguration);
        }

        const url: string = `${this.apiUrl}/api/${path}`
        const apiResponse = await fetch(url, requestOptions);

        if (!apiResponse.ok) {
            this.handleError(apiResponse, onError);
        }

        return apiResponse;
    }

    public async postAsync(
        input: any | FormData,
        apiPath: string,
        unitInputConfiguration?: IUnitConfiguration | IUnitBaseConfiguration,
        onError?: (error: any) => void
    ) {
        const authService = new AuthenticationService(this.context)
        const accessToken = await authService.getAccessToken()

        // depending on what body we'll have, either treat as a file or general payload.
        if (input instanceof FormData) {
            const requestOptions = {
                method: 'POST',
                body: input,
                headers: {
                    ContentType: 'multipart/form-data',
                    Authorization: 'Bearer ' + accessToken,
                    'Units': getPreferredUnits().preferredUnits,
                    'UnitInputConfiguration': ''
                },
            }
            if (unitInputConfiguration !== undefined) {
                requestOptions.headers['UnitInputConfiguration'] = JSON.stringify(unitInputConfiguration);
            }

            const url: string = `${this.apiUrl}/api/${apiPath}`
            const apiResponse = await fetch(url, requestOptions);

            if (!apiResponse.ok) {
                this.handleError(apiResponse, onError);
            }

            return apiResponse;

        }
        else {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify(input),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + accessToken,
                    'units': getPreferredUnits().preferredUnits,
                    'UnitInputConfiguration': ''
                },
            }
            if (unitInputConfiguration !== undefined) {
                requestOptions.headers['UnitInputConfiguration'] = JSON.stringify(unitInputConfiguration);
            }

            const url: string = `${this.apiUrl}/api/${apiPath}`
            const apiResponse = await fetch(url, requestOptions);

            if (!apiResponse.ok) {
                this.handleError(apiResponse, onError);
            }

            return apiResponse;
        }
    }

    public async delete(
        apiPath: string,
        unitInputConfiguration?: IUnitConfiguration,
        onError?: (error: any) => void
    ) {
        const authService = new AuthenticationService(this.context)
        const accessToken = await authService.getAccessToken()

        // depending on what body we'll have, either treat as a file or general payload.
        const requestOptions = {
            method: 'DELETE',
            headers: {
                ContentType: 'application/json',
                Authorization: 'Bearer ' + accessToken,
                'Units': getPreferredUnits().preferredUnits,
                'UnitInputConfiguration': ''
            },
        }
        if (unitInputConfiguration !== undefined) {
            requestOptions.headers['UnitInputConfiguration'] = JSON.stringify(unitInputConfiguration);
        }

        const url: string = `${this.apiUrl}/api/${apiPath}`
        const apiResponse = await fetch(url, requestOptions);

        if (!apiResponse.ok) {
            this.handleError(apiResponse, onError);
        }

        return apiResponse;
    }

    public async putAsync(
        input: any,
        apiPath: string,
        onError?: (error: any) => void
    ) {
        const authService = new AuthenticationService(this.context)
        const accessToken = await authService.getAccessToken()

        // depending on what body we'll have, either treat as a file or general payload.
        const requestOptions = {
            method: 'PUT',
            body: JSON.stringify(input),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + accessToken,
                'units': getPreferredUnits().preferredUnits
            },
        }

        const url: string = `${this.apiUrl}/api/${apiPath}`
        const apiResponse = await fetch(url, requestOptions);

        if (!apiResponse.ok) {
            this.handleError(apiResponse, onError);
        }

        return apiResponse;
    }

}
