import React, { useMemo } from "react";
import { Navbar } from "./Components/Molecules/Navbar/navbar";
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import Sidebar from "./Components/Molecules/Sidebar/Sidebar";
import { Dashboard } from "./Components/Molecules/Dashboard/Dashboard";
import Design from "./Components/Molecules/Design/Design";
import ChooseMachine from "./Components/Molecules/ChooseMachine/ChooseMachine";
import { FinalParameters } from "./Components/Molecules/FinalParameters/FinalParameters";
import SMESidebar from "./Components/Molecules/Sidebar/SMESidebar";
import { BlindTestDashboard } from "./Components/Molecules/SME/BlindTestDashboard/BlindTestDashboard";
import { BlindTestDetailsList } from "./Components/Molecules/BlindTesting/BlindTestDetailsList";

import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import {
  useMsal,
  IMsalContext
} from "@azure/msal-react";
import { AuthenticationService } from "./Services/AuthenticationService";
import { useEffect, useState } from "react";
import './App.scss';
import { getAppInsightsConnectionString, getLocalStorageItem, hasRole, setAppInsights, setFeatures, setHomeLocation, setLocalStorageItem } from "./Services/Global";
import { ApiService } from "./Services/ApiService";
import { createTheme, MessageBar, MessageBarType, ThemeProvider, useTheme } from "@fluentui/react";
import { BlindTestList } from "./Components/Molecules/SME/BlindTests/BlindTestList";
import { DesignSessionReviewForm } from "./Components/Molecules/SME/DesignSessionReview/DesignSessionReviewForm";
import { ProductDesigns } from "./Components/Molecules/ProductDesigns/ProductDesigns";
import { ProductDesignForm } from "./Components/Molecules/ProductDesigns/ProductDesignForm";
import { ModelerOverview, } from "./Components/Molecules/Modeler/ModelerOverview";
import { ModelDataSampleForm } from "./Components/Molecules/Modeler/ModelDataSampleForm";
import { Roles } from "./Models/Enums";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { UnitConfigurationManagement } from "./Components/Molecules/Admin/UnitConfiguration/UnitConfigurationManagement";
import { PHEPlateTypeManagement } from "./Components/Molecules/Admin/PHEPlateTypeManagement";
import { Admin } from "./Components/Molecules/Admin/Admin";
import { BlindTestOverview } from "./Components/Molecules/BlindTesting/BlindTestOverview";
import { About } from "./Components/Molecules/About/About";
import { UnitConfigurationEditor } from "./Components/Molecules/Admin/UnitConfiguration/UnitConfigurationEditor";
import { ModelDataSampleList } from "./Components/Molecules/Modeler/ModelDataSampleList";
import { UnitPropertySpecificationEditor } from "./Components/Molecules/Admin/UnitConfiguration/UnitPropertySpecificationEditor";
import { PropertyRangeSelection } from "./Components/Molecules/Admin/PropertyRangeSelection";

export const App: React.FC<{}> = () => {

  const theme = createTheme({
    palette: {
      themePrimary: '#007fc8',
      themeLighterAlt: '#f3f9fd',
      themeLighter: '#cfe8f6',
      themeLight: '#a7d4ee',
      themeTertiary: '#59addd',
      themeSecondary: '#198bce',
      themeDarkAlt: '#0065A0',
      themeDark: '#11387F',
      themeDarker: '#00476f',
      neutralLighterAlt: '#faf9f8',
      neutralLighter: '#f3f2f1',
      neutralLight: '#edebe9',
      neutralQuaternaryAlt: '#e1dfdd',
      neutralQuaternary: '#d0d0d0',
      neutralTertiaryAlt: '#c8c6c4',
      neutralTertiary: '#b4b2b0',
      neutralSecondary: '#9b9997',
      neutralSecondaryAlt: '#9b9997',
      neutralPrimaryAlt: '#83817e',
      neutralPrimary: '#201f1e',
      neutralDark: '#52504e',
      black: '#3a3836',
      white: '#ffffff',
      yellow: '#ffca42',
      yellowLight: '#ffecbd',
      red: '#E84745',
    }
  });

  const [signedIn, setSignedIn] = useState(false);
  const [isBusy, setIsBusy] = useState(true);
  const [isSme, setIsSme] = useState(false);
  const [isModeler, setIsModeler] = useState(false);
  const [isBlindTester, setIsBlindTester] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);
  const [blockUnassignedUsers, setBlockUnassignedUsers] = useState(false);
  const [errorData, setErrorData] = React.useState<string>('');
  const [sellerFeature, setSellerFeature] = useState(false);
  const [modelerFeature, setModelerFeature] = useState(false);
  const [showConditionalFields, setShowConditionalFields] = useState(false);

  initializeIcons();

  const ctx: IMsalContext = useMsal();
  const authService: AuthenticationService = new AuthenticationService(ctx);
  const apiService = useMemo(() => new ApiService(ctx), [ctx]);


  useEffect(() => {

    let firstTimeUser = getLocalStorageItem('showTutorial');
    if (firstTimeUser === null) {
      setLocalStorageItem('showTutorial', 'true');
    }

    const appInsights = new ApplicationInsights({
      config: {
        connectionString: getAppInsightsConnectionString()
      }
    });

    appInsights.loadAppInsights();

    setAppInsights(appInsights);

    async function signInAndGetFeatures() {
      await authService.getAccessToken();
      setSignedIn(true);

      if (hasRole(...['SME'])) {
        setIsSme(true);
        setHomeLocation('/blindtests/dashboard')
      }
      else if (hasRole(...['Modeler'])) {
        setIsModeler(true);
        setHomeLocation('/modeling/dashboard')
      }
      else {
        setIsBlindTester(true);
        setHomeLocation('/about');
      }

      // Allow all Alfa Laval users after ask 2023-04-12 by Magnus Hoffstein to jowessle
      // let allRoles: string[] = Object.values(Roles).filter(k => isNaN(Number(k))).map(r => r as string);
      // if (!hasRole(...allRoles)) {
      //   setUnauthorized(true);
      //   throw new Error('No roles exist for the user. Unauthorized.')
      // }

      getFeaturesFromApi();
    }

    async function getFeaturesFromApi() {
      try {
        var response = await apiService.getAsync('settings/features');
        if (!response.ok) {
          console.error(response.statusText);
        }

        const features = await response.json();
        setFeatures(features);

        setBlockUnassignedUsers(features["blockUnassignedUsers"]);
        setSellerFeature(features["sellerFeature"]);
        setModelerFeature(features["modelerFeature"]);
        setShowConditionalFields(features["showConditionalFields"]);

        setIsBusy(false);
      }
      catch {
        setIsBusy(false);
      }

    }

    signInAndGetFeatures();
  }, []);

  window.onunhandledrejection = function (e) {
    setErrorData("Oops, something went wrong: " + e.reason);
  }

  const dismissErrorHandler = () => {
    setErrorData('');
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="App emelie-height" style={{ background: theme.palette.neutralLighterAlt }}>
        {
          signedIn &&
            (unauthorized && blockUnassignedUsers) ?
            <div className="unauthorized">
              You are not authorized to use this application.
              <p>
                The application is intended for Sellers and SMEs.
              </p>
              <p>
                (insert contact details here.)
              </p>
            </div>
            :
            <>
              {errorData &&
                <MessageBar
                  messageBarType={MessageBarType.error}
                  dismissButtonAriaLabel="Close"
                  onDismiss={dismissErrorHandler}
                  dismissIconProps={{ iconName: 'Clear', styles: { root: { color: `${theme.palette.white} !important` } } }}
                  styles={{
                    root: {
                      background: theme.palette.red,
                      color: theme.palette.white,
                      position: 'absolute',
                      margin: '80px 100px',
                      width: '92vw',
                      borderRadius: '5px',
                      zIndex: 1000
                    },
                    icon: {
                      color: theme.palette.white
                    }
                  }}
                >
                  {errorData}
                </MessageBar>}

              {
                isBusy === false ?
                  <Pages
                    isSme={isSme}
                    sellerFeatureActive={sellerFeature}
                    modelerFeatureActive={modelerFeature}
                    showConditionalFields={showConditionalFields}
                    isModeler={isModeler}
                    isBlindtester={isBlindTester}
                  />
                  :
                  <></>
              }

            </>
        }

      </div>
    </ThemeProvider>
  );
}
export const Pages: React.FC<{
  isSme: boolean,
  sellerFeatureActive: boolean,
  modelerFeatureActive: boolean,
  showConditionalFields: boolean,
  isModeler: boolean,
  isBlindtester: boolean
}> = (props) => {

  let theme = useTheme();

  let starRedirection: JSX.Element = <></>;
  if (hasRole(...[Roles[Roles.SME]])) {
    starRedirection = <BlindTestDashboard isModeler={props.isModeler} isSme={props.isSme} />;
  }
  else if (hasRole(...[Roles[Roles.Modeler]])) {
    starRedirection = <ModelerOverview />;
  }
  else if (hasRole(...[Roles[Roles.BlindTestUser]])) {
    starRedirection = <BlindTestOverview />;
  }
  else {
    starRedirection = <About />;
  }

  return (
    <Router>
      <Navbar
        sellerFeatureActive={props.sellerFeatureActive}
        modelerFeatureActive={props.modelerFeatureActive}
      />


      <div className="emelie-flex">
        {

          props.isSme === true ?
            <SMESidebar />
            :
            <Sidebar showTutorial={true} />
        }
        <div className="emelie-body" style={{ boxShadow: theme.effects.elevation8 }}>
          <Routes>

            <Route path="*" element={starRedirection} />
            {
              //SME Only roles
              hasRole(...[Roles[Roles.SME]]) &&
              <>
                <Route path="/blindtests/dashboard" element={<BlindTestDashboard isModeler={props.isModeler} isSme={props.isSme} />} />
                <Route path="/admin" element={<Admin />}></Route>
                <Route path="/blindtests/:samplingId" element={<BlindTestList />} />
                <Route path="/DesignSessionReviewForm" element={<DesignSessionReviewForm showConditionalFields={props.showConditionalFields} />} />
              </>
            }
            {
              hasRole(...[Roles[Roles.Modeler]]) &&
              <>
                <Route path="/blindtests/dashboard" element={<BlindTestDashboard isModeler={props.isModeler} isSme={props.isSme} />} />
                <Route path="/blindtests/:samplingId" element={<BlindTestList />} />
                <Route path="/DesignSessionReviewForm" element={<DesignSessionReviewForm showConditionalFields={props.showConditionalFields} />} />
                <Route path="/admin/UnitConfigurationManagement" element={<UnitConfigurationManagement />} />
                <Route path="/admin/UnitConfigurationManagement/unitconfigurationeditor" element={<UnitConfigurationEditor />} />
                <Route path="/admin/unitconfigurationmanagement/propertyspecificationeditor/:i/:si/:a/:sa/:ps/:pl/:alp/:sc" element={<UnitPropertySpecificationEditor />} />
                <Route path="/admin/unitconfigurationmanagement/propertyrangespecificationeditor/:i/:si/:a/:sa/:ps/:pl/:alp/:sc/:ip" element={<PropertyRangeSelection />} />
                <Route path="/admin/PHEPlateTypeManagement" element={<PHEPlateTypeManagement />} />
              </>
            }
            {
              (hasRole(...[Roles[Roles.Seller]]) || hasRole(...[Roles[Roles.Modeler]])) &&
              <>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/design" element={<Design />} />
                <Route path="/choosemachine" element={<ChooseMachine />} />
                <Route path="/productdesigns" element={<ProductDesigns />} />
                <Route path="/finalparameters" element={<FinalParameters />} />
              </>
            }
            {
              hasRole(...[Roles[Roles.BlindTestUser]]) &&
              <>
                <Route path="/blindtests/overview" element={<BlindTestDetailsList />} />
                <Route path="/blindtests" element={<BlindTestOverview />} />
              </>
            }
            {
              props.modelerFeatureActive &&
              <>
                <Route path="/modeling/dashboard" element={<ModelerOverview />} />
                <Route path="/modeling/:samplingId/datasamples" element={<ModelDataSampleList />} />
                <Route path="/modeling/:samplingId/datasamples/:dataSampleId/review" element={<ModelDataSampleForm />} />
              </>
            }

            {/* Support landing on a product design page by URL + query navigation */}
            <Route path='/productdesign' element={<ProductDesignForm showConditionalFields={props.showConditionalFields} />} />
            <Route path='/about' element={<About />} />

            <Route path="/admin" element={<Admin />} />
            {
              props.isSme === true ?
                <Route path="/login/callback" element={<BlindTestDashboard isModeler={props.isModeler} isSme={props.isSme} />} />
                :
                <Route path="/login/callback" element={<Dashboard />} />
            }
          </Routes>
        </div>
      </div>

    </Router>
  )
}
export default App;