import React, { useEffect, useMemo, useState } from 'react'
import { CheckboxVisibility, DefaultButton, DetailsList, IColumn, Icon, IDetailsListStyles, Label, MotionAnimations, PrimaryButton, Spinner, SpinnerSize, Stack, TooltipHost, useTheme } from '@fluentui/react';
import { Filter } from '../Filter/Filter';
import ReactPaginate from 'react-paginate';
import { ApiService } from '../../../../Services/ApiService';
import { IMsalContext, useMsal } from '@azure/msal-react';
import { IDesignSession } from '../../../../Models/IDesignSession';
import { deCapitalizeFirstLetter, getLocalStorageItem, getPreferredUnits, setLocalStorageItem } from '../../../../Services/Global';
import { getFormattedDate } from '../../../../Utils/UnitsHelper';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Constants } from '../../../../Models/Constants';
import { IUnitConfiguration } from '../../../../Models/IUnitConfiguration';
import { IPositionSummary } from '../../../../Models/IPositionSummary';
import { Badge } from '@fluentui/react-components';
import './BlindTestList.scss';
import { generateHeader, getFormattedColumnValue, getPageKeyName } from '../../../../Utils/UnitConfigurationHelper';

export const BlindTestList: React.FC<{}> = () => {

    const ctx: IMsalContext = useMsal();
    const apiService = useMemo(() => new ApiService(ctx), [ctx]);

    let theme = useTheme();
    let navigate = useNavigate();
    let params = useParams();

    const { state } = useLocation() as { state: { positionSummary: IPositionSummary } };

    const pageKeyName = getPageKeyName({
        industry: state.positionSummary.industry,
        subIndustry: state.positionSummary.subIndustry,
        application: state.positionSummary.application,
        subApplication: state.positionSummary.subApplication,
        processStage: state.positionSummary.processStage,
        productLine: state.positionSummary.productLine,
        alfaLavalPosition: state.positionSummary.alfaLavalPosition
    } as IUnitConfiguration,
        Constants.UnitResultListPage);

    const pageSize = 20;
    const [currentPage, setCurrentPage] = useState(getLocalStorageItem(pageKeyName) ?? 0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [pageCount, setPageCount] = useState<number>(0);
    const [offset, setOffset] = useState<number>(0);
    const [currentData, setCurrentData] = useState<any>();
    const [currentFilter, setCurrentFilter] = useState(getLocalStorageItem(`${pageKeyName}_currentFilter`) ?? '');
    const [configurations, setConfigurations] = useState<IUnitConfiguration[]>([]);
    const [unitInputConfiguration, setUnitInputConfiguration] = useState<IUnitConfiguration>({
        industry: state.positionSummary.industry,
        subIndustry: state.positionSummary.subIndustry,
        application: state.positionSummary.application,
        subApplication: state.positionSummary.subApplication,
        processStage: state.positionSummary.processStage,
        productLine: state.positionSummary.productLine,
        alfaLavalPosition: state.positionSummary.alfaLavalPosition,
        breakdownOfProcessStage: '',
        coldMediaType: '',
        hotMediaType: '',
        inputs: [],
        nonUsedProperties: []
    });

    const [dataColumns, setDataColumns] = useState<IColumn[]>();

    const preferredTemperatureUnits: string = `°${getPreferredUnits().preferredUnits === 'us' ? 'F' : 'C'}`;
    const preferredFlowrateUnits: string = getPreferredUnits().preferredUnits === 'us' ? 'lb/h' : 'kg/h';
    const preferredPressureUnits: string = getPreferredUnits().preferredUnits === 'us' ? 'PSI' : 'kPa';


    const [showFilters, setShowFilters] = useState(false);

    const handlePageChange = async (pageNumber: any) => {
        if (unitInputConfiguration !== undefined) {
            const keyName = getPageKeyName(unitInputConfiguration, Constants.UnitResultListPage);
            setLocalStorageItem(keyName, pageNumber.selected.toString());
        }
        getDesignSessionData(currentFilter, pageNumber.selected);
        setOffset(pageNumber.selected);
      
    }

    let columns: IColumn[] = [
        {
            key: 'ID',
            name: 'Row',
            minWidth: 100,
            maxWidth: 120,
            fieldName: 'Id',
            onRender: (item?: IDesignSession, index?: number, column?: IColumn) => {
                return (
                    <TooltipHost key={`${index}-${item?.id}`} content={item?.id?.toString()}>
                        <p>{item?.id}</p>
                    </TooltipHost>
                )
            }
        },
        {
            key: 'source',
            name: '',
            minWidth: 100,
            maxWidth: 100,
            fieldName: 'render',
            onRender: renderSource
        },
        {
            key: 'TestRef',
            name: 'Ref Id',
            minWidth: 75,
            maxWidth: 75,
            fieldName: 'testReferenceId',
            onRender: (item?: IDesignSession, index?: number, column?: IColumn) => {
                return (
                    <TooltipHost key={`${index}-${item?.blindTestDataSetId}`} content={item?.blindTestDataSetId?.toString()}>
                        <p>{item?.blindTestDataSetId}</p>
                    </TooltipHost>
                )
            }
        },
        {
            key: 'ModelId',
            name: 'Model ID',
            minWidth: 100,
            maxWidth: 150,
            fieldName: 'modelId',
            onRender: (item?: IDesignSession, index?: number, column?: IColumn) => {
                return (
                    <TooltipHost key={`${index}-${item?.id}-modelid`} content={item?.modelId}>
                        <p>{item?.modelId}</p>
                    </TooltipHost>
                )
            }
        },
        {
            key: 'blindTestDataSetId',
            name: 'Dataset ID',
            minWidth: 80,
            maxWidth: 80,
            onRender: (item: IDesignSession, index?: number, column?: IColumn) => {
                return (
                    <TooltipHost key={`${index}-${item?.id}-datasetid`} content={item.blindTestDataSetId}>
                        <p>{item.blindTestDataSetId}</p>
                    </TooltipHost>
                )
            }
        },
        {
            key: 'MediaType',
            name: 'Media Type',
            minWidth: 80,
            maxWidth: 80,
            fieldName: 'hotMediaType',
            onRender: (input) => {
                return (

                    <TooltipHost content={input.hotMediaType}>
                        <p>
                            {input.hotMediaType}
                        </p>
                    </TooltipHost>
                )
            }
        },
        {
            key: 'createdDate',
            name: 'Created',
            minWidth: 100,
            maxWidth: 100,
            fieldName: 'createdDate',
            onRender: renderDate
        }
    ]

    useEffect(() => {
        if (configurations === undefined || configurations.length === 0) {
            setColumns();
        }

        getDesignSessionData();
    }, [configurations])

    async function getDesignSessionData(query?: string, pageNumber?: number) {
        console.log('getting design session data.', configurations);
        setIsLoading(true);
        const currentPage = Number(getLocalStorageItem(pageKeyName)) ?? 0;
        setCurrentPage(pageNumber ?? currentPage);
        if (configurations.length > 0) {
            const response = await apiService.getAsync(`designsessions/positions?pageOffset=${(currentPage as number) * pageSize}&pageSize=${pageSize}${query ?? currentFilter}&samplingId=${state.positionSummary.blindTestDataSetId}&sectionLength=${configurations.length}`, unitInputConfiguration);
            const data = await response.json();
            const designSessions: IDesignSession[] = data.items;

            let sessionGroupedData: any[] = [];

            //handle values with nullGuid - single sections
            //copy every values to sesionGroupedData
            if (configurations.length === 1)
                setSingleSectionData(designSessions, sessionGroupedData);
            else
                //handle grouped data with sectionId
                setGroupedData(designSessions, sessionGroupedData);

            const totalRows: number = data.totalRows / configurations.length;
            const offset: number = data.offset;
            setPageCount(totalRows / pageSize);
            setOffset(offset);
            setCurrentData(sessionGroupedData);
        }

        setIsLoading(false);
    }

    function setGroupedData(designSessions: IDesignSession[], sessionGroupedData: any[]) {
        let groupedData: { [key: string]: IDesignSession[] } = {};

        designSessions.forEach((item: IDesignSession) => {
            if (!item.sectionId) {
                return;
            }
            if (!groupedData[item.sectionId]) {
                groupedData[item.sectionId] = [];
            }

            groupedData[item.sectionId].push(item);
        });

        Object.keys(groupedData).forEach((key: string) => {
            const values = groupedData[key];
            values.sort((a, b) => (a.sectionNumber || 0) - (b.sectionNumber || 0));
            //const concatenatedId = values.map(value => value.id).join(' | ');
            let concatenatedSource = values.map(value => value.source).join(' | ');
            if (concatenatedSource.split(' | ').every(value => value.trim() === 'Unassigned')) {
                concatenatedSource = 'Unassigned';
            }

            let foundMinValue = false;
            for (let i = 1; i <= configurations.length; i++) {
                const value: any = values.find(c => c.sectionNumber === i);
                const inputProps = configurations.filter(c => c.sectionNumber === i).map(c => c.inputs).flat();

                if (value !== undefined) {

                    //setting this condition as we do not know if the minimum section number is 1 or 2 or 3 etc.
                    if (foundMinValue === false) {
                        foundMinValue = true;
                        const clonedValue = { ...value };
                        inputProps.forEach((property) => {
                            const propertyName = `s${i}_${property}`;
                            clonedValue[propertyName] = value[deCapitalizeFirstLetter(property)];
                        });
                        //clonedValue.id = concatenatedId;
                        clonedValue.source = concatenatedSource;
                        sessionGroupedData.push(clonedValue);
                    }
                    else {
                        //append properties to existing sessionData object
                        inputProps.forEach((property) => {
                            sessionGroupedData.find(c => c.sectionId == value.sectionId)[`s${i}_${property}`] = value[deCapitalizeFirstLetter(property)];
                        });
                    }
                }
            }

        });
    }

    function setSingleSectionData(designSessions: IDesignSession[], sessionGroupedData: any[]) {

        const i = 1;
        if (designSessions.length > 0) {
            const inputProps = configurations.filter(c => c.sectionNumber === i).map(c => c.inputs).flat();
            designSessions.forEach((value: any) => {
                const clonedValue = { ...value };
                inputProps.forEach((property) => {

                    const propertyName = `s${i}_${property}`;
                    clonedValue[propertyName] = value[deCapitalizeFirstLetter(property)];
                });
                sessionGroupedData.push(clonedValue);
            });

        }
    }

    async function setColumns() {
        const response = await apiService.getAsync('settings/unitconfigurations/sections', unitInputConfiguration);
        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const configurations: IUnitConfiguration[] = await response.json();
        setConfigurations(configurations);
        for (let i = 0; i < configurations.length; i++) {
            for (let j = 0; j < configurations[i].inputs.length; j++) {
                columns.push({
                    key: deCapitalizeFirstLetter('s' + (i + 1).toString() + "_" + configurations[i].inputs[j]),
                    name: `Sec${i + 1} ${getFormattedColumnValue(configurations[i].inputs[j], configurations[i])}`,
                    minWidth: 200,
                    maxWidth: 300,
                    fieldName: deCapitalizeFirstLetter(configurations[i].inputs[j]),
                    onRender: (item?: any, index?: number, column?: IColumn) => {
                        let output: string = '';
                        if (column!.key.toLowerCase().includes('flowrate')) {
                            output = `${item![column!.key]} ${preferredFlowrateUnits}`;
                        }
                        if (column!.key.toLowerCase().includes('pressure')) {
                            output = `${item![column!.key]} ${preferredPressureUnits}`;
                        }
                        if (column!.key.toLowerCase().includes('temp')) {
                            output = `${item![column!.key]} ${preferredTemperatureUnits}`;
                        }
                        return (
                            <TooltipHost content={output}>
                                <p style={{ color: configurations[i].inputs[j].includes('Hot') ? theme.palette.redDark : theme.palette.themePrimary }}>
                                    {output}
                                </p>
                            </TooltipHost>
                        );
                    }
                })
            }
        }

        columns.push({
            key: '2',
            name: '',
            minWidth: 100,
            maxWidth: 100,
            fieldName: 'modelerComment',
            onRender: (item?: any, index?: number, column?: IColumn) => {
                return (
                    <TooltipHost content={item.modelerComment}>
                        {item.modelerComment && <Icon iconName='Comment' style={{ fontSize: 16 }} />}
                    </TooltipHost>
                );
            }
        })

        setDataColumns(columns);

        setIsLoading(false);
    }

    function renderDate(item: any, index?: number, column?: IColumn) {
        return (
            <TooltipHost content={getFormattedDate(item.createdDate)}>
                <p>{getFormattedDate(item.createdDate)}</p>
            </TooltipHost>
        )
    }

    function renderSource(item?: IDesignSession, index?: number, column?: IColumn) {

        console.log(currentData, item, configurations)
        if (item?.source?.includes('|')) {
            return (
                <Badge
                    key={index}
                    title={item?.source}
                    onClick={() => {
                        navigate("/DesignSessionReviewForm", { state: { unitConfiguration: unitInputConfiguration, designSession: item, multiSectionConfiguration: configurations } });
                    }}
                    style={{ cursor: 'pointer', background: theme.palette.greenDark, color: 'white', width: 70, fontSize: 14, height: 20 }}>
                    Done
                </Badge>
            );
        }
        else {
            if (item?.source === Constants.SOURCE_SME) {
                return <Badge title='SME' onClick={() => { navigate("/DesignSessionReviewForm", { state: { unitConfiguration: unitInputConfiguration, designSession: item, multiSectionConfiguration: configurations } }); }}
                    style={{ cursor: 'pointer', background: theme.palette.yellow, color: 'white', width: 70, fontSize: 14, height: 20 }}>SME</Badge>
            }

            if (item?.source === Constants.SOURCE_EMELIE_BETTER_THAN_SME) {
                return <Badge title='EMELIE - better than SME' onClick={() => { navigate("/DesignSessionReviewForm", { state: { unitConfiguration: unitInputConfiguration, designSession: item, multiSectionConfiguration: configurations } }); }}
                    style={{ cursor: 'pointer', background: theme.palette.green, color: 'white', width: 70, fontSize: 14, height: 20 }}>EMELIE</Badge>
            }
            if (item?.source === Constants.SOURCE_EMELIE_ACCEPTABLE) {
                return <Badge title='EMELIE - acceptable' onClick={() => { navigate("/DesignSessionReviewForm", { state: { unitConfiguration: unitInputConfiguration, designSession: item, multiSectionConfiguration: configurations } }); }}
                    style={{ cursor: 'pointer', background: theme.palette.themePrimary, color: 'white', width: 70, fontSize: 14, height: 20 }}>EMELIE</Badge>
            }
            return <button className="emelie-text-btn" onClick={() => { navigate("/DesignSessionReviewForm", { state: { unitConfiguration: unitInputConfiguration, designSession: item, multiSectionConfiguration: configurations } }); }}>{"Check"}</button>
        }
    }

    const gridStyles: Partial<IDetailsListStyles> = {
        root: {
            overflowX: 'scroll',
            borderRadius: 5,
            marginTop: 20,
            width: '100%',
            boxShadow: theme.effects.elevation4
        }
    }


    const handleDataFilter = async (query: string) => {
        if (unitInputConfiguration !== undefined) {
            const keyName = getPageKeyName(unitInputConfiguration, Constants.UnitResultListPage);
            setLocalStorageItem(keyName, 0);
        }
        setCurrentFilter(query);
        getDesignSessionData(query, currentPage as number);
    }

    function itemSelected(item: IDesignSession) {
        navigate("/DesignSessionReviewForm", { state: { unitConfiguration: unitInputConfiguration, designSession: item, multiSectionConfiguration: configurations } });
    }

    return (
        <Stack style={{ padding: 20 }}>
            <Stack horizontal horizontalAlign='center'>
                <Stack.Item align='start'>
                    <DefaultButton iconProps={{ iconName: 'back' }} onClick={() => navigate('/blindtests/dashboard')}>Back</DefaultButton>
                    {
                        currentFilter !== '' &&

                        <Badge appearance='filled' color='warning' style={{ padding: 5, margin: '5px 10px' }}>
                            <Icon iconName='Warning' style={{ marginRight: 5 }} />
                            <span>Filters are active.</span>
                        </Badge>

                    }
                </Stack.Item>


                <Stack.Item align='center' grow>
                    <h1 style={{ textAlign: 'center' }}>{state.positionSummary.alfaLavalPosition}</h1>
                    <Label style={{ textAlign: 'center', marginTop: 10, padding: 0 }}>{state.positionSummary.productLine}</Label>
                </Stack.Item>

                <Stack.Item align='end'>
                    <Stack>
                        <Stack.Item>
                            <PrimaryButton iconProps={{ iconName: 'Filter' }} disabled={isLoading} onClick={() => setShowFilters(!showFilters)}>{showFilters ? 'Hide filters' : 'Show filters'}</PrimaryButton>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
            {
                isLoading === true ?
                    <Spinner className='loading-spinner' size={SpinnerSize.large} label={'Loading...'} />
                    :
                    <>

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {
                                <div style={{
                                    marginTop: 10,
                                    animation: showFilters ? MotionAnimations.fadeIn : MotionAnimations.fadeOut,
                                    animationIterationCount: 1
                                }} hidden={showFilters ? false : true}>
                                    <Filter unitInputConfiguration={unitInputConfiguration!} handleFilter={(query) => handleDataFilter(query)} />
                                </div>
                            }
                        </div>

                        <>
                            {
                                currentData && dataColumns && !isLoading &&
                                <>
                                    <DetailsList
                                        items={currentData}
                                        styles={gridStyles}
                                        columns={dataColumns}
                                        checkboxVisibility={CheckboxVisibility.hidden}
                                        onActiveItemChanged={(item) => {
                                            itemSelected(item);
                                        }} />
                                    <Stack>
                                        <Stack.Item align='center'>
                                            <div className='pagination-container'>
                                                <ReactPaginate
                                                    breakLabel='...'
                                                    nextLabel='>'
                                                    onPageChange={handlePageChange}
                                                    pageCount={pageCount}
                                                    previousLabel='<'
                                                    forcePage={currentPage as number}
                                                />
                                            </div>
                                        </Stack.Item>
                                    </Stack>
                                </>
                            }
                        </>
                    </>
            }
        </Stack>
    )
}