import { IMsalContext, useMsal } from "@azure/msal-react";
import { DefaultButton, Dialog, DialogType, FontWeights, Icon, IconButton, Label, mergeStyleSets, Modal, PrimaryButton, Spinner, SpinnerSize, Stack, TextField, TooltipHost, useTheme } from "@fluentui/react";
import { Badge } from "@fluentui/react-components";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IDataSampleSummaryItem } from "../../../Models/IDataSampleSummaryItem";
import { IUnitConfiguration } from "../../../Models/IUnitConfiguration";
import { hoverableStackStyles } from "../../../Models/StackStyling";
import { ApiService } from "../../../Services/ApiService";
import { getFormattedDate } from "../../../Utils/UnitsHelper";

export const DataSampleList: React.FC<{
    dataSamples: IDataSampleSummaryItem[],
    downloadingSample: string,
    downloadDataSampleAsCsv: any,
    unitInputConfig: IUnitConfiguration,
    onDelete?: any
}> = ({
    dataSamples,
    downloadingSample,
    downloadDataSampleAsCsv,
    unitInputConfig,
    onDelete }) => {

        const ctx: IMsalContext = useMsal();
        const apiService = useMemo(() => new ApiService(ctx), [ctx]);

        const [usedFor, setUsedFor] = useState('');
        const [datasetName, setDatasetName] = useState('');
        const [isModalOpen, setIsModalOpen] = useState('');
        const [isLocking, setIsLocking] = useState<boolean>(false);
        const [isDeleting, setIsDeleting] = useState<boolean>(false);
        const [showDeleteConfirmation, setShowDeleteConfirmation] = useState('');

        let theme = useTheme();
        let navigate = useNavigate();
        let params = useParams();

        const contentStyles = modalStyles(theme);

        function modalStyles(theme: any) {
            return mergeStyleSets({
                container: {
                    display: 'flex',
                    flexFlow: 'column nowrap',
                    alignItems: 'stretch',
                },
                header: [
                    theme.fonts.xLargePlus,
                    {
                        flex: '1 1 auto',
                        flexDirection: 'row',
                        borderTop: `4px solid ${theme.palette.themePrimary}`,
                        color: theme.palette.neutralPrimary,
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: FontWeights.bold,
                        padding: '12px 12px 14px 24px',
                        backgroundColor: 'lightgray',
                        flexdirection: 'row'
                    },
                ],
                body: {
                    display: 'table',
                    flex: '4 4 auto',
                    borderSpacing: 4,
                    padding: '0 24px 24px 24px',
                    margin: '4px',
                    overflowY: 'hidden',
                    selectors: {
                        p: { margin: '14px 0' },
                        'p:first-child': { marginTop: 0 },
                        'p:last-child': { marginBottom: 0 },
                    },
                }
            });
        }

        const iconButtonStyles = iconModalStyles(theme);
        function iconModalStyles(theme: any) {
            return {
                root: {
                    color: theme.palette.neutralPrimary,
                    marginLeft: 'auto',
                    marginTop: '4px',
                    marginRight: '2px',
                },
                rootHovered: {
                    color: theme.palette.neutralDark,
                },
            };
        }

        function showModal(item: IDataSampleSummaryItem) {
            setDatasetName(item.datasetName);
            setUsedFor(item.usedFor);
            setIsModalOpen(item.samplingId);
        }

        function hideModal() {
            setIsModalOpen('');
        }

        async function lockRecord(item: IDataSampleSummaryItem) {
            setIsLocking(true);
            let body: any = {}
            body.id = item.id;
            body.samplingId = item.samplingId;
            body.datasetName = datasetName;

            const response = await apiService.postAsync(body, `modeling/lock`);
            if (!response.ok) {
                setIsLocking(false);
                throw Error(`Could not lock record. ${response.status} ${response.statusText}`);
            }
            var result = await response.json();
            item.id = result.id;
            item.datasetName = result.datasetName;
            item.usedFor = result.usedFor;
            item.lockedStatus = 0; //for now its defualt need to decide which status we need here
            item.locked = true;
            setIsLocking(false);
            hideModal();
        }

        function goToDesign(item: any) {
            unitInputConfig.samplingId = item.samplingId;
            unitInputConfig.prefferedUnit = item.prefferedUnit;
            navigate(`/modeling/${item.samplingId}/datasamples`, { state: { unitInputConfig } });
        }

        const deleteDataSamples = async (samplingId: string) => {
            setIsDeleting(true);
            const response = await apiService.delete(`modeling/datasamples/${samplingId}`);
            if (!response.ok) {
                setShowDeleteConfirmation('');
                throw Error(`Could not delete record. ${response.status} ${response.statusText}`);
            }
            setIsDeleting(false);
            setShowDeleteConfirmation('');
            onDelete && onDelete();
        }

        return (
            <>
                {
                    dataSamples?.map((item, index) => {

                        return (

                            <Stack horizontal style={{ padding: 0 }} key={`dataSample-${index}`}>
                                <Stack.Item align='center'>
                                    <TooltipHost content={'Download data sample'}>
                                        {
                                            downloadingSample === item.samplingId &&
                                            <Spinner label='Fetching...' />
                                        }
                                        <IconButton iconProps={{ iconName: 'Download' }} onClick={() => downloadDataSampleAsCsv(item.samplingId)} disabled={downloadingSample === item.samplingId} />
                                    </TooltipHost>
                                    <TooltipHost content={item.todo === 0 ? 'Mark data sample as ready for training' : 'All samples must be completed in order to mark the sample as completed.'}>
                                        <IconButton style={{ color: theme.palette.green }} iconProps={{ iconName: 'CheckboxComposite' }} disabled={downloadingSample === item.samplingId} onClick={() => item.todo === 0 ? showModal(item) : ''} />
                                    </TooltipHost>

                                    <IconButton iconProps={{ iconName: 'Delete' }} onClick={() => setShowDeleteConfirmation(item.samplingId)} style={{ color: theme.palette.redDark }} />

                                    <Modal titleAriaId="id" isOpen={isModalOpen === item.samplingId} isModeless={false} onDismiss={hideModal} isBlocking={true} isDarkOverlay={true}
                                        containerClassName={contentStyles.container} >
                                        <Stack grow className={contentStyles.header}>
                                            <Label>Completing {item.samplingId}</Label>
                                            <IconButton styles={iconButtonStyles} iconProps={{ iconName: 'Cancel' }} ariaLabel="Close"
                                                style={{ float: 'right' }} onClick={hideModal} />
                                        </Stack>
                                        <form onSubmit={(e) => { e.preventDefault(); return lockRecord(item) }}>
                                            <table className={contentStyles.body}>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <Label>Uploaded by</Label>
                                                        </td>
                                                        <td>
                                                            <Label>{item.user}</Label>

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <Label >Dataset</Label>
                                                        </td>
                                                        <td>
                                                            <TextField required multiline={false} style={{ height: '30px', fontSize: '14' }} value={datasetName} onChange={(e, i) => setDatasetName(i!.toString())}></TextField>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <Label>Used for</Label>
                                                        </td>
                                                        <td>
                                                            <TextField multiline={false} style={{ height: '30px', fontSize: '14' }} value={usedFor} readOnly></TextField>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                        </td>
                                                        <td>
                                                            <TooltipHost content={<p>Marks the data set as <strong>complete</strong>, meaning the data set should be picked up by the Machine Learning pipelines.</p>}>
                                                                <Stack horizontal>
                                                                    <PrimaryButton type='submit' text='Mark as completed' disabled={isLocking} iconProps={{ iconName: 'CheckboxComposite' }} />
                                                                    <Stack.Item style={{ padding: 8 }}>
                                                                        <Icon style={{ color: theme.palette.themePrimary }} iconName='Info' />
                                                                    </Stack.Item>
                                                                </Stack>
                                                            </TooltipHost>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </Modal>
                                </Stack.Item>
                                {
                                    item.locked &&
                                    <Badge appearance='tint' style={{ marginLeft: 80, marginTop: 5, position: 'absolute' }} icon={<Icon iconName='lock' />}>
                                        Locked - {item.usedFor} {item.usedFor ? '🚀' : '🧪'}
                                    </Badge>
                                }
                                <Stack horizontal styles={hoverableStackStyles(theme)} grow onClick={() => goToDesign(item)}>
                                    <Stack.Item grow>
                                        <Label>SamplingId</Label>
                                        <Label style={{ cursor: 'pointer' }}>{item.samplingId}</Label>
                                    </Stack.Item>
                                    <Stack.Item grow>
                                        <Label>Uploaded by</Label>
                                        <p>{item.user}</p>
                                    </Stack.Item>
                                    <Stack.Item grow>
                                        <Label>Created</Label>
                                        <p>{getFormattedDate(item.created.toString())}</p>
                                    </Stack.Item>
                                    <Stack.Item grow>
                                        <Label>Modified</Label>
                                        <p>{item.lastUpdate ? getFormattedDate(item.lastUpdate.toString()) : 'Never'}</p>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Label>Status</Label>
                                        <Badge>{item.todo} To do</Badge>
                                        <Badge color='success' style={{ marginLeft: 5 }} >{item.done} Done</Badge>
                                    </Stack.Item>
                                </Stack>
                            </Stack>
                        )
                    })
                }
                {
                    showDeleteConfirmation &&
                    <Dialog hidden={false} dialogContentProps={{
                        type: DialogType.normal,
                        title: 'Delete data sample set',
                    }}>
                        <Stack>
                            <Stack.Item>
                                <p>
                                    Are you sure you want to delete data sample <code>{showDeleteConfirmation}</code>?
                                </p>
                                <p>
                                    This action cannot be undone.
                                </p>
                            </Stack.Item>
                            <Stack horizontalAlign='center' horizontal style={{ padding: 10, marginTop: 15 }}>
                                {
                                    isDeleting ?
                                        <Spinner size={SpinnerSize.large} label='Deleting data sample set...' />
                                        :
                                        <>
                                            <Stack.Item>
                                                <DefaultButton text='Cancel' onClick={() => setShowDeleteConfirmation('')} />
                                            </Stack.Item>
                                            <Stack.Item style={{ marginLeft: 5 }}>
                                                <DefaultButton disabled={isDeleting} style={{ color: theme.palette.redDark }} text='Delete' onClick={() => deleteDataSamples(showDeleteConfirmation)} />
                                            </Stack.Item>
                                        </>
                                }
                            </Stack>
                        </Stack>
                    </Dialog>
                }
            </>

        )
    }