import { INavLink, Pivot, PivotItem, Stack } from '@fluentui/react';
import { getLocalStorageItem, hasRole } from '../../../Services/Global';
import { Roles } from '../../../Models/Enums';

export const AdminNavigation: React.FC<{
    updateSelection: (key: string) => void
}> = ({ updateSelection }) => {
    let navLinks: INavLink[] = [
        {
            name: 'Settings',
            url: 'Settings',
            isExpanded: true,
            onClick: (e) => { e?.preventDefault(); updateSelection('Settings') },
        }
    ];

    if (hasRole(...[Roles[Roles.Modeler]])) {
        navLinks.push(
            {
                name: 'Unit configuration',
                url: 'UnitConfigurationManagement',
                isExpanded: true,
                onClick: (e) => { e?.preventDefault(); updateSelection('UnitconfigurationManagement') },
            },
            {
                name: 'PHE Plate type',
                url: 'PHEPlateTypeManagement',
                isExpanded: false,
                onClick: (e) => { e?.preventDefault(); updateSelection('PHEPlateTypeManagement') },
            }
        )
    }

    if (hasRole(...[Roles[Roles.SME]])) {
        navLinks.push(
            {
                name: 'SME design guideline',
                url: 'SMEDesignGuideline',
                isExpanded: false,
                onClick: (e) => { e?.preventDefault(); updateSelection('SMEDesignGuideline') },
            }
        )
    }

    return (
        <Stack horizontal style={{ padding: 20 }}>
            <Stack.Item style={{ padding: 10 }}>
                <h3>Administration</h3>
            </Stack.Item>
            <Stack.Item>
                <Pivot onLinkClick={(e) => { updateSelection(e!.props.itemKey!) }} defaultSelectedKey={getLocalStorageItem('admin:currentSelection') ?? 'Settings'}>
                    {
                        navLinks.map((link, index) => {
                            return (
                                <PivotItem key={index} headerText={link.name} itemKey={link.url} />
                            )
                        })
                    }
                </Pivot>
            </Stack.Item>
        </Stack>
    );
}
