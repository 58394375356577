import React, { Component } from "react";
import BreadCrumb from "../../Atom/Breadcrumbs/Breadcrumb";
import Tooltip from "../../Atom/Tooltip/Tooltip";
import { NavLink } from "react-router-dom";
import machine from "../../../Assets/PNG/machines.png";
import "../../Atom/ButtonProps/ButtonProps";
import Tooltip1 from "../../Atom/Tooltip/Tooltip-1/Tooltip1";
import Tooltip2 from "../../Atom/Tooltip/Tooltip-2/Tooltip2";
import Tooltip3 from "../../Atom/Tooltip/Tooltip-3/Tooltip3";
import Tooltip4 from "../../Atom/Tooltip/Tooltip-4/Tooltip4";
import Tooltip5 from "../../Atom/Tooltip/Tooltip-5/Tooltip5";
import Tooltip6 from "../../Atom/Tooltip/Tooltip-6/Tooltip6";
import Tooltip7 from "../../Atom/Tooltip/Tooltip-7/Tooltip7";
import Tooltip8 from "../../Atom/Tooltip/Tooltip-8/Tooltip8";
import Tooltip9 from "../../Atom/Tooltip/Tooltip-9/Tooltip9";
import Tooltip10 from "../../Atom/Tooltip/Tooltip-10/Tooltip10";
import { useNavigate } from "react-router";
import { DefaultButton } from "@fluentui/react";
export default function ChooseMachine() {
  let navigate = useNavigate();
  return (
    <div className="emelie-height emelie-width">
      <BreadCrumb />
      <div className="emelie-flex emelie-flex-col emelie-justify-sb e-p-30">
        <div>
          <div className="emelie-f-32">Step.2</div>
          <div className="emelie-f-24--regular e-pt-10">
            Choose Position <Tooltip name="Click on Your heat exchanger choice to make a design" />
          </div>
          <div className="emelie-flex">
            <img src={machine} alt="machine1" className="e-absolute e-pt-25" />
            <NavLink to="/productdesigns/milkcooler">
              <Tooltip1 />
            </NavLink>
            <Tooltip2 />
            <Tooltip3 />
            <Tooltip4 />
            <Tooltip5 />
            <Tooltip6 />
            <Tooltip7 />
            <Tooltip8 />
            <Tooltip9 />
            <Tooltip10 />
          </div>
        </div>
        <div className="emelie-flex-center">
          <DefaultButton className="emelieBtn emelie-btn--white" onClick={() => {
            navigate("/Design");
          }}>{"Previous"}</DefaultButton>
        </div>
      </div>
    </div>

  );
}
