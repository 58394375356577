import { FontSizes, MessageBar, PrimaryButton, Stack, useTheme } from '@fluentui/react'
import { generalStackStyles } from '../../../Models/StackStyling';
import { useNavigate } from 'react-router-dom';
import { getLocalStorageItem, setLocalStorageItem } from '../../../Services/Global';
import { useEffect, useState } from 'react';

export const About: React.FC = () => {

    let theme = useTheme();
    let nav = useNavigate();

    const [returnLocation, setReturnLocation] = useState<string>();



    useEffect(() => {
        setLocalStorageItem('showTutorial', 'false');
        setReturnLocation(getLocalStorageItem('productdesign') ?? 'null');
    }, [])

    return (
        <>
            <Stack style={{ padding: 15 }}>
                <Stack.Item>
                    <h1>About</h1>
                </Stack.Item>
            </Stack>
            <Stack styles={generalStackStyles(theme)} >
                <Stack.Item style={{ padding: 5, marginBottom: 20 }} align='start'>
                    <h3>
                        Welcome to Alfa Laval's AI Design Wizard for Plate Heat Exchangers.
                    </h3>
                </Stack.Item>
                <Stack.Item style={{ fontSize: FontSizes.medium }}>
                    <p>
                        This is Alfa Laval's AI based Design Wizard for Plate Heat Exchangers that helps you to quickly find the right design for the specified heat exchanger positions.
                        <br />
                        The designs that the AI Design Wizard suggests are based on decades of experience and application expertise.
                        <br />
                        <br />
                        If you quote the AI Design Wizard's solution, Alfa Laval guarantees the thermal performance, because we know they work as intended.
                        <br />
                        For any questions or feedback regarding the AI Design Wizard, please reach out to your local Alfa Laval contact person or <a href="mailto:magnus.hoffstein@alfalaval.com?subject=AI Design Wizard General Feedback">use the feedback function in the app.</a>
                        <br />
                        <br />
                        With your feedback we can make the AI Design Wizard better.
                        <br />
                        <br />
                        The AI Design Wizard will always calculate a competitive PHE design for the given duty.
                        <br />
                        For the models included in Alfa Laval's new hygienic line, H4, H8, etc., you copy and paste the result into the configurator by using the "copy" button.
                        <br />
                        <br />
                        The older models, Base6, Base10, etc., are still designed in CAS.
                        <br />
                        We are working on a solution to automatically export these results from the AI Design Wizard to CAS.
                        <br />
                        Until that's in place you can manually enter the AI Design Wizard's result into CAS.
                        <br />
                        <br />
                        <p>Thank you for using the AI Design Wizard!</p>
                    </p>
                </Stack.Item>
            </Stack>
            <Stack>
                <Stack.Item align='center'>
                    {
                        returnLocation !== null && returnLocation !== undefined && returnLocation.includes('null') === false ?
                            <PrimaryButton text='Return' onClick={() => nav(returnLocation)} />
                            :
                            <MessageBar>
                                It seems like you didn't get to this page through the configurator. Please return to the configurator to calculate designs.
                            </MessageBar>
                    }
                </Stack.Item>
            </Stack>
        </>
    )
}