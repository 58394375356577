import React, { useState } from "react";
import "./Breadcrumb.scss";
import { NavLink } from "react-router-dom";

export default function () {
  const [activeButton, setActiveButton] = useState("");
  return (
    <div className="emelie-breadcrumb">
      <ul className="emelie-breadcrumb--item">
        <li>
        <NavLink
          to="/Dashboard"
          onClick={() => setActiveButton("Dashboard")}
          className={activeButton === "Dashboard" ? "active" : "link"}
        >
          Dashboard
        </NavLink>
        </li>
        <li className="emelie-divide">/</li>
        <li>
        <NavLink
          to="/Design"
          onClick={() => setActiveButton("Design")}
          className={activeButton === "Design" ? "active" : "link"}
        >
          Design
        </NavLink>
        </li>
      </ul>
    </div>
  );
}
