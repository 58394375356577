import { useEffect, useState } from "react";
import { AdminNavigation } from "./AdminNavigation"
import { SMEDesignGuideline } from './SMEDesignGuideline'
import { UnitConfigurationManagement } from "./UnitConfiguration/UnitConfigurationManagement";
import { PHEPlateTypeManagement } from "./PHEPlateTypeManagement";
import { Settings } from "./Settings";
import { getLocalStorageItem, setLocalStorageItem } from "../../../Services/Global";

export const Admin: React.FC<{}> = () => {

    const [selectedKey, setSelectedKey] = useState(getLocalStorageItem('admin:currentSelection'));

    const handleSelection = (key: string) => {
        setSelectedKey(key);
    }

    useEffect(() => {
        setLocalStorageItem('admin:currentSelection', selectedKey);
    }, [selectedKey])

    return (
        <>
            <AdminNavigation updateSelection={handleSelection} />
            {
                selectedKey === 'UnitConfigurationManagement' &&
                <UnitConfigurationManagement />
            }
            {
                selectedKey === 'PHEPlateTypeManagement' &&
                <PHEPlateTypeManagement />
            }
            {
                selectedKey === 'SMEDesignGuideline' &&
                <SMEDesignGuideline />
            }
            {
                selectedKey === 'Settings' &&
                <Settings />
            }
        </>
    )
}