import { Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Label } from '@fluentui/react-components';
import { CalendarCancel24Regular, DismissCircle24Regular, Search12Filled, ZoomIn16Regular } from '@fluentui/react-icons';
import * as React from 'react';




export const ImagePreview: React.FC<{ imageSource: string, className?: string }> = (props) => {

    return (
        <Dialog>
            <DialogTrigger>
                <Button className={props.className} icon={<ZoomIn16Regular />}>Preview</Button>
            </DialogTrigger>
            <DialogSurface>
                <DialogBody >
                    <DialogContent>
                        <div className="al-container-vflex">
                            <img className="al-container-vflex al-container-vcenter" style={{width: '100%', objectFit: 'contain'}} src={props.imageSource} alt="preview" />
                        </div>
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}
