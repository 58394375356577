import React, { useEffect, useMemo, useState } from 'react'
import { CheckboxVisibility, DefaultButton, DetailsList, DetailsListLayoutMode, IColumn, Icon, IconButton, IDetailsListStyles, Label, MotionAnimations, PrimaryButton, Spinner, SpinnerSize, Stack, TooltipHost, useTheme } from '@fluentui/react';
import { Filter } from '../SME/Filter/Filter';
import ReactPaginate from 'react-paginate';
import { ApiService } from '../../../Services/ApiService';
import { IMsalContext, useMsal } from '@azure/msal-react';
import { getFlowTypeUnits, getLocalStorageItem, getPreferredUnits, setLocalStorageItem } from '../../../Services/Global';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IUnitConfiguration } from '../../../Models/IUnitConfiguration';
import { Badge } from '@fluentui/react-components';
import { IDataSample } from '../../../Models/IDataSample';
import { Constants } from '../../../Models/Constants';
import { getPageKeyName } from '../../../Utils/UnitConfigurationHelper';
import { generalStackStyles } from '../../../Models/StackStyling';
import { DataSampleStatus } from '../../../Utils/Enums';


export const ModelDataSampleList: React.FC<{}> = () => {

    const ctx: IMsalContext = useMsal();
    const apiService = useMemo(() => new ApiService(ctx), [ctx]);

    let theme = useTheme();
    let navigate = useNavigate();
    let params = useParams();
    const { state } = useLocation() as { state: any };

    const pageSize = 20;

    const pageKeyName = getPageKeyName(state.unitInputConfig, Constants.ModelDataSampleListPage);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [pageCount, setPageCount] = useState<number>(0);
    const [currentData, setCurrentData] = useState<IDataSample[]>([]);
    const [currentFilter, setCurrentFilter] = useState(getLocalStorageItem(`${pageKeyName}_currentFilter`) ?? '');
    const [currentPage, setCurrentPage] = useState(getLocalStorageItem(pageKeyName) ?? 0);
    const [dataColumns, setDataColumns] = useState<IColumn[]>();
    const [configurations, setConfigurations] = useState<IUnitConfiguration[]>([]);
    const unitInputConfiguration = state.unitInputConfig as IUnitConfiguration;

    const preferredTemperatureUnits: string = `°${getPreferredUnits().preferredUnits === 'us' ? 'F' : 'C'}`;
    let preferredFlowrateUnits: string = getFlowTypeUnits(state.unitInputConfig.flowType!);
    const preferredPressureUnits: string = getPreferredUnits().preferredUnits === 'us' ? 'PSI' : 'kPa';
    const mediaProperties = [
        Constants.ColdMediaTempIn,
        Constants.HotMediaFlowrate,
        Constants.HotMediaTempIn,
        Constants.HotMediaTempOut,
        Constants.HotMediaPressureDrop,
        Constants.ColdMediaFlowrate,
        Constants.ColdMediaTempOut,
        Constants.ColdMediaPressureDrop
    ];

    const [showFilters, setShowFilters] = useState(false);

    const handlePageChange = async (pageNumber: any) => {
        console.log("Current page: ", pageNumber.selected);
        console.log("Page size: ", currentFilter);

        const response = await apiService.getAsync(`modeling/datasamples?pageOffset=${pageNumber.selected * pageSize}&pageSize=${pageSize}&samplingId=${params.samplingId}${currentFilter}`, unitInputConfiguration);
        const data = await response.json();
        const sessionData: IDataSample[] = data.items;
       
        setCurrentData(sessionData);
        setIsLoading(false);
        if (unitInputConfiguration !== undefined) {
            const keyName = getPageKeyName(unitInputConfiguration, Constants.ModelDataSampleListPage);
            setLocalStorageItem(keyName, pageNumber.selected.toString());
        }
    }

    

    let columns: IColumn[] = [
        {
            key: 'id',
            name: 'Id',
            minWidth: 50,
            maxWidth: 75,
            fieldName: 'id',
            onRender(item, index, column) {
                return (
                    <TooltipHost content={item.id}>
                        <p>{item.id}</p>
                    </TooltipHost>
                )
            },
        },
        {
            key: '1',
            name: '',
            minWidth: 75,
            maxWidth: 75,
            fieldName: 'render',
            onRender: renderSource
        },
        {
            key: '2',
            name: '',
            minWidth: 75,
            maxWidth: 75,
            fieldName: 'comment',
            onRender: renderSource
        }
    ]

    async function getDesignSessionData(query?: string) {
        const currentPage = Number(getLocalStorageItem(pageKeyName)) ?? 0;
        setCurrentPage(currentPage);
        const configResponse = await apiService.getAsync('settings/unitconfiguration', unitInputConfiguration);
        const config = await configResponse.json();
        preferredFlowrateUnits = getFlowTypeUnits(config.flowType);
        setConfigurations([config]);

        let response: Response;
        if (config.sectionCount > 1) {

            response = await apiService.getAsync(`modeling/datasamples/${params.samplingId}/all${query?.includes("onlyTodo") ? "?onlyTodo=true" : ""}`, config);
        }
        else {
            response = await apiService.getAsync(`modeling/datasamples?pageOffset=${(currentPage as number) * pageSize}&pageSize=${pageSize}&samplingId=${params.samplingId}${query ?? currentFilter}`, config);
        }
        const data = await response.json();

        const dataSamples: IDataSample[] =  data.items;

        

        let groupedData: { [key: string]: any[] } = {};

        dataSamples.forEach((item: IDataSample) => {
            if (!item.sectionId) {
                return;
            }
            if (!groupedData[item.sectionId]) {
                groupedData[item.sectionId] = [];
            }

            groupedData[item.sectionId].push(item);
        });

        let output: any[] = [];
        if (config.isMultiSection === true) {
            for (let j = 0; j < Object.keys(groupedData).length; j++) {
                const key = Object.keys(groupedData)[j];
                const values = groupedData[key];
                values.sort((a, b) => (a.sectionNumber || 0) - (b.sectionNumber || 0));
                let container: any = {
                    id: values[0].id,
                    sectionId: key,
                    dataSampleStatus: values[0].dataSampleStatus,
                }

                for (let i = 0; i < values.length; i++) {
                    // create new object with sectionId and Sec1, Sec2, Sec3 etc. properties
                    const sec: IDataSample = values[i];
                    sec.hotMediaFlowrate && (container[`Sec${sec.sectionNumber}HotMediaFlowrate`] = sec.hotMediaFlowrate);
                    sec.hotMediaPressureDrop && (container[`Sec${sec.sectionNumber}HotMediaPressureDrop`] = sec.hotMediaPressureDrop);
                    sec.hotMediaTempIn && (container[`Sec${sec.sectionNumber}HotMediaTempIn`] = sec.hotMediaTempIn);
                    sec.hotMediaTempOut && (container[`Sec${sec.sectionNumber}HotMediaTempOut`] = sec.hotMediaTempOut);

                    sec.coldMediaFlowrate && (container[`Sec${sec.sectionNumber}ColdMediaFlowrate`] = sec.coldMediaFlowrate);
                    sec.coldMediaPressureDrop && (container[`Sec${sec.sectionNumber}ColdMediaPressureDrop`] = sec.coldMediaPressureDrop);
                    sec.coldMediaTempIn && (container[`Sec${sec.sectionNumber}ColdMediaTempIn`] = sec.coldMediaTempIn);
                    sec.coldMediaTempOut && (container[`Sec${sec.sectionNumber}ColdMediaTempOut`] = sec.coldMediaTempOut);
                }

                output.push(container);
            }
        }
        else {
            output = dataSamples;
        }

        if (output.length === 0) {
            output = []
            setCurrentData(output);
            return;
        }

        Object.keys(output[0]).forEach((key: string) => {
            const found = columns.find(c => c.key === key);
            if (key.toLowerCase().includes('flowrate') || key.toLowerCase().includes('pressure') || key.toLowerCase().includes('temp')) {
                if (found === undefined) {
                    if (config.isMultiSection === false) {
                        if (config.inputs.filter((x: string) => x.toLowerCase().includes(key.toLowerCase())).length > 0) {
                            columns.push({
                                key: key,
                                name: key.charAt(0).toUpperCase() + key.slice(1),
                                minWidth: 100,
                                isResizable: true,
                                fieldName: key,
                                maxWidth: 200,
                                onRender: (item?: any, index?: number, column?: IColumn) => {
                                    let output: string = '';

                                    if (column!.key.toLowerCase().includes('flowrate')) {
                                        output = item![column!.fieldName!] ? `${item![column!.fieldName!]} ${preferredFlowrateUnits}` : '';
                                    }
                                    if (column!.key.toLowerCase().includes('pressure')) {
                                        output = item![column!.fieldName!] ? `${item![column!.fieldName!]} ${preferredPressureUnits}` : '';
                                    }
                                    if (column!.key.toLowerCase().includes('temp')) {
                                        output = item![column!.fieldName!] ? `${item![column!.fieldName!]} ${preferredTemperatureUnits}` : '';
                                    }
                                    return (
                                        <TooltipHost content={output} >
                                            <p style={{ color: column!.key.toLowerCase().includes('hot') ? theme.palette.redDark : theme.palette.themePrimary }}>
                                                {output}
                                            </p>
                                        </TooltipHost>
                                    );
                                }
                            })
                        }
                    }
                    else {
                        columns.push({
                            key: key,
                            name: key,
                            minWidth: 100,
                            maxWidth: 200,
                            isResizable: true,
                            fieldName: key,
                            onRender: (item?: any, index?: number, column?: IColumn) => {
                                let output: string = '';

                                if (column!.key.toLowerCase().includes('flowrate')) {
                                    output = `${item![column!.fieldName!]} ${preferredFlowrateUnits}`;
                                }
                                if (column!.key.toLowerCase().includes('pressure')) {
                                    output = `${item![column!.fieldName!]} ${preferredPressureUnits}`;
                                }
                                if (column!.key.toLowerCase().includes('temp')) {
                                    output = `${item![column!.fieldName!]} ${preferredTemperatureUnits}`;
                                }
                                return (
                                    <TooltipHost content={output} >
                                        <p style={{ color: column!.key.toLowerCase().includes('hot') ? theme.palette.redDark : theme.palette.themePrimary }}>
                                            {output}
                                        </p>
                                    </TooltipHost>
                                );
                            }
                        })
                    }
                }
            }
        });

        columns = columns.filter(c => c.key !== 'dataSampleStatus' && c.key !== 'sectionId');

        setDataColumns(columns);

        let dataSampleGroupedData: any[] = output;

        const totalRows: number = data.totalRows;
        const offset: number = data.offset;

        if (config.isMultiSection === false) {
            setCurrentData(dataSampleGroupedData);
        } else {
            setCurrentData(dataSampleGroupedData);
        }

        setPageCount(totalRows / pageSize);
        setIsLoading(false);
    }

    useEffect(() => {
        if (dataColumns === undefined) {
            //setColumns();
        }

        getDesignSessionData();
    }, [])

    const isMediaProperty = (column: string): boolean => {
        return mediaProperties.some(property => property.toLowerCase() === column.toLowerCase());
      };

    const updateSessionData = (sessionData: IDataSample[], columns: any[]): IDataSample[] => {
        return sessionData.map((data:any) => {
          let updatedData  = { ...data };
          columns.forEach((column: any) => {
            if (isMediaProperty(column.key) && (updatedData[column.key] ===0 || updatedData[column.key] === null || updatedData[column.key] === undefined || updatedData[column.key] === '')) {
              updatedData[column.key] = "0";
            }
          });
          return updatedData;
        });
      };

    function renderSource(item?: IDataSample, index?: number, column?: IColumn) {
        if (column?.fieldName === 'comment') {
            if (item?.modelerComment !== '' && item?.modelerComment !== undefined) {
                return <TooltipHost content={item.modelerComment}>
                    <IconButton style={{ color: theme.palette.themeDark }} iconProps={{ iconName: "Info" }} />
                </TooltipHost>
            }
        }
        else {
            if (item?.dataSampleStatus === Constants.DATASAMPLESTATUS_Done) {
                return <Badge title='Done'
                    style={{ cursor: 'pointer', background: theme.palette.green, color: theme.palette.white, width: 70, fontSize: 14, height: 20 }}>Done</Badge>
            }

            if (item?.dataSampleStatus === Constants.DATASAMPLESTATUS_NeedsUpdate) {
                return <Badge title='Update'
                    style={{ cursor: 'pointer', background: theme.palette.yellow, color: theme.palette.white, width: 70, fontSize: 14, height: 20 }}>Update</Badge>
            }

            return <Badge title='Todo'
                style={{ cursor: 'pointer', background: theme.palette.neutralLight, color: theme.palette.themePrimary, width: 70, fontSize: 14, height: 20 }}>To do</Badge>
        }
    }

    const gridStyles: Partial<IDetailsListStyles> = {
        root: {
            overflowX: 'scroll',
            borderRadius: 5,
            marginTop: 20,
            width: '100%',
            boxShadow: theme.effects.elevation4
        }
    }

    const handleDataFilter = async (query: string) => {
        if (unitInputConfiguration !== undefined) {
            const keyName = getPageKeyName(unitInputConfiguration, Constants.ModelDataSampleListPage);
            setLocalStorageItem(keyName, 0);
        }
        setCurrentFilter(query);
        await getDesignSessionData(query);
    }


    return (
        <Stack style={{ padding: 20 }}>
            <Stack horizontal horizontalAlign='center'>
                <Stack.Item align='start'>
                    <DefaultButton iconProps={{ iconName: 'back' }} onClick={() => navigate('/modeling/dashboard')}>Back</DefaultButton>
                    {
                        currentFilter !== '' &&

                        <Badge appearance='filled' color='warning' style={{ padding: 5, margin: '5px 10px' }}>
                            <Icon iconName='Warning' style={{ marginRight: 5 }} />
                            <span>Filters are active.</span>
                        </Badge>

                    }
                </Stack.Item>

                <Stack.Item align='center' grow>
                    <h1 style={{ textAlign: 'center' }}>{state.alfaLavalPosition}</h1>
                    <Label style={{ textAlign: 'center', marginTop: 10, padding: 0 }}>{state.productLine}</Label>
                </Stack.Item>

                <Stack.Item align='end'>
                    <PrimaryButton iconProps={{ iconName: 'Filter' }} disabled={isLoading} onClick={() => setShowFilters(!showFilters)}>{showFilters ? 'Hide filters' : 'Show filters'}</PrimaryButton>
                </Stack.Item>
            </Stack>
            {
                isLoading === true ?
                    <Spinner className='loading-spinner' size={SpinnerSize.large} label={'Loading...'} />
                    :
                    <>

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {
                                <div style={{
                                    marginTop: 10,
                                    animation: showFilters ? MotionAnimations.fadeIn : MotionAnimations.fadeOut,
                                    animationIterationCount: 1
                                }} hidden={showFilters ? false : true}>
                                    {unitInputConfiguration && <Filter unitInputConfiguration={unitInputConfiguration} handleFilter={(query) => handleDataFilter(query)} />}
                                </div>
                            }
                        </div>

                        <>
                            {
                                configurations && configurations.length > 0 && configurations[0].isMultiSection === true ?
                                    <>
                                        <p style={{ marginTop: 5 }}>{currentData.length} samples</p>
                                        <Stack styles={generalStackStyles(theme)} style={{ marginTop: 20 }}>
                                            <DetailsList
                                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                                items={updateSessionData(currentData,dataColumns!)}
                                                columns={dataColumns}
                                                checkboxVisibility={CheckboxVisibility.hidden}
                                                onActiveItemChanged={(item) => {
                                                    navigate(`/modeling/${params.samplingId}/datasamples/${item.id}/review`, { state: { unitConfiguration: unitInputConfiguration, dataSample: item, multiSectionConfiguration: configurations } })
                                                }}
                                            />
                                        </Stack>
                                    </>
                                    :
                                    currentData.length > 0 && dataColumns &&
                                    <Stack styles={generalStackStyles(theme)} style={{ marginTop: 20 }}>
                                        <DetailsList
                                            layoutMode={DetailsListLayoutMode.fixedColumns}
                                            items={updateSessionData(currentData,dataColumns!)}
                                            columns={dataColumns}
                                            checkboxVisibility={CheckboxVisibility.hidden}
                                            onActiveItemChanged={(item) => {
                                                navigate(`/modeling/${params.samplingId}/datasamples/${item.id}/review`, { state: { unitConfiguration: unitInputConfiguration, dataSample: item, multiSectionConfiguration: configurations } })
                                            }}
                                        />
                                        <Stack>
                                            <Stack.Item align='center'>
                                                <div className='pagination-container'>
                                                    <ReactPaginate
                                                        breakLabel='...'
                                                        nextLabel='>'
                                                        onPageChange={handlePageChange}
                                                        pageCount={pageCount}
                                                        previousLabel='<'
                                                        forcePage={currentPage as number}
                                                    />
                                                </div>
                                            </Stack.Item>
                                        </Stack>
                                    </Stack>
                            }
                            {
                                currentData.length === 0 &&
                                <Stack horizontal horizontalAlign='center' style={{ marginTop: 20 }} styles={generalStackStyles(theme)}>
                                    <Stack.Item>
                                        <Label>No data samples found.</Label>
                                    </Stack.Item>
                                </Stack>
                            }
                        </>
                    </>
            }
        </Stack >
    )
}