import { Icon, IconButton, Label, Stack, TooltipHost, useTheme } from "@fluentui/react"
import { BlindTestStatus, CountSessions, GroupedSessions, IBlindTest } from "../../../Models/IBlindTest"
import { IUnitConfiguration } from "../../../Models/IUnitConfiguration"
import { hoverableStackStyles } from "../../../Models/StackStyling";
import { getFormattedDate } from "../../../Utils/UnitsHelper";
import { Badge } from "@fluentui/react-components";
import { IMsalContext, useMsal } from "@azure/msal-react";
import { useEffect, useMemo, useState } from "react";
import { ApiService } from "../../../Services/ApiService";
import { useNavigate } from "react-router";
import { IBlindTestState } from "../../../Models/IBlindTestState"

export const BlindTestList: React.FC<{ blindTests: IBlindTest[], unitConfiguration: IUnitConfiguration }> = (props) => {
    let navigate = useNavigate();

    let theme = useTheme();

    const ctx: IMsalContext = useMsal();
    const apiService = useMemo(() => new ApiService(ctx), [ctx]);

    const [blindTests, setBlindTests] = useState<IBlindTest[]>();
    const [countGroupedSession, setCountGroupedSession] = useState<CountSessions[]>();

    useEffect(() => {
        setBlindTests(props.blindTests);
        let countSessions = new Array<CountSessions>();

        for (let i = 0; i < props.blindTests.length; i++) {
            const unassignedSessions = props.blindTests[i].positionDesignSessions.filter(session => session.source === 'Unassigned');

            const finishedSessions = props.blindTests[i].positionDesignSessions.filter(session => session.source != 'Unassigned');

            const unAssignedGroupedSessions: GroupedSessions = unassignedSessions.reduce((acc: GroupedSessions, session) => {
                const { sectionId } = session;
                acc[sectionId!] = (acc[sectionId!] || 0) + 1;
                return acc;
            }, {});
            const successGroupedSessions: GroupedSessions = finishedSessions.reduce((acc: GroupedSessions, session) => {
                const { sectionId } = session;
                acc[sectionId!] = (acc[sectionId!] || 0) + 1;
                return acc;
            }, {});

            const unAssignedgroupedSessionsLength = Object.keys(unAssignedGroupedSessions).length
            const finishedGroupedSessionsLength = Object.keys(successGroupedSessions).length
            countSessions.push({ ToDo: unAssignedgroupedSessionsLength, Done: finishedGroupedSessionsLength, BlindTestDataSetId: props.blindTests[i].blindTestSetId });
        }
        setCountGroupedSession(countSessions);

    }, [props.blindTests]);

    const publishBlindtest = async (blindTest: IBlindTest) => {
        blindTest.status = BlindTestStatus.Published;
        const response = await apiService.postAsync(blindTest, `blindtests/publish`);
        if (!response.ok) {
            throw new Error('Could not mark blind test for SME');
        }
        window.location.reload();
    }

    const deleteBlindTest = async (blindTest: IBlindTest) => {
        const response = await apiService.delete(`blindtests/${blindTest.id}`);
        if (!response.ok) {
            throw new Error('Could not delete blind test');
        }

        window.location.reload();
    }
    function goToDetails(blindTest: IBlindTest) {
        let stateParam: IBlindTestState = { blindTest: blindTest, unitConfiguration: props.unitConfiguration }
        navigate("/blindtests/overview", { state: stateParam });
    }
    return (
        <>
            {
                blindTests && blindTests.length > 0 &&
                <Stack>
                    <Label style={{ marginLeft: 20 }}>Blind tests ({blindTests?.length})</Label>
                    {
                        blindTests?.map((blindTest, index) => {
                            return (
                                <Stack horizontal style={{ padding: 10 }} key={`blindtest-${index}`} grow>
                                    <Stack horizontal verticalAlign="center">
                                        <TooltipHost content='Release to SME'>
                                            <Stack.Item>
                                                <IconButton disabled={blindTest.status === BlindTestStatus.Published} iconProps={{ iconName: 'Rocket' }} alt='Release to SME' onClick={() => publishBlindtest(blindTest)} />
                                            </Stack.Item>
                                        </TooltipHost>
                                        <TooltipHost content={blindTest.status === BlindTestStatus.New ? 'Delete' : 'Published blind tests cannot be deleted.'}>
                                            <Stack.Item>
                                                <IconButton disabled={blindTest.status === BlindTestStatus.Published} style={{ color: theme.palette.red, marginLeft: 5 }} iconProps={{ iconName: 'Delete' }} onClick={() => blindTest.status === BlindTestStatus.New ? deleteBlindTest(blindTest) : ''} />
                                            </Stack.Item>
                                        </TooltipHost>
                                    </Stack>
                                    <Stack.Item align='center' grow onClick={() => { goToDetails(blindTest); }}>
                                        {
                                            blindTest.status === BlindTestStatus.Published &&
                                            <Badge appearance='tint' style={{ marginLeft: 20, marginTop: 5, position: 'absolute' }} icon={<Icon iconName='lock' />}>
                                                Published - 🚀
                                            </Badge>
                                        }
                                        <Stack horizontal styles={hoverableStackStyles(theme)} grow onClick={() => console.log(blindTest)}>
                                            <Stack.Item grow>
                                                <Label>Blindtest id</Label>
                                                <p style={{ cursor: 'pointer' }}>{blindTest.blindTestSetId}</p>
                                            </Stack.Item>
                                            <Stack.Item grow>
                                                <Label>Uploaded by</Label>
                                                <p>{blindTest.user}</p>
                                            </Stack.Item>
                                            <Stack.Item grow>
                                                <Label>Created</Label>
                                                <p>{getFormattedDate(blindTest.createdDate.toString())}</p>
                                            </Stack.Item>
                                            <Stack.Item grow>
                                                <Label>Modified</Label>
                                                <p>{blindTest.modified.toString() !== '0001-01-01T00:00:00' ? getFormattedDate(blindTest.modified.toString()) : 'Never'}</p>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Label>Status</Label>
                                                <Badge>{countGroupedSession == undefined ? 0 : countGroupedSession.filter(x => x.BlindTestDataSetId === blindTest.blindTestSetId)[0]!.ToDo} To do</Badge>
                                                <Badge color='success' style={{ marginLeft: 5 }} >{countGroupedSession == undefined ? 0 : countGroupedSession.filter(x => x.BlindTestDataSetId === blindTest.blindTestSetId)[0]!.Done} Done</Badge>
                                            </Stack.Item>
                                        </Stack>
                                    </Stack.Item>
                                </Stack>
                            )
                        })
                    }
                </Stack>
            }

        </>
    )
}