import { IMsalContext, useMsal } from "@azure/msal-react";
import { DefaultButton, IDropdownOption, Label, PrimaryButton, Spinner, SpinnerSize, Stack, Text, TextField, useTheme } from "@fluentui/react"
import { Badge } from "@fluentui/react-components";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Constants, PropertyConstants } from "../../../../Models/Constants";
import { IDesignSession } from "../../../../Models/IDesignSession"
import { IMultiSectionUnitConfiguration, IUnitConfiguration } from "../../../../Models/IUnitConfiguration";
import { generalStackStyles } from "../../../../Models/StackStyling";
import { ApiService } from "../../../../Services/ApiService";
import { deCapitalizeFirstLetter } from "../../../../Services/Global";
import { ConditionalTextFieldInputs } from "../../../Atom/ConditionalTextFields/ConditionalTextFieldInputs";
import { ConditionalTextFieldOutputs } from "../../../Atom/ConditionalTextFields/ConditionalTextFieldOutputs";
import { PheTypePlatePackInputForm } from "./PheTypePlatePackInputForm";
import { IPlatePackConfiguration } from "../../../../Models/IPlatePackConfiguration";
import { SMEDesignGuidelines } from "../../../Atom/SMEDesignGuidelines/SMEDesignGuidelines";
import { checkPlatePackMultiSectionValidity } from "../../../../Utils/PlatePackConfgiurationHelper";
import { MultiSectionFlowchart } from "../../../Atom/MultiSectionVisualization/MultiSectionFlowchart";

export const DesignSessionReviewForm: React.FC<{ showConditionalFields: boolean }> = (props) => {
    let theme = useTheme();
    let navigate = useNavigate();
    const location = useLocation();
    let state = location.state as any;
    const ctx: IMsalContext = useMsal();
    const apiService = useMemo(() => new ApiService(ctx), [ctx]);
    const [activeIndex, setActiveIndex] = useState(1);

    const [multiSectionConfig, setMultiSectionConfig] = useState<IMultiSectionUnitConfiguration[]>([]);
    const [flowChartSelectableProperties, setFlowChratSelectableProperties] = useState<string[]>();
    const activeSection = multiSectionConfig.find(x => x.sectionNumber === activeIndex) ?? {} as IMultiSectionUnitConfiguration;
    const [showEmelieSection, setShowEmelieSections] = useState<boolean[]>([]);
    const [unitConfiguration] = useState<IUnitConfiguration>(state.unitConfiguration as IUnitConfiguration);
    const [SMEDesignGuidelineId, setSMEDesignGuidelineId] = useState<bigint>();
    const [editMode, setEditMode] = useState<boolean>(false);
    const [designSessions, setDesignSessions] = useState<IDesignSession[]>([]);
    const [designSession] = useState<IDesignSession>(state.designSession as IDesignSession);
    const [formIsValid, setFormIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [pheTypesList, setPHETypesList] = useState<IDropdownOption[]>();
    const [platePackConfigs, setPlatePackConfigs] = useState<IPlatePackConfiguration[]>([]);
    const currentSectionNumber = activeIndex - 1; //(multiSectionConfig.length - activeIndex) - 1;


    useEffect(() => {

        if (unitConfiguration === undefined || designSession == undefined) {
            console.log(designSession)
            return;
        };

        if (designSession.source !== null && designSession.source !== undefined && designSession.source !== '' && designSession.source !== Constants.SOURCE_UNASSIGNED) {
            setEditMode(true);
        }
        // get the unit's user inputs to filter them out of the form for SME review
        async function getUnitConfiguration() {

            const response = await apiService.getAsync(`settings/unitconfigurations/sections`, unitConfiguration);
            if (!response.ok) {
                throw new Error(`Could not get unit configuration. ${response.status} ${response.statusText}`);
            }

            const sections: IMultiSectionUnitConfiguration[] = await response.json();
            console.log(sections);
            const orderedSections = sections.sort((a, b) => { return b.sectionNumber! - a.sectionNumber! });
            setMultiSectionConfig(orderedSections);
            let selectableProps = orderedSections[0].inputs?.concat(orderedSections[0].outputs ?? []);
            console.log(selectableProps);
            setFlowChratSelectableProperties(selectableProps);

            setPlatePackConfigs(new Array(sections.length).fill({} as IPlatePackConfiguration));
            await getPHETypes(unitConfiguration);
            await getPositionDesignSessions(sections);
        }

        getUnitConfiguration();
    }, [state]);

    useEffect(() => {
        getPheTypeFromDictionary(designSession);
    }, [designSessions, pheTypesList])
  
    async function getPositionDesignSessions(sections: IMultiSectionUnitConfiguration[]) {
        let currentDesignSession: IDesignSession[] = []

        if (sections[0].isMultiSection === true) {
            const positionResponse = await apiService.getAsync(`designsessions/positions/sections/${state.designSession.sectionId}`);
            if (!positionResponse.ok) {
                throw new Error(`Could not get positoins. ${positionResponse.status} ${positionResponse.statusText}`);
            }

            currentDesignSession = await positionResponse.json();
        }
        else {
            const positionResponse = await apiService.getAsync(`designsessions/${designSession.id}`);

            if (!positionResponse.ok) {
                throw new Error(`Could not get design sessions. ${positionResponse.status} ${positionResponse.statusText}`);
            }

            const designSessionsResponse: IDesignSession = await positionResponse.json();
            currentDesignSession.push(designSessionsResponse);
        }

        let currentConfigs = platePackConfigs;
        for (let i = 0; i < currentDesignSession.length; i++) {
            currentConfigs[i] = {
                cold_1_channels_per_pass_type_1: currentDesignSession[i].cold_1_channels_per_pass_type_1,
                cold_1_channels_per_pass_type_2: currentDesignSession[i].cold_1_channels_per_pass_type_2,
                cold_1_passes: currentDesignSession[i].cold_1_passes,
                cold_2_channels_per_pass: currentDesignSession[i].cold_2_channels_per_pass,
                cold_2_passes: currentDesignSession[i].cold_2_passes,
                cold_plate_pattern_type_1: currentDesignSession[i].cold_plate_pattern_type_1,
                cold_plate_pattern_type_2: currentDesignSession[i].cold_plate_pattern_type_2,
                hot_1_channels_per_pass_type_1: currentDesignSession[i].hot_1_channels_per_pass_type_1,
                hot_1_channels_per_pass_type_2: currentDesignSession[i].hot_1_channels_per_pass_type_2,
                hot_1_passes: currentDesignSession[i].hot_1_passes,
                hot_2_channels_per_pass: currentDesignSession[i].hot_2_channels_per_pass,
                hot_2_passes: currentDesignSession[i].hot_2_passes,
                hot_plate_pattern_type_1: currentDesignSession[i].hot_plate_pattern_type_1,
                hot_plate_pattern_type_2: currentDesignSession[i].hot_plate_pattern_type_2,
                type: currentDesignSession[i].hot_1_channels_per_pass_type_2 !== undefined && currentDesignSession[i].hot_1_channels_per_pass_type_2 !== null ?
                    Constants.VaryingTheta :
                    currentDesignSession[i].hot_2_channels_per_pass !== undefined && currentDesignSession[i].hot_2_channels_per_pass !== null ?
                        Constants.VaryingChannelsPasses : Constants.BasicPass
            }
        }

        setDesignSessions(currentDesignSession);
        setPlatePackConfigs(currentConfigs);
        setIsLoading(false);
    }

    async function setPhePlateTypeSME(source: IDropdownOption[]) {
        if (designSessions === undefined) {
            return;
        }
        else {

            let targetSME = source.find(c => c.key === designSession.phePlateTypeSME);
            // If target phe type cannot be found, it might have been deleted from Admin pages.
            if (targetSME !== undefined) {
                designSession.phePlateTypeSME = source.find(c => c.key === designSession.phePlateTypeSME)?.text;
            }
        }
    }

    async function getPHETypes(unitInput: IUnitConfiguration) {
        let pheTypes: IDropdownOption[] = [];
        const response = await apiService.getAsync(`settings/phetypes/available`, unitInput);

        if (!response.ok) {
            throw new Error(`Could not get PHE types. ${response.status} ${response.statusText}`);
        }
        const types: any[] = await response.json();
        types.sort((a, b) => a.text.localeCompare(b.text));
        types.forEach((phe: any) => {
            pheTypes.push({
                key: phe.key,
                text: phe.text
            })
        });
        setPHETypesList(pheTypes);
        setPhePlateTypeSME(pheTypes)
    }

    const getPheTypeFromDictionary = (designSession: IDesignSession) => {

        let target = pheTypesList?.find(c => c.key === designSession.phePlateType);
        // If target phe type cannot be found, it might have been deleted from Admin pages.
        if (target !== undefined) {
            designSession.phePlateType = pheTypesList?.find(c => c.key === designSession.phePlateType)?.text;
        }

        return designSession.phePlateType;
    }

    const handleChange = (property: string, value: any, isSME?: boolean) => {
        let current = designSessions[currentSectionNumber];
        if (isSME) {
            property = property + "SME";
        }
        if (value.currentTarget !== undefined) {
            value = value.currentTarget.value;
        }

        //assign same property to all datasamples if it is phe plate type 
        let currentSessions = designSessions;
        if (property === PropertyConstants.PHEPlateTypeSME) {
            currentSessions.forEach((session: any, index) => {
                session[`${deCapitalizeFirstLetter(property)}`] = value;
            });
        }
        else {
            current = { ...current, [`${deCapitalizeFirstLetter(property)}`]: value };
        }

        currentSessions[currentSectionNumber] = current;
        setDesignSessions([...currentSessions]);
        validateForm();
    }

    async function submitForm() {

        setIsLoading(true);

        for (let i = 0; i < multiSectionConfig.length; i++) {
            let body: any = { ...designSessions[i], ...platePackConfigs[i] }
            const response = await apiService.putAsync(body, `designsessions/positions/${designSessions[i].id}`);
            if (!response.ok) {
                throw Error(`Could not send response. ${response.status} ${response.statusText}`);
            }
        }

        setIsLoading(false);
        navigate(-1);
    }

    const getSMEGiudelineId = (id?: bigint) => {
        setSMEDesignGuidelineId(id);
        //set sme design guideline same for all the sections
        let currentSessions = designSessions;
        currentSessions.forEach((session: any, index) => {
            session['smeDesignGuideLineId'] = id;
        });
    }

    const validateForm = (input?: boolean) => {
        if ((input === true || input === undefined) &&
            checkMultiSectionValidity() === true &&
            (document.forms[0] === undefined || (document.forms[0] !== undefined && document.forms[0].checkValidity() === true))) {
            setFormIsValid(true);
        }
        else {
            setFormIsValid(false);
        }
    }

    function checkMultiSectionValidity() {
        let isValid = true;

        dataSampleLoop: for (let designSession of designSessions) {
            if (designSession.phePlateTypeSME == null || designSession.phePlateTypeSME == undefined || designSession.phePlateTypeSME === '') {
                isValid = false;
                break dataSampleLoop;
            }
        }

        sourceLoop: for (let designSession of designSessions) {
            if (designSession.source == null || designSession.source == undefined || designSession.source === '' || designSession.source === Constants.SOURCE_UNASSIGNED) {
                isValid = false;
                break sourceLoop;
            }
        }

        if (isValid === true) {
            isValid = checkPlatePackMultiSectionValidity(platePackConfigs);
        }
        return isValid;
    }

    const stackStyles = generalStackStyles(theme);

    const resultsStyling = {
        minWidth: 300,
        maxWidth: 400,
        backgroundColor: theme.palette.neutralLighterAlt,
        boxShadow: "none !important",
        padding: 20
    }
    const smeStyling = {
        border: "2px solid " + theme.palette.yellow,
        minWidth: 300,
        maxWidth: 500
    }

    const emelieStyling = {
        border: "2px solid " + theme.palette.themePrimary,
        minWidth: 300,
        maxWidth: 400
    }

    const handlePlatePackConfigurationChange = (config: IPlatePackConfiguration) => {
        let currentConfigs = platePackConfigs;
        currentConfigs[currentSectionNumber] = config;
        setPlatePackConfigs([...currentConfigs]);
    }

    function setSelectionForSession(source: string, sectionNumber: number): void {
        let currentSessions = designSessions;
        currentSessions[sectionNumber].source = source;
        setDesignSessions([...currentSessions]);
        validateForm();
    }

    function currentSectionValid(currentSection: number): boolean | undefined {
        let isValid = true;
        const designSession = designSessions[currentSection]
        if (designSession.phePlateTypeSME == null || designSession.phePlateTypeSME == undefined || designSession.phePlateTypeSME === '') {
            isValid = false;
        }

        if (isValid === true) {
            const plateConfigs: IPlatePackConfiguration[] = [];
            plateConfigs.push(platePackConfigs[currentSection]);
            isValid = checkPlatePackMultiSectionValidity(plateConfigs);
        }
        return isValid;
    }

    function setEmelieViewSection(currentSectionNumber: number): void {
        let currentSessions = showEmelieSection;
        const viewEmelie = currentSessions[currentSectionNumber];
        currentSessions[currentSectionNumber] = !viewEmelie;
        setShowEmelieSections([...currentSessions]);
    }

    return (

        <>
            <Stack horizontal>
                <Stack.Item style={{ margin: 15 }} grow>
                    <div>
                        <DefaultButton style={{ borderRadius: 5 }} iconProps={{ iconName: 'Back' }} text='Back' onClick={() => { navigate(-1) }} />
                        {
                            designSessions && designSessions.length > 0 &&
                            <Text style={{ padding: 5 }}>{`${designSessions[currentSectionNumber].industry} / ${designSessions[currentSectionNumber].subIndustry} / ${designSessions[currentSectionNumber].application} / ${designSessions[currentSectionNumber].subApplication} / `}<Text><strong>{designSessions[currentSectionNumber].alfaLavalPosition}</strong></Text></Text>
                        }
                    </div>
                </Stack.Item>
                <Stack.Item style={{ padding: 20, position: 'fixed', right: 20, top: 50 }}>
                    <PrimaryButton onClick={() => submitForm()} iconProps={{ iconName: 'CheckMark' }} type='submit' style={{ padding: 5 }} text={multiSectionConfig.length > 1 ? 'Submit all sections' : 'Submit'} disabled={isLoading || !formIsValid} />
                </Stack.Item>
            </Stack>
            {
                isLoading &&
                <Stack.Item style={{ margin: 15 }}>
                    <Spinner size={SpinnerSize.large} label='Loading design session...' />
                </Stack.Item>
            }
            {
                designSessions && designSessions.length > 0 &&
                <Stack horizontal>
                    <Stack grow style={{
                        minWidth: 400,
                        maxWidth: 500,
                        backgroundColor: theme.palette.neutralLighterAlt,
                        boxShadow: "none !important",
                        padding: 20
                    }}>
                        {
                            multiSectionConfig && multiSectionConfig.length > 1 &&
                            < Stack >
                                <Stack horizontal>
                                    <Stack.Item grow>
                                        <DefaultButton iconProps={{ iconName: "ChevronLeft" }} onClick={() =>{ console.log(designSessions.length+" "+ activeIndex); setActiveIndex(activeIndex === designSessions.length ? designSessions.length : activeIndex + 1)}} />
                                    </Stack.Item>
                                    <Stack.Item grow align='center'>
                                        <Label>Section {activeIndex}</Label>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <DefaultButton iconProps={{ iconName: "ChevronRight" }} onClick={() => setActiveIndex(activeIndex === 1 ? 1 : activeIndex - 1)} />
                                    </Stack.Item>
                                </Stack>
                                <Stack style={{ marginLeft: -25 }} horizontal horizontalAlign='center'>
                                    <MultiSectionFlowchart
                                        selectableProperties={flowChartSelectableProperties ?? []}
                                        sectionCount={multiSectionConfig.length}
                                        unitInputConfiguration={multiSectionConfig[0]}
                                        scaling={1 / multiSectionConfig.length * 1.8} />
                                    {/* {
                                            multiSectionConfig.length > 1 &&
                                            multiSectionConfig.sort((a, b) => { return b.sectionNumber! - a.sectionNumber! }).map((section) => {
                                                return <MultiSectionVisualization
                                                    sectionWidth={100}
                                                    onClick={() => { console.log('settingIndes to', section.sectionNumber); setActiveIndex(section.sectionNumber!) }}
                                                    isActive={section.sectionNumber === activeIndex}
                                                    label={`${section.sectionNumber}`}
                                                    leftEnding={section.sectionNumber === multiSectionConfig.length}
                                                    rightEnding={section.sectionNumber === 1} />
                                            })
                                        } */}
                                </Stack>
                            </Stack>
                        }
                        <Stack.Item style={{ padding: 5 }}>
                            <Stack styles={generalStackStyles(theme)} style={{ borderLeft: "5px solid #65AFAD" }}>
                                <Label>Product: <Badge appearance='outline' style={{ fontSize: 14, padding: 15, color: '#65AFAD' }}>{activeSection?.hotMediaType}</Badge></Label>
                                <Label>Process stage: {activeSection?.processStage}</Label>
                                {
                                        designSessions[currentSectionNumber] !== undefined && activeSection?.unitOperationTargetName !== undefined &&
                                        <Stack >
                                            <Stack.Item grow>
                                                <Label>Unit operation target: {activeSection.unitOperationTargetName}</Label>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Badge style={{ fontSize: 16, padding: 15, backgroundColor: '#65AFAD' }}>
                                                    {designSessions[currentSectionNumber].unitOperationTargetValue?.toString()} {activeSection.unitOperationTargetValueUnit}
                                                </Badge>
                                            </Stack.Item>
                                        </Stack>

                                    }
                            </Stack>
                        </Stack.Item>
                        {designSessions &&
                            <ConditionalTextFieldInputs
                                readOnly={false}
                                originPage={Constants.DesignSessionReviewForm}
                                propertySpecs={[]}
                                unitConfiguration={activeSection}
                                coldMediaFlowrate={designSessions[currentSectionNumber].coldMediaFlowrate?.toString()}
                                coldMediaTempIn={designSessions[currentSectionNumber].coldMediaTempIn?.toString()}
                                coldMediaTempOut={designSessions[currentSectionNumber].coldMediaTempOut?.toString()}
                                coldMediaPressureDrop={designSessions[currentSectionNumber].coldMediaPressureDrop?.toString()}
                                hotMediaFlowrate={designSessions[currentSectionNumber].hotMediaFlowrate?.toString()}
                                hotMediaTempIn={designSessions[currentSectionNumber].hotMediaTempIn?.toString()}
                                hotMediaTempOut={designSessions[currentSectionNumber].hotMediaTempOut?.toString()}
                                hotMediaPressureDrop={designSessions[currentSectionNumber].hotMediaPressureDrop?.toString()}
                                addStyling={true}
                                handleChange={undefined} />
                        }

                        <Stack.Item style={{ margin: 10 }}>
                            <PrimaryButton iconProps={{ iconName: showEmelieSection[currentSectionNumber] && currentSectionValid(currentSectionNumber) ? 'Hide3' : 'View' }}
                                text={showEmelieSection[currentSectionNumber] && currentSectionValid(currentSectionNumber) ? 'Hide Emelie result' : 'Show Emelie result'}
                                disabled={!currentSectionValid(currentSectionNumber) || editMode} onClick={() => setEmelieViewSection(currentSectionNumber)} />
                            {
                                !formIsValid &&
                                <p><sub><strong>Fill out the SME form to be able to show EMELIE result.</strong></sub></p>
                            }
                        </Stack.Item>
                    </Stack>
                    {
                        <>
                            <Stack grow styles={stackStyles} style={{
                                border: "2px solid " + theme.palette.yellow,
                                minWidth: 450,
                                maxWidth: 450
                            }}>
                                <form onSubmit={(e) => { e.preventDefault(); }}>
                                    <Stack horizontal>
                                        <Stack.Item grow>
                                            <h3>SME</h3>
                                        </Stack.Item>
                                        <Stack.Item align='stretch'>
                                            <DefaultButton onClick={() => setSelectionForSession(Constants.SOURCE_SME, currentSectionNumber)} iconProps={{ iconName: designSessions[currentSectionNumber].source === Constants.SOURCE_SME ? 'CheckMark' : '' }}
                                                style={{ padding: 5, color: theme.palette.white, background: theme.palette.yellow, border: theme.palette.yellow }} text='Keep SME' />
                                        </Stack.Item>
                                    </Stack>
                                    <Stack>
                                        <Stack.Item>
                                            {
                                                designSessions &&
                                                <PheTypePlatePackInputForm
                                                    isReadOnly={false}
                                                    existingConfig={platePackConfigs[currentSectionNumber]}
                                                    pheTypes={pheTypesList!}
                                                    setConfig={(c) => handlePlatePackConfigurationChange(c)}
                                                    setPheType={(phe) => { handleChange(PropertyConstants.PHEPlateTypeSME, phe) }}
                                                    existingPheType={designSessions[0].phePlateTypeSME ?? ''}
                                                    shouldValidateFormInputs={true}
                                                    validateForm={validateForm}
                                                    label={multiSectionConfig.length > 1 ? `Plate pack configuration section ${currentSectionNumber + 1}` : 'Plate pack configuration'}
                                                />
                                            }
                                        </Stack.Item>
                                    </Stack>

                                    {
                                        designSessions !== undefined && designSessions.length > 0 && designSessions[currentSectionNumber] &&
                                        <>
                                            <Label>Process outputs</Label>
                                            <ConditionalTextFieldOutputs
                                                readOnly={false}
                                                handleChange={(e: any) => handleChange(e.currentTarget.name, e.currentTarget.value, true)}
                                                unitConfiguration={activeSection!}
                                                hotMediaFlowrate={designSessions[currentSectionNumber].hotMediaFlowrateSME ?? ''}
                                                hotMediaTempIn={designSessions[currentSectionNumber].hotMediaTempInSME ?? ''}
                                                hotMediaTempOut={designSessions[currentSectionNumber].hotMediaTempOutSME ?? ''}
                                                hotMediaPressureDrop={designSessions[currentSectionNumber].hotMediaPressureDropSME ?? ''}
                                                coldMediaFlowrate={designSessions[currentSectionNumber].coldMediaFlowrateSME ?? ''}
                                                coldMediaTempIn={designSessions[currentSectionNumber].coldMediaTempInSME ?? ''}
                                                coldMediaTempOut={designSessions[currentSectionNumber].coldMediaTempOutSME ?? ''}
                                                coldMediaPressureDrop={designSessions[currentSectionNumber].coldMediaPressureDropSME ?? ''}
                                                coldMediaWallTemp={designSessions[currentSectionNumber].coldMediaWallTempSME ?? ''}
                                                hotMediaWallTemp={designSessions[currentSectionNumber].hotMediaWallTempSME ?? ''}
                                                addStyling={true}
                                            />
                                        </>

                                    }
                                    <Stack.Item>
                                        <Label>Comment</Label>
                                        <TextField multiline={true} style={{ height: 100 }} onChange={(e) => handleChange(PropertyConstants.Comment, e.currentTarget.value)} value={designSessions[currentSectionNumber].comment ?? ''} />
                                    </Stack.Item>
                                </form>
                            </Stack>
                            {
                                ((unitConfiguration !== undefined && !showEmelieSection[currentSectionNumber]) || editMode) &&
                                <SMEDesignGuidelines isReadOnly={false} setDesignGuidelineId={(e) => getSMEGiudelineId(e)} designSession={designSession} unitConfiguration={unitConfiguration} ></SMEDesignGuidelines>
                            }
                            {
                                (showEmelieSection[currentSectionNumber] || editMode) &&
                                <>
                                    <Stack grow styles={stackStyles} style={emelieStyling}>
                                        <Stack horizontal>
                                            <Stack.Item grow>
                                                <h3>EMELIE</h3>
                                            </Stack.Item>
                                        </Stack>
                                        <Stack horizontal style={{ paddingTop: 5 }}>
                                            <Stack.Item >
                                                <PrimaryButton style={{ padding: 5, marginRight: 2, background: theme.palette.themePrimary }}
                                                    text='Keep EMELIE - acceptable'
                                                    iconProps={{ iconName: designSessions[currentSectionNumber].source === Constants.SOURCE_EMELIE_ACCEPTABLE ? 'CheckMark' : '' }}
                                                    onClick={() => setSelectionForSession(Constants.SOURCE_EMELIE_ACCEPTABLE, currentSectionNumber)}
                                                    disabled={false} />
                                            </Stack.Item>
                                            <Stack.Item>
                                                <PrimaryButton style={{ padding: 5, background: theme.palette.green }}
                                                    text='Keep EMELIE - better than SME'
                                                    iconProps={{ iconName: designSessions[currentSectionNumber].source === Constants.SOURCE_EMELIE_BETTER_THAN_SME ? 'CheckMark' : '' }}
                                                    onClick={() => setSelectionForSession(Constants.SOURCE_EMELIE_BETTER_THAN_SME, currentSectionNumber)}
                                                    disabled={false} />
                                            </Stack.Item>
                                        </Stack>

                                        <Stack>
                                            <Stack.Item>
                                                <Label>PHE Type</Label>
                                                <TextField value={getPheTypeFromDictionary(designSessions[currentSectionNumber]!)} disabled />
                                            </Stack.Item>

                                            <Stack.Item>
                                                <Label>Plate Pack Configuration</Label>
                                                <TextField value={designSessions[currentSectionNumber]!.platePackConfiguration} disabled />
                                            </Stack.Item>
                                        </Stack>

                                        {
                                            unitConfiguration && props.showConditionalFields &&

                                            <ConditionalTextFieldOutputs
                                                readOnly={true}
                                                handleChange={handleChange}
                                                unitConfiguration={multiSectionConfig[currentSectionNumber]!}
                                                addStyling={false}
                                                hotMediaFlowrate={designSessions[currentSectionNumber].hotMediaFlowrate}
                                                hotMediaPressureDrop={designSessions[currentSectionNumber].hotMediaPressureDrop?.toString()}
                                                hotMediaTempIn={designSessions[currentSectionNumber].hotMediaTempIn}
                                                hotMediaTempOut={designSessions[currentSectionNumber].hotMediaTempOut}
                                                coldMediaFlowrate={designSessions[currentSectionNumber].coldMediaFlowrate}
                                                coldMediaPressureDrop={designSessions[currentSectionNumber].coldMediaPressureDrop?.toString()}
                                                coldMediaTempIn={designSessions[currentSectionNumber].coldMediaTempIn}
                                                coldMediaTempOut={designSessions[currentSectionNumber].coldMediaTempOut}
                                                hotMediaWallTemp={designSessions[currentSectionNumber].hotMediaWallTemp}
                                                coldMediaWallTemp={designSessions[currentSectionNumber].coldMediaWallTemp}
                                            />
                                        }
                                    </Stack>
                                </>

                            }
                        </>
                    }
                </Stack>
            }
        </>
    )
}