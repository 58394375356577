import { IMsalContext, useMsal } from '@azure/msal-react';
import { FontSizes, Icon, INavLink, INavLinkGroup, INavStyles, IStackItemStyles, IStackTokens, Label, Nav, Spinner, SpinnerSize, Stack, TooltipHost, useTheme } from '@fluentui/react';
import { Badge } from '@fluentui/react-components';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IPositionSummary } from '../../../../Models/IPositionSummary';
import { ApiService } from '../../../../Services/ApiService';

import milkCoolerImage from '../../../../Assets/PNG/milkCooler.png';
import steamHeaterImage from '../../../../Assets/PNG/steamheater.png';
import { getLocalStorageItem, setLocalStorageItem } from '../../../../Services/Global';
import { Constants } from '../../../../Models/Constants';
import saveAs from 'file-saver';

export const BlindTestDashboard: React.FC<{ isModeler: boolean, isSme: boolean }> = (props) => {
    let theme = useTheme();
    let navigate = useNavigate();
    const ctx: IMsalContext = useMsal();
    const apiService = useMemo(() => new ApiService(ctx), [ctx]);

    const [positionSummaries, setPositionSummaries] = useState<IPositionSummary[]>();
    const [navLinkGroups, setNavLinkGroup] = useState<INavLinkGroup[]>()
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedSubIndustry, setSelectedSubIndustry] = useState<string>();
    const [selectedSubApplication, setSelectedSubApplication] = useState<string>();
    const [selectedIndustry, setSelectedIndustry] = useState<string>();
    const [selectedApplication, setSelectedApplication] = useState<string>();
    const [selectedKey, setSelectedKey] = useState('');
    const [displaySummaries, setDisplaySummaries] = useState(false);
    const [downloadingPosition, setDownloadingPosition] = useState<string>('');
    const [downloadingProdLine, setDownloadingProdLine] = useState<string>('');

    const navStyles: Partial<INavStyles> = {
        root: {
            width: 280,
            boxSizing: 'border-box',
            overflowY: 'auto',
            background: `${theme.palette.white}`,
            boxShadow: theme.effects.elevation8,
            color: theme.palette.themePrimary,
            borderColor: theme.palette.green,
        },
    };

    async function getPositionDesignSessionSummary(industry: string, subindustry: string, application: string, subapplication: string) {
        setIsLoading(true);
        const response = await apiService.getAsync(`blindtests/designsessions/summary`, {
            industry: industry,
            subIndustry: subindustry,
            application: application,
            subApplication: subapplication,
            processStage: '',
            productLine: '',
            alfaLavalPosition: '',
            breakdownOfProcessStage: '',
            coldMediaType: '',
            hotMediaType: '',
            inputs: [],
            outputs: [],
            nonUsedProperties: [],
        });
        if (!response.ok) {
            throw new Error('Error getting position design session summaries');
        }

        const result: IPositionSummary[] = await response.json();
        console.log('result', result);
        setPositionSummaries(result);
        setIsLoading(false);
    }

    useEffect(() => {
        if (selectedIndustry && selectedSubIndustry && selectedApplication && selectedSubApplication) {
            getPositionDesignSessionSummary(selectedIndustry, selectedSubIndustry, selectedApplication, selectedSubApplication);
        }
    }, [selectedSubApplication])

    useEffect(() => {

        const storedIndustry = getLocalStorageItem(Constants.Industry);
        const storedsubIndustry = getLocalStorageItem(Constants.SubIndustry);
        const storedapplication = getLocalStorageItem(Constants.Application);
        const storedsubApplication = getLocalStorageItem(Constants.SubApplication);

        if (storedIndustry && storedsubIndustry && storedapplication && storedsubApplication) {
            setSelectedIndustry(storedIndustry);
            setSelectedSubIndustry(storedsubIndustry);
            setSelectedApplication(storedapplication);
            setSelectedSubApplication(storedsubApplication);
            setDisplaySummaries(true);
        }

        async function GetSMENavigationLinks() {
            try {
                const response = await apiService.getAsync(`settings/productnavigation`);

                if (!response.ok) {
                    throw new Error(`There was an issue getting SME navigation [${response.status}]: ${response.statusText}`);
                }

                const result = await response.json();
                const data: INavLink[] = result;

                let linkGroup: INavLinkGroup[] = [];

                data.forEach(industry => {
                    linkGroup.push({
                        name: industry.name,
                        links: industry.children.map((subIndustry: any) => {
                            return {
                                name: subIndustry.name,
                                isExpanded: selectedSubIndustry === subIndustry.name || storedsubIndustry === subIndustry.name,
                                links: subIndustry.children.map((application: any) => {
                                    return {
                                        name: application.name,
                                        isExpanded: selectedApplication === application.name || storedapplication === application.name,
                                        links: application.children.map((subApplication: any) => {
                                            return {
                                                name: subApplication.name,
                                                onClick: () => {
                                                    setSelectedIndustry(industry.name);
                                                    setSelectedKey(subApplication.name);
                                                    setLocalStorageItem(Constants.Industry, industry.name);
                                                    setSelectedSubIndustry(subIndustry.name);
                                                    setLocalStorageItem(Constants.SubIndustry, subIndustry.name);
                                                    setSelectedApplication(application.name);
                                                    setLocalStorageItem(Constants.Application, application.name);
                                                    setSelectedSubApplication(subApplication.name);
                                                    setLocalStorageItem(Constants.SubApplication, subApplication.name);
                                                    setDisplaySummaries(true);
                                                },
                                                isExpanded: selectedSubApplication === subApplication.name || storedsubApplication === subApplication.name,
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    })
                })

                setNavLinkGroup(linkGroup);
            }
            catch (exception: any) {
                throw new Error(`Could not contact the server. Error: ${exception}`);
            }
        }

        GetSMENavigationLinks();
    }, []);

    const divStyles: IStackItemStyles = {
        root: [
            {
                background: theme.palette.white,
                boxShadow: theme.effects.elevation4,
                padding: 15,
                margin: 15,
                borderRadius: 5,
                transition: '150ms',
                selectors: {
                    ':hover': {
                        boxShadow: theme.effects.elevation16,
                        transition: '50ms',
                        cursor: 'pointer'
                    }
                }
            }
        ]
    }

    const renderItems = (item: IPositionSummary[]) => {
        if (item.length === 0) {
            return (
                <Stack>
                    <Stack.Item>
                        <Label>No results to display under this process.</Label>
                    </Stack.Item>
                </Stack>
            )
        }

        return item.map((item: IPositionSummary) => {
            return (
                <Stack.Item className={item.summary['todo'] !== 0 ? 'todo-highlight' : ''} key={`${item.alfaLavalPosition}-${item.productLine}-${item.blindTestDataSetId}`} styles={divStyles} style={{ width: 350, maxHeight: 250, borderColor: theme.palette.themePrimary }}>
                    {/* 
                        If the user is a modeler, then the user shouldn't be able to go to the next step if there are items in todo state.
                        If the user is a modeler AND has the SME role, it should be allowed.
                    */}
                    <Stack.Item grow onClick={(props.isModeler && !props.isSme && item.summary['todo'] > 0) ? () => { } : () => { navigate(`/blindtests/${item.blindTestDataSetId}`, { state: { positionSummary: item! } }) }}>
                        {
                            item.featureAvailable === false ?
                                <Badge style={{ marginTop: '-26px', position: 'absolute', display: 'flex' }} appearance='tint' >Coming soon <span role='img' aria-label='time icon'>⏳</span></Badge>
                                :
                                <></>
                        }
                        <Stack>
                            <Stack horizontal>
                                <Stack.Item grow>
                                    <span style={{ fontSize: FontSizes.small }}><code>{item.blindTestDataSetId}</code></span>
                                    <Stack horizontal>
                                        <Stack.Item>
                                            <Label>{item.alfaLavalPosition}</Label>
                                            <Label style={{ fontSize: FontSizes.small, marginTop: -10 }}>{item.processStage}</Label>
                                            <p style={{ position: 'absolute', marginTop: -8, fontSize: 12 }}>{item.productLine}</p>
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                                <Stack.Item>
                                    <Stack>
                                        {
                                            !props.isModeler || item.summary['todo'] === 0 ?
                                                <>
                                                    <Stack.Item style={{ alignSelf: 'end', width: 100 }}>
                                                        <Badge title='EMELIE - better than SME' style={{ background: theme.palette.green, color: theme.palette.white, marginLeft: 5 }}>{item.summary['emelie - bts']} EMELIE - BTS</Badge>
                                                    </Stack.Item>
                                                    <Stack.Item style={{ alignSelf: 'end', marginTop: 2 }}>
                                                        <Badge title='EMELIE - acceptable' style={{ marginLeft: 5 }}>{item.summary['emelie - acceptable']} EMELIE - A</Badge>
                                                    </Stack.Item>
                                                    <Stack.Item style={{ alignSelf: 'end', marginTop: 2 }}>
                                                        <Badge title='SME' style={{ background: theme.palette.yellow, color: theme.palette.white, marginLeft: 5 }}>{item.summary['sme']} SME</Badge>
                                                    </Stack.Item>
                                                </>
                                                :
                                                <>
                                                    {
                                                        props.isModeler ? <>
                                                            <Stack.Item style={{ alignSelf: 'end' }}>
                                                                <Badge appearance='outline'>in progress</Badge>
                                                            </Stack.Item>
                                                        </>
                                                            :
                                                            <>
                                                                <Stack.Item style={{ width: 100 }}>
                                                                    <Badge title='EMELIE - better than SME' style={{ background: theme.palette.green, color: theme.palette.white, marginLeft: 5 }}>{item.summary['emelie - bts']} EMELIE - BTS</Badge>
                                                                </Stack.Item>
                                                                <Stack.Item>
                                                                    <Badge title='EMELIE - acceptable' style={{ marginLeft: 5 }}>{item.summary['emelie - acceptable']} EMELIE - A</Badge>
                                                                </Stack.Item>
                                                                <Stack.Item>
                                                                    <Badge title='SME' style={{ background: theme.palette.yellow, color: theme.palette.white, marginLeft: 5 }}>{item.summary['sme']} SME</Badge>
                                                                </Stack.Item>
                                                            </>
                                                    }
                                                </>
                                        }
                                    </Stack>
                                </Stack.Item>

                            </Stack>

                        </Stack>

                        <br />
                        <Stack horizontal horizontalAlign='stretch' style={{ flexDirection: 'column', marginTop: -50 }}>
                            <Stack.Item grow={0} align='center'>
                                <img src={item.alfaLavalPosition.includes('Milk') ? milkCoolerImage : steamHeaterImage} alt={item.alfaLavalPosition} style={{ display: 'block', maxWidth: 100, maxHeight: 100, textAlign: 'center' }} />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack horizontal style={{ marginTop: 5, display: 'flex', flexDirection: 'row' }} grow>
                        <Stack horizontal style={{ justifyContent: 'flex-start', width: '80%' }}>
                            <Badge appearance={item.summary['todo'] !== 0 ? 'filled' : 'tint'}>{item.summary['todo']} To do</Badge>
                            <Badge appearance={item.summary['todo'] === 0 ? 'filled' : 'tint'} style={{ marginLeft: 5 }} color='success'>{(item.summary['emelie - bts'] + item.summary['emelie - acceptable'] + item.summary['sme'])} Completed</Badge>
                        </Stack>
                        {
                            <Stack horizontal style={{ justifyContent: 'flex-end', width: '20%' }}>
                                <TooltipHost content={item.summary['todo'] === 0 ? 'Download design' : 'All tests must be completed by SME in order to download the sample.'}>
                                    <Badge onClick={() => item.summary['todo'] === 0 ? downloadCSVFile(item.blindTestDataSetId ?? '', item) : ''} color='important' appearance={item.summary['todo'] === 0 ? 'filled' : 'outline'}>
                                        <Icon iconName='download'></Icon>
                                        {
                                            downloadingProdLine === item.productLine && downloadingPosition === item.alfaLavalPosition &&
                                            <Spinner />
                                        }
                                        Download
                                    </Badge>
                                </TooltipHost>
                            </Stack>
                        }
                    </Stack>
                </Stack.Item>
            )
        })
    }

    const wrapStackTokens: IStackTokens = { childrenGap: 30 };

    const onRenderLink = (link: any): (JSX.Element | null) => {
        if (!link?.name) {
            return <></>;
        }
        var tokens = link.name.split('-');
        var heading = '';
        var subHeading = '';
        if (tokens.length > 0) {
            heading = tokens[0];
            if (tokens.length === 2) {
                subHeading = tokens[1];
            }
        }
        return (
            <div>
                <span key={1} style={{ display: 'block' }}>{heading} </span>
                {subHeading && <span style={{ display: 'block', 'textAlign': 'left', margin: '-25px 0 0 0', 'fontSize': 'smaller', 'fontWeight': '300' }} > ({subHeading})</span>}
            </div>
        );
    }

    async function downloadCSVFile(datasetId: string, item: IPositionSummary) {
        setDownloadingPosition(item.alfaLavalPosition);
        setDownloadingProdLine(item.productLine);
        const response = await apiService.getAsync(`designsessions/${datasetId}/download`);
        if (!response.ok) {
            setDownloadingPosition('');
            setDownloadingProdLine('');
            throw new Error('Could not download csv. ' + response.statusText);
        }
        setDownloadingPosition('');
        setDownloadingProdLine('');
        convertStreamToCsv(response.body!, item.alfaLavalPosition + "_" + item.productLine + ".csv");
    }

    async function convertStreamToCsv(stream: ReadableStream, filename: string) {
        const reader = stream.getReader();
        let csvData = '';
        while (true) {
            const result = await reader.read();
            if (result.done) {
                break;
            }
            csvData += new TextDecoder().decode(result.value);
        }
        const blob = new Blob([csvData], { type: 'text/csv' });
        saveAs(blob, filename);
    }

    return (
        <Stack horizontal style={{ padding: 20, }}>
            {
                !navLinkGroups &&
                <Spinner size={SpinnerSize.large} style={{ alignSelf: 'center' }} />
            }
            <Stack.Item style={{ float: 'left', padding: '10px', height: '300px' }}>
                <Nav
                    styles={navStyles}
                    selectedKey={selectedKey}
                    onLinkClick={(e, i) => { setSelectedKey(i!.key!); }}
                    groups={navLinkGroups ?? []}
                    onRenderLink={onRenderLink}
                />
            </Stack.Item>
            <Stack>
                <Stack.Item style={{ padding: 10 }}>
                    <h3>{displaySummaries && selectedSubApplication}</h3>
                </Stack.Item>

                <Stack.Item style={{ padding: '10px' }}>
                    {(positionSummaries && displaySummaries) &&
                        <Stack
                            horizontal
                            verticalFill
                            wrap
                            horizontalAlign='center'
                            verticalAlign='center'
                            tokens={wrapStackTokens}>
                            {
                                isLoading === true ?
                                    <Stack horizontal horizontalAlign='center'>
                                        <Stack.Item>
                                            <Spinner size={SpinnerSize.large} label="Loading..." />
                                        </Stack.Item>
                                    </Stack>
                                    :
                                    renderItems(positionSummaries)
                            }
                        </Stack>
                    }
                </Stack.Item>
            </Stack>
        </Stack>
    );
}
