import { FontIcon, getTheme } from '@fluentui/react'

export const EditIcon: React.FC<{}> = () => {

    let theme = getTheme();

    return (
        <FontIcon iconName='Edit' style={{ margin: 10, fontSize: 20, color: theme.palette.accent }} />
    )
}
