import React, { Component } from "react";
import BreadCrumb from "../../Atom/Breadcrumbs/Breadcrumb";
import Tooltip from "../../Atom/Tooltip/Tooltip";
import ChooseIndustry from "../../Molecules/ChooseIndustry/ChooseIndustry";
import "../../Atom/ButtonProps/ButtonProps";
import { useNavigate } from "react-router-dom";
export default function Design() {
  let navigate = useNavigate();
  return (
    <div className="emelie-height emelie-width">
      <BreadCrumb />
      <div className="emelie-flex emelie-flex-col emelie-justify-sb e-p-30">
        <div>
          <div className="emelie-f-32">Step.1</div>
          <div className="emelie-f-24--regular e-pt-10">
            Choose Industry <Tooltip name={"Click on the industry of your type"} />
          </div>
          <ChooseIndustry />
        </div>
        <div className="emelie-flex-center">
          <button className="emelieBtn emelie-btn--white" onClick={() => {
            navigate("/Dashboard");
          }}>{"Previous"}</button>
        </div>
      </div>
    </div>
  );
}
