export const Constants = {
    FoodDairyAndBeverages: 'Food Dairy and Beverages',
    Dairy: 'Dairy',
    MilkAndCreamProcessing: 'Milk and cream processing',
    PasteurizedMilkProcessing: 'Pasteurized milk processing',
    MilkReception: 'Milk reception',
    MilkCooler: 'Milk cooler',

    ColdMediaType: 'ColdMediaType',
    ColdMediaFlowrate: 'ColdMediaFlowrate',
    ColdMediaTempIn: 'ColdMediaTempIn',
    ColdMediaTempOut: 'ColdMediaTempOut',
    ColdMediaPressureDrop: 'ColdMediaPressureDrop',
    ColdMediaWallTemp: 'ColdMediaWallTemp',

    HotMediaWallTemp: 'HotMediaWallTemp',
    HotMediaType: 'HotMediaType',
    HotMediaFlowrate: 'HotMediaFlowrate',
    HotMediaTempIn: 'HotMediaTempIn',
    HotMediaTempOut: 'HotMediaTempOut',
    HotMediaPressureDrop: 'HotMediaPressureDrop',
    UnitOperationalTargetValue: 'UnitOperationalTargetValue',

    FlowType: 'FlowType',

    PHEType: 'PheType',
    PlatePackConfiguration: 'PlatePackConfiguration',

    Comment: 'Comment',

    CurrentFilter: 'CurrentFilter',

    Industry: 'Industry',
    SubIndustry: 'SubIndustry',
    Application: 'Application',
    SubApplication: 'SubApplication',
    ProcessStage: 'ProcessStage',
    AlfaLavalPosition: 'AlfaLavalPosition',

    SOURCE_SME: 'SME',
    SOURCE_EMELIE_ACCEPTABLE: 'EMELIE - acceptable',
    SOURCE_UNASSIGNED: 'Unassigned',
    SOURCE_EMELIE_BETTER_THAN_SME: "EMELIE - better than SME",

    COLDMEDIATYPES_CoolingWater: 'Cooling water',
    COLDMEDIATYPES_CIPWater: 'Milk',

    HOTMEDIATYPES_Milk: 'Milk',
    HOTMEDIATYPES_Steam: 'Steam',

    DATASAMPLESTATUS_None: 'None',
    DATASAMPLESTATUS_Done: 'Done',
    DATASAMPLESTATUS_NeedsUpdate: 'NeedsUpdate',

    SMEDesignguidelineNotFoundforGivenconfiguration: "SME design guideline not found for current configuration",
    ClickOnAddButtonToAddORAppendDesignGuidelines: "Click on 'Add' to Add/Append Designguidelines for current configuration",

    UnitResultListPage: 'UnitResultListPage',
    ModelDataSampleListPage: 'ModelDataSampleListPage',

    VaryingTheta: 'Varying theta',
    VaryingChannelsPasses: 'Varying channels in passes',
    BasicPass: 'Basic',

    SuccessMessageForXMLCopy: 'The result was copied to clipboard. Now, return to configurator and use the content using "Paste from CAS".',
    XMLCopyDefaultMessage: "{pheType} is not supported in ALICE. Copy the plate pack configuration and given temperatures and flow rates to use with {calculationMode} mode in the CAS tool to configure the heat exchanger.",

    ProductLine_Hygienic: 'Hygienic',
    ProductLine_Industrial: 'Industrial',

    GreaterThan: 'greaterThan',
    AllowDecimal: 'allowDecimal',
    LessThan: 'lessThan',
    ModelDataSampleForm: 'ModelDataSampleForm',
    ProductDesignForm: 'ProductDesignForm',
    DesignSessionReviewForm: 'DesignSessionReviewForm',
    UnitOperationTargetValue: 'UnitOperationTargetValue',
    VALUE_SHOULD_NOT_BE_DECIMAL: 'Value should not be a decimal number',
    WARNING_BEER_COOLING_HTML: `<p>Note: This model may occasionally produce incorrect designs. An update is scheduled for Q3 2024 to address this issue.</p>
                                <br><br>
                                <h3>How to Identify a Potentially Incorrect Design</h3>
                                 <br>
                                <p>After performing a performance calculation in the configurator, check the following values:</p>
                                <br>
                                <b><strong>Pressure drop :</strong></b>
                                <ul>
                                   <li>Beer side: Must not exceed 160 kPa</li>
                                    <li>Propylene glycol side: Must not exceed 200 kPa</li>
                                </ul>
                                <br>
                                <b><strong>Port velocity :</strong></b>
                                <ul>
                                    <li>Beer side: Must not exceed 5.3 m/s</li>
                                </ul>
                                <br>
                                <p>If any of these values are exceeded, the AI Design Wizard has likely provided an incorrect design. Do not proceed with this design solution.</p>
                                <br><br>
                                <h3>Need Help?</h3>
                                 <br>
                                <p>Please contact support for assistance with your design.</p>
                                <p>If the above values are within limits, the AI Design Wizard has provided a good design.</p>
                                `,
    WARNING_BEER_PASTEURIZER_HTML: `<p>The temperature programs for beer, heating water and glycol are already set according to industry standards.<br>You only need to specify desired beer flowrate and regenerative % here.<br> Please contact sales support if you have a design need that is not covered in the calculated solution.</p>`,
    BEER_COOLING: 'beer cooling',
    BEER_PASTEURIZER: 'beer pasteurizer 3 section',
    ADDITIONAL_STEPS_HTML: `<p>Additional steps after you have done "Paste from CAS": </p><br/>
                                <ol>
                                    <li>Manually set beer inlet temperature section 3 to match hot beer outlet temperature section 2</li>
                                    <li>Change calculation type to "performance"</li>
                                    <li>Delete the cold beer outlet temperature section 2 value</li>
                                    <li>Make sure the hot beer outlet temperature section 2 value was automatically deleted</li>
                                    <li>Click "calculate result list" to get the initial performance calculation, and make a note of the hot and cold beer outlet temperatures in section 2</li>
                                    <li>Manually update beer inlet temperature section 3 to match hot beer outlet temperature section 2 from step 5</li>
                                    <li>Manually update beer inlet temperature section 1 to match cold beer outlet temperature section 2 from step 5</li>
                                    <li>Click "calculate result list" again to get the final performance calculation</li>
                                </ol>
                                `,
}

export const PropertyConstants = {
    Industry: 'industry',
    SubIndustry: 'subIndustry',
    Application: 'application',
    SubApplication: 'subApplication',
    ProcessStage: 'processStage',
    ProductLine: 'productLine',
    AlfaLavalPosition: 'alfaLavalPosition',
    FlowType: 'flowType',
    CalculationType: 'calculationType',
    PHEPlateType: 'phePlateType',
    PHEType: 'pheType',
    HotMediaType: 'hotMediaType',
    ColdMediaType: 'coldMediaType',
    Inputs: 'inputs',
    Outputs: 'outputs',
    UnitOperationTargetName: 'unitOperationTargetName',
    UnitOperationTargetValueUnit: 'unitOperationTargetValueUnit',
    Comment: 'comment',
    UnitOperationTargetValue: 'unitOperationTargetValue',
    SMEComment: 'smeComment',
    PHEPlateTypeSME: 'phePlateTypeSME',
    ModelApiKeyName: 'modelApiKeyName',
    ModelInputs: 'modelInputs',
    ModelEndpointUrl: 'modelEndpointUrl',
    TempApproachHotInlet: 'TempApproachHotInlet',
    TempApproachHotOutlet: 'TempApproachHotOutlet',
    DeltaTemperatureHotSide: 'DeltaTemperatureHotSide',
    DeltaTemperatureColdSide: 'DeltaTemperatureColdSide',

}


export const PlatePackConfigurationConstants = {
    type: 'type',

    hot_1_passes: 'hot_1_passes',
    hot_1_channels_per_pass_type_1: 'hot_1_channels_per_pass_type_1',
    hot_1_channels_per_pass_type_2: 'hot_1_channels_per_pass_type_2',
    hot_2_passes: 'hot_2_passes',
    hot_2_channels_per_pass: 'hot_2_channels_per_pass',
    hot_plate_pattern_type_1: 'hot_plate_pattern_type_1',
    hot_plate_pattern_type_2: 'hot_plate_pattern_type_2',

    cold_1_passes: 'cold_1_passes',
    cold_1_channels_per_pass_type_1: 'cold_1_channels_per_pass_type_1',
    cold_1_channels_per_pass_type_2: 'cold_1_channels_per_pass_type_2',
    cold_2_passes: 'cold_2_passes',
    cold_2_channels_per_pass: 'cold_2_channels_per_pass',
    cold_plate_pattern_type_1: 'cold_plate_pattern_type_1',
    cold_plate_pattern_type_2: 'cold_plate_pattern_type_2'
}

export const UnitTargetValueNames: any = {
    'regenerative': "Heat recovery"
}

export const PHEType: any = {
    'tl10p': "BASE11",
    'front8': "HL8"
}