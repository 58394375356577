import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { IUserSettings } from "../Models/IUserSettings";
import { IUnitConfiguration } from "../Models/IUnitConfiguration";
import { Constants } from "../Models/Constants";

export function getPreferredUnits() {
    let settings: IUserSettings = JSON.parse(getLocalStorageItem('userSettings')!);
    if (settings === null) {
        return { preferredUnits: 'metric' };
    }

    return settings;
}

export function setTemporaryState(value: string, handle: any, timeout: number = 1500) {
    setTimeout(() => {
        setTimeout(() => {
            handle('')
        }, timeout)
        handle(value);
    }, 0);
}

export function getFlowTypeUnits(flowType: string) {
    if (getPreferredUnits().preferredUnits === 'us') {
        if (flowType === 'Volumetric' || flowType === 'volumetric')
            return 'GPM';
        return 'lb/h'
    }

    if (flowType === 'Volumetric' || flowType === 'volumetric')
        return 'm3/h';

    return 'kg/h';
}

export function setRoles(newRoles: string[]) {
    roles = newRoles;
}

export function getRoles() {
    return roles;
}

export function hasRole(...anyOfRoles: string[]): boolean {
    for (let i = 0; i < anyOfRoles.length; i++) {
        if (roles?.includes(anyOfRoles[i])) {
            return true;
        }
    }
    return false;
}

export function getSettingsWasSet() {
    return settingsWasSet;
}


export function setUserSettings(settings: IUserSettings) {
    userSettings = settings;
    settingsWasSet = true;
}

export function setFeatures(input: Record<string, boolean>) {
    features = input;
}

export function getFeatures() {
    return features;
}

export function setLocalStorageItem(name: string, value: any) {
    if (value !== 'null') {
        window.localStorage.setItem(`EMELIE_${name}`, value);
    }
}

export function getLocalStorageItem(name: string) {
    return window.localStorage.getItem(`EMELIE_${name}`);
}

export function setUserFirstname(name: string) {
    userFirstname = name;
}
export function getUserFirstname() {
    return userFirstname;
}

export function setUserLastname(name: string) {
    userLastname = name;
}
export function getUserLastname() {
    return userLastname;
}

export function deCapitalizeFirstLetter(input: string) {
    return input.charAt(0).toLowerCase() + input.slice(1);
}

export function getAppInsightsConnectionString() {
    return process.env.REACT_APP_APPINSIGHTS_CONNECTIONSTRING;
}

export function setAppInsights(appInsights: ApplicationInsights) {
    if (__APP_INSIGHTS__ === undefined) {
        __APP_INSIGHTS__ = appInsights;
    }
}

export function getAppInsights() {
    return __APP_INSIGHTS__;
}

export function setHomeLocation(location: string) {
    setLocalStorageItem('EMELIE_homeLocation', location);
}

export function getHomeLocation() {
    return getLocalStorageItem('EMELIE_homeLocation');
}


declare global {
    var __USER_SETTINGS__: IUserSettings;
    var __SETTINGS_WAS_SET__: boolean;
    var __ROLES__: string[];
    var __FEATURES__: Record<string, boolean>;
    var __USER_FIRSTNAME__: string;
    var __USER_LASTNAME__: string;
    var __APP_INSIGHTS__: ApplicationInsights;
}

let userSettings = window.__USER_SETTINGS__;
let roles = window.__ROLES__;
let settingsWasSet = window.__SETTINGS_WAS_SET__;
let features = window.__FEATURES__;
let userFirstname = window.__USER_FIRSTNAME__;
let userLastname = window.__USER_LASTNAME__;
let __APP_INSIGHTS__ = window.__APP_INSIGHTS__;