import { Stack, Label, TextField, useTheme } from "@fluentui/react"
import { Constants, PropertyConstants } from "../../../Models/Constants"
import { IMultiSectionUnitConfiguration, IUnitConfiguration } from "../../../Models/IUnitConfiguration";
import { getFlowTypeUnits, getPreferredUnits } from "../../../Services/Global";
import { isRelevantOutputField } from "../../../Utils/UnitConfigurationHelper";
import { Badge } from "@fluentui/react-components";
import TextFieldNoScroll from "../TextBox/TextFieldNoScroll";

export const ConditionalTextFieldOutputs: React.FC<{
    readOnly?: boolean,
    unitConfiguration: IUnitConfiguration | IMultiSectionUnitConfiguration,
    handleChange: any,
    hotMediaFlowrate?: string | number,
    hotMediaTempIn?: string | number,
    hotMediaTempOut?: string | number,
    hotMediaPressureDrop?: string | number,
    coldMediaFlowrate?: string | number,
    coldMediaTempIn?: string | number,
    coldMediaTempOut?: string | number,
    coldMediaPressureDrop?: string | number,
    hotMediaTempOutError?: string,
    hotMediaTempInError?: string,
    hotMediaFlowrateError?: string,
    coldMediaTempInError?: string,
    hotMediaWallTemp?: string | number,
    coldMediaWallTemp?: string | number,
    addStyling?: boolean
}> = ({
    readOnly = false,
    unitConfiguration,
    handleChange,
    hotMediaFlowrate,
    hotMediaTempIn,
    hotMediaTempOut,
    hotMediaPressureDrop,
    coldMediaFlowrate,
    coldMediaTempIn,
    coldMediaTempOut,
    coldMediaPressureDrop,
    hotMediaFlowrateError,
    hotMediaTempInError,
    hotMediaTempOutError,
    coldMediaTempInError,
    hotMediaWallTemp,
    coldMediaWallTemp,
    addStyling
}) => {
        let theme = useTheme();
        const warmStyling = {
            border: '2px solid ' + theme.palette.redDark,
            margin: 5,
            borderRadius: 5,
            padding: 5
        }

        const coldStyling = {
            border: '2px solid ' + theme.palette.themePrimary,
            margin: 5,
            borderRadius: 5,
            padding: 5
        }

        const hotInputs = unitConfiguration?.outputs?.filter(x => x === Constants.HotMediaFlowrate || x === Constants.HotMediaTempIn || x === Constants.HotMediaTempOut || x === Constants.HotMediaPressureDrop || x === Constants.HotMediaWallTemp);
        const shouldStyleHot = hotInputs && hotInputs.length > 0 ? true : false;
        const coldInputs = unitConfiguration?.outputs?.filter(x => x === Constants.ColdMediaFlowrate || x === Constants.ColdMediaTempIn || x === Constants.ColdMediaTempOut || x === Constants.ColdMediaPressureDrop || x === Constants.ColdMediaWallTemp);
        const shouldStyleCold = coldInputs && coldInputs.length > 0 ? true : false;

        const preferredUnit = getPreferredUnits().preferredUnits;
        const temperatureUnit = preferredUnit === 'us' ? '°F' : '°C';
        const flowUnit = getFlowTypeUnits(unitConfiguration.flowType!);
        const pressureUnit = preferredUnit === 'us' ? 'PSI' : 'kPa';

        if (
            !isRelevantOutputField(Constants.HotMediaFlowrate, unitConfiguration) &&
            !isRelevantOutputField(Constants.HotMediaTempIn, unitConfiguration) &&
            !isRelevantOutputField(Constants.HotMediaTempOut, unitConfiguration) &&
            !isRelevantOutputField(Constants.HotMediaPressureDrop, unitConfiguration) &&
            !isRelevantOutputField(Constants.ColdMediaFlowrate, unitConfiguration) &&
            !isRelevantOutputField(Constants.ColdMediaTempIn, unitConfiguration) &&
            !isRelevantOutputField(Constants.ColdMediaTempOut, unitConfiguration) &&
            !isRelevantOutputField(Constants.ColdMediaPressureDrop, unitConfiguration) &&
            !isRelevantOutputField(Constants.HotMediaWallTemp, unitConfiguration) &&
            !isRelevantOutputField(Constants.ColdMediaWallTemp, unitConfiguration)
        ) return (<></>)


        return (
            <>
                <Stack style={addStyling && shouldStyleHot ? warmStyling : {}}>
                    {
                        isRelevantOutputField(Constants.HotMediaFlowrate, unitConfiguration) &&
                        <Stack.Item>
                            <Label>{unitConfiguration.hotMediaType} flow rate</Label>
                            <TextFieldNoScroll type='number' readOnly={readOnly} step={0.1} onChange={handleChange} name={Constants.HotMediaFlowrate} value={hotMediaFlowrate?.toString()} suffix={flowUnit} errorMessage={hotMediaFlowrateError} />
                        </Stack.Item>
                    }

                    {
                        isRelevantOutputField(Constants.HotMediaTempIn, unitConfiguration) &&
                        <Stack.Item>
                            <Label>{unitConfiguration.hotMediaType} temperature inlet</Label>
                            <TextFieldNoScroll type='number' readOnly={readOnly} step={0.1} onChange={handleChange} name={Constants.HotMediaTempIn} value={hotMediaTempIn?.toString()} suffix={temperatureUnit} errorMessage={hotMediaTempInError} />
                        </Stack.Item>
                    }

                    {
                        isRelevantOutputField(Constants.HotMediaTempOut, unitConfiguration) &&
                        <Stack.Item>
                            <Label>{unitConfiguration.hotMediaType} temperature outlet</Label>
                            <TextFieldNoScroll type='number' readOnly={readOnly} step={0.1} onChange={handleChange} name={Constants.HotMediaTempOut} value={hotMediaTempOut?.toString()} suffix={temperatureUnit} errorMessage={hotMediaTempOutError} />
                        </Stack.Item>
                    }

                    {
                        isRelevantOutputField(Constants.HotMediaPressureDrop, unitConfiguration) &&
                        <Stack.Item>
                            <Label>{unitConfiguration.hotMediaType} pressure drop</Label>
                            <TextFieldNoScroll type='number' readOnly={readOnly} step={0.1} onChange={handleChange} name={Constants.HotMediaPressureDrop} value={hotMediaPressureDrop?.toString()} suffix={pressureUnit} />
                        </Stack.Item>
                    }

                    {
                        isRelevantOutputField(Constants.HotMediaWallTemp, unitConfiguration) &&
                        <Stack.Item>
                            <Label>{unitConfiguration.hotMediaType} wall temperature</Label>
                            <TextFieldNoScroll type='number' readOnly={readOnly} step={0.1} onChange={handleChange} name={Constants.HotMediaWallTemp} value={hotMediaWallTemp?.toString()} suffix={temperatureUnit} />
                        </Stack.Item>
                    }
                </Stack>

                <Stack style={addStyling && shouldStyleCold ? coldStyling : {}}>
                    {
                        isRelevantOutputField(Constants.ColdMediaFlowrate, unitConfiguration) &&
                        <Stack.Item>
                            <Label>{unitConfiguration.coldMediaType} flow rate</Label>
                            <TextFieldNoScroll type='number' readOnly={readOnly} step={0.1} onChange={handleChange} name={Constants.ColdMediaFlowrate} value={coldMediaFlowrate?.toString()} suffix={flowUnit} ></TextFieldNoScroll>
                        </Stack.Item>
                    }
                    {
                        isRelevantOutputField(Constants.ColdMediaTempIn, unitConfiguration) &&
                        <Stack.Item>
                            <Label>{unitConfiguration.coldMediaType} temperature inlet</Label>
                            <TextFieldNoScroll type='number' readOnly={readOnly} step={0.1} onChange={handleChange} name={Constants.ColdMediaTempIn} value={coldMediaTempIn?.toString()} suffix={temperatureUnit} errorMessage={coldMediaTempInError} />
                        </Stack.Item>
                    }
                    {
                        isRelevantOutputField(Constants.ColdMediaTempOut, unitConfiguration) &&

                        <Stack.Item>
                            <Label>{unitConfiguration.coldMediaType} temperature outlet</Label>
                            <TextFieldNoScroll type='number' readOnly={readOnly} step={0.1} onChange={handleChange} name={Constants.ColdMediaTempOut} value={coldMediaTempOut?.toString()} suffix={temperatureUnit} ></TextFieldNoScroll>
                        </Stack.Item>
                    }
                    {
                        isRelevantOutputField(Constants.ColdMediaPressureDrop, unitConfiguration) &&
                        <Stack.Item>
                            <Label>{unitConfiguration.coldMediaType} pressure drop</Label>
                            <TextFieldNoScroll type='number' readOnly={readOnly} step={0.1} onChange={handleChange} name={Constants.ColdMediaPressureDrop} value={coldMediaPressureDrop?.toString()} suffix={pressureUnit}></TextFieldNoScroll>
                        </Stack.Item>
                    }
                    {
                        isRelevantOutputField(Constants.ColdMediaWallTemp, unitConfiguration) &&
                        <Stack.Item>
                            <Label>{unitConfiguration.coldMediaType} wall temperature</Label>
                            <TextFieldNoScroll type='number' readOnly={readOnly} step={0.1} onChange={handleChange} name={Constants.ColdMediaWallTemp} value={coldMediaWallTemp?.toString()} suffix={temperatureUnit}></TextFieldNoScroll>
                        </Stack.Item>
                    }
                </Stack>
            </>
        )
    }