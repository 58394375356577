import { CheckboxVisibility, DefaultButton, DetailsList, IColumn, Label, Spinner, SpinnerSize, Stack, TooltipHost, useTheme } from "@fluentui/react"
import { IBlindTest } from "../../../Models/IBlindTest"
import { IDesignSession } from "../../../Models/IDesignSession"
import { IBlindTestState } from "../../../Models/IBlindTestState"
import { IUnitConfiguration } from "../../../Models/IUnitConfiguration"
import { useLocation, useNavigate } from "react-router-dom"
import { useEffect, useMemo, useState } from "react"
import { ApiService } from "../../../Services/ApiService"
import { IMsalContext, useMsal } from "@azure/msal-react"
import { getFormattedDate } from "../../../Utils/UnitsHelper"
import { deCapitalizeFirstLetter, getFlowTypeUnits, getPreferredUnits } from "../../../Services/Global"
import { generateHeader } from "../../../Utils/UnitConfigurationHelper"

export const BlindTestDetailsList: React.FC<{}> = (props) => {
    const location = useLocation();
    let navigate = useNavigate();
    const ctx: IMsalContext = useMsal();
    let theme = useTheme();
    const apiService = useMemo(() => new ApiService(ctx), [ctx]);
    const [designSessions, setDesignSessions] = useState<IBlindTest[]>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const stateParam = location.state as IBlindTestState;
    const blindtest = stateParam.blindTest;
    const unitConfig = stateParam.unitConfiguration;
    const [dataColumns, setDataColumns] = useState<IColumn[]>();
    const preferredTemperatureUnits: string = `°${getPreferredUnits().preferredUnits === 'us' ? 'F' : 'C'}`;
    let preferredFlowrateUnits: string = getPreferredUnits().preferredUnits === 'us' ? 'lb/h' : 'kg/h';
    const preferredPressureUnits: string = getPreferredUnits().preferredUnits === 'us' ? 'PSI' : 'kPa';

    useEffect(() => {
        async function getDesignSessionsForBlindTest() {
            setIsLoading(true);
            const response = await apiService.getAsync(`blindtests/designsessions/${blindtest.id}`);
            if (!response.ok) {
                setIsLoading(false);
                throw new Error('Could not fetch design sessions');
            }

            const result = await response.json();

            const unitConfigResponse = await apiService.getAsync(`settings/unitconfiguration`, unitConfig);
            if (!response.ok) {
                throw new Error(`Error: ${response.status} ${response.statusText}`);
            }

            const unitConfigresult: IUnitConfiguration = await unitConfigResponse.json();
            preferredFlowrateUnits = getFlowTypeUnits(unitConfigresult.flowType!);
            unitConfigresult.inputs.forEach((input: string) => {
                let name = generateHeader(input, unitConfigresult!);
                columns.push({
                    key: deCapitalizeFirstLetter(input),
                    name: name,
                    minWidth: 100,
                    maxWidth: 150,
                    fieldName: deCapitalizeFirstLetter(input),
                    onRender: (item?: any, index?: number, column?: IColumn) => {
                        let output: string = '';
                        console.log('column', column)
                        if (column!.key.toLowerCase().includes('flowrate')) {
                            output = item![column!.fieldName!] ? `${item![column!.fieldName!]} ${preferredFlowrateUnits}` : "";
                        }
                        if (column!.key.toLowerCase().includes('pressure')) {
                            output = item![column!.fieldName!] ? `${item![column!.fieldName!]} ${preferredPressureUnits}` : "";
                        }
                        if (column!.key.toLowerCase().includes('temp')) {
                            output = item![column!.fieldName!] ? `${item![column!.fieldName!]} ${preferredTemperatureUnits}` : "";
                        }
                        return (
                            <TooltipHost content={output}>
                                <p style={{ color: column!.fieldName!.toLowerCase().includes('hot') ? theme.palette.redDark : theme.palette.themePrimary }}>
                                    {output}
                                </p>
                            </TooltipHost>
                        );
                    }
                });
            });

            columns.push({
                key: '1',
                name: '',
                minWidth: 100,
                maxWidth: 100,
                fieldName: 'render',
            });

            setDataColumns(columns);
            setDesignSessions(result);
            setIsLoading(false);
        }
        getDesignSessionsForBlindTest();
    }, [location.state]);

    let columns: IColumn[] = [
        {
            key: 'ID',
            name: 'Model ID',
            minWidth: 100,
            maxWidth: 150,
            fieldName: 'modelId',
            onRender: (item?: IDesignSession, index?: number, column?: IColumn) => {
                return (
                    <TooltipHost key={`${index}-${item?.id}-modelid`} content={item?.modelId}>
                        <p>{item?.modelId}</p>
                    </TooltipHost>
                )
            }
        },
        {
            key: 'HotMediaType',
            name: 'Hot media type',
            minWidth: 80,
            maxWidth: 120,
            fieldName: 'hotMediaType',
            onRender: (input) => {
                console.log('input hot media', input)
                return (

                    <TooltipHost content={input.hotMediaType}>
                        <p>
                            {input.hotMediaType}
                        </p>
                    </TooltipHost>
                )
            }
        },
        {
            key: 'ColdMediaType',
            name: 'Cold media type',
            minWidth: 80,
            maxWidth: 120,
            fieldName: 'coldMediaType',
            onRender: (input) => {
                return (

                    <TooltipHost content={input.coldMediaType}>
                        <p>
                            {input.coldMediaType}
                        </p>
                    </TooltipHost>
                )
            }
        }
    ]

    return (
        <Stack grow>
            <Stack horizontal style={{ margin: 15 }}>
                <DefaultButton style={{ padding: 10, borderRadius: 5 }} iconProps={{ iconName: 'Back' }} text='Back' onClick={() => { navigate(-1) }} />
                <h4 style={{ padding: 10, color: theme.palette.themePrimary }}>Generated designs for Blind test: {blindtest.blindTestSetId}</h4>
            </Stack>
            {
                isLoading &&
                <Spinner size={SpinnerSize.large} />
            }
            {
                designSessions &&
                <DetailsList items={designSessions!} columns={dataColumns} checkboxVisibility={CheckboxVisibility.hidden} />
            }
        </Stack>)
}
