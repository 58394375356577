import { IMsalContext, useMsal } from '@azure/msal-react';
import { CheckboxVisibility, DefaultButton, DetailsList, DetailsRow, IColumn, IComboBoxOption, IconButton, IDetailsListStyles, IDetailsRowProps, IDetailsRowStyleProps, IDetailsRowStyles, IStyleFunctionOrObject, Label, PrimaryButton, SelectableOptionMenuItemType, Spinner, SpinnerSize, Stack, TooltipHost, useTheme } from '@fluentui/react';
import { useEffect, useMemo, useState } from 'react';
import { getFlowTypeUnits, hasRole } from '../../../../Services/Global';
import { ApiService } from '../../../../Services/ApiService';
import { Roles } from '../../../../Models/Enums';
import { IMultiSectionUnitConfiguration, IUnitBaseConfiguration, IUnitConfiguration } from '../../../../Models/IUnitConfiguration';
import { IPHEPlateType } from '../../../../Models/IPHEPlateType';
import { useNavigate } from 'react-router-dom';
import { MultiSectionTiles } from '../../../Atom/MultiSectionVisualization/MultiSectionTiles';
import FilterToggle from '../../../Atom/Filtering/FilterToggle';
import { UCManagementFilter } from '../UCManagementFilter';
import { newUnitConfigurationFilter } from '../../../../Utils/UnitConfigurationHelper';
import { IUnitConfigurationFilter } from '../../../../Models/IUnitConfigurationFilter';
import { Button } from '@fluentui/react-components';

export const UnitConfigurationManagement: React.FC<{}> = () => {
    const ctx: IMsalContext = useMsal();
    let theme = useTheme();
    let navigate = useNavigate();

    const [currentData, setCurrentData] = useState<IUnitConfiguration[]>([]);
    const [dataColumns, setDataColumns] = useState<IColumn[]>();
    const apiService = useMemo(() => new ApiService(ctx), [ctx]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showFilters, setShowFilters] = useState(false);
    const [unitConfigurationFilter, setUnitConfigurationFilter] = useState<IUnitConfigurationFilter>(newUnitConfigurationFilter());
    const [filteredData, setFilteredData] = useState<IUnitConfiguration[]>([]);
    const [isInitialApiCallComplete, setIsInitialApiCallComplete] = useState<boolean>(false);
    const [isFiltering, setIsFiltering] = useState<boolean>(false);

    const [sections, setSections] = useState<IMultiSectionUnitConfiguration[]>();

    let columns: IColumn[] = [];

    const gridStyles: Partial<IDetailsListStyles> = {
        root: {
            overflowX: 'scroll',
            borderRadius: 5,
            marginTop: 20,
            width: '93vw',
            boxShadow: theme.effects.elevation4
        }
    }

    const tableColumns: IColumn[] = [
        {
            key: 'edit',
            minWidth: 50,
            maxWidth: 50,
            name: '',
            onRender: (item?: IUnitConfiguration, index?: number, column?: IColumn) => {
                return (
                    <>
                        {
                            <IconButton disabled={item?.isDisabled} style={{ color: theme.palette.themePrimary }} iconProps={{ iconName: 'Edit' }} onClick={() =>
                                navigate('unitconfigurationmanagement/unitconfigurationeditor', { state: { unitConfiguration: item } })}
                            />
                        }
                    </>
                )
            }
        },
        {
            key: 'industry',
            minWidth: 100,
            maxWidth: 150,
            isMultiline: true,
            name: 'Industry',
            fieldName: 'industry',
            onRender: (item?: IUnitConfiguration, index?: number, column?: IColumn) => {
                return (
                    <TooltipHost content={item?.industry}>
                        <p>
                            {item?.industry}
                        </p>
                    </TooltipHost>
                )
            }
        },

        {
            key: 'subIndustry',
            minWidth: 100,
            maxWidth: 150,
            isMultiline: true,
            name: 'Sub Industry',
            fieldName: 'subIndustry',
            onRender: (item?: IUnitConfiguration, index?: number, column?: IColumn) => {
                return (
                    <TooltipHost content={item?.subIndustry}>
                        <p>
                            {item?.subIndustry}
                        </p>
                    </TooltipHost>
                )
            }
        },

        {
            key: 'application',
            minWidth: 100,
            maxWidth: 150,
            name: 'Application',
            isMultiline: true,
            fieldName: 'application',
            onRender: (item?: IUnitConfiguration, index?: number, column?: IColumn) => {
                return (
                    <TooltipHost content={item?.application}>
                        <p>
                            {item?.application}
                        </p>
                    </TooltipHost>
                )
            }
        },

        {
            key: 'subApplication',
            minWidth: 100,
            isMultiline: true,
            maxWidth: 100,
            name: 'Sub App.',
            fieldName: 'subApplication',
            onRender: (item?: IUnitConfiguration, index?: number, column?: IColumn) => {
                return (
                    <TooltipHost content={item?.subApplication}>
                        <p>
                            {item?.subApplication}
                        </p>
                    </TooltipHost>
                )
            }
        }, {
            key: 'processStage',
            minWidth: 100,
            maxWidth: 100,
            isMultiline: true,
            name: 'Process Stage',
            fieldName: 'processStage',
            onRender: (item?: IUnitConfiguration, index?: number, column?: IColumn) => {
                return (
                    <TooltipHost content={item?.processStage}>
                        <p>
                            {item?.processStage}
                        </p>
                    </TooltipHost>
                )
            }
        }, {
            key: 'productLine',
            minWidth: 100,
            maxWidth: 100,
            isMultiline: true,
            name: 'Product Line',
            fieldName: 'productLine',
            onRender: (item?: IUnitConfiguration, index?: number, column?: IColumn) => {
                return (
                    <TooltipHost content={item?.productLine}>
                        <p>
                            {item?.productLine}
                        </p>
                    </TooltipHost>
                )
            }
        }, {
            key: 'alfaLavalPosition',
            minWidth: 100,
            maxWidth: 100,
            isMultiline: true,
            name: 'Position',
            fieldName: 'alfaLavalPosition',
            onRender: (item?: IUnitConfiguration, index?: number, column?: IColumn) => {
                return (
                    <TooltipHost content={item?.alfaLavalPosition}>
                        <p>
                            {item?.alfaLavalPosition}
                        </p>
                    </TooltipHost>
                )
            }
        }, {
            key: 'flow',
            minWidth: 50,
            maxWidth: 50,
            name: 'Flow',
            fieldName: 'flowType',
            isMultiline: true,
            onRender: (item?: IUnitConfiguration, index?: number, column?: IColumn) => {
                return (
                    <TooltipHost content={item?.flowType}>
                        <p>
                            {`${getFlowTypeUnits(item?.flowType!)}`}
                        </p>
                    </TooltipHost>
                )
            }
        }, {
            key: 'pheTypes',
            minWidth: 100,
            maxWidth: 100,
            name: 'PHE types',
            fieldName: 'pheTypes',
            isMultiline: true,
            onRender: (item?: IUnitConfiguration, index?: number, column?: IColumn) => {
                return (
                    <TooltipHost content={item?.pheTypes?.flatMap(i => i).join(', ')}>
                        <p>
                            {item?.pheTypes?.flatMap(i => i).join(', ')}
                        </p>
                    </TooltipHost>
                )
            }
        },
        {
            key: 'isMultiSection',
            minWidth: 150,
            maxWidth: 150,
            name: 'Multisection',
            fieldName: 'isMultiSection',
            onRender: (item?: IMultiSectionUnitConfiguration, index?: number, column?: IColumn) => {
                return (
                    <TooltipHost content={item?.isMultiSection ? '✅' : ''}>
                        <p>
                            {item?.isMultiSection ? '✅' : ''}
                        </p>
                    </TooltipHost>
                )
            }

        },
        {
            key: 'isDisabled',
            minWidth: 150,
            maxWidth: 150,
            name: 'Action',
            fieldName: 'isDisabled',
            onRender: (item?: IUnitConfiguration, index?: number, column?: IColumn) => {
                return (
                    <>
                        {
                            item && item.isDisabled !== undefined &&
                            <TooltipHost content={item.isDisabled ? "Enable this unit configuration" : "Disable this unit configuration"}>

                                {
                                    item.isDisabled &&
                                    <p>
                                        <DefaultButton text={"Enable"} onClick={() => enableDisableUnitConfiguration(item, !item?.isDisabled)} />
                                    </p>
                                    ||
                                    <p>

                                        <PrimaryButton text={"Disable"} onClick={() => enableDisableUnitConfiguration(item, !item?.isDisabled)} />
                                    </p>
                                }
                            </TooltipHost>
                        }

                    </>
                )
            }

        }
    ]

    async function enableDisableUnitConfiguration(config: IUnitConfiguration, isDisbaled: boolean) {
        config.isDisabled = isDisbaled;
        const response = await apiService.putAsync(config, `settings/unitconfigurations`);
        if (!response.ok) {
            const error = await response.text();
            throw new Error(`Could not enable/disable unit configuration. ${error}`);
        }
        getAllUnitConfigs();
    }

    async function deleteUnitConfiguration(config: IUnitConfiguration) {
        const response = await apiService.delete('settings/unitconfigurations', config);
        if (!response.ok) {
            const error = await response.text();
            throw new Error(`Could not delete unit configuration. ${error}`);
        }

        setCurrentData(currentData?.filter(c =>
            c.industry !== config.industry &&
            c.subIndustry !== config.subIndustry &&
            c.application !== config.application &&
            c.subApplication !== config.subApplication &&
            c.processStage !== config.processStage &&
            c.productLine !== config.productLine &&
            c.alfaLavalPosition !== config.alfaLavalPosition));
    }

    if (process.env.NODE_ENV === 'development') {
        tableColumns.splice(1, 0, {
            key: 'delete',
            minWidth: 50,
            maxWidth: 50,
            name: '',
            onRender: (item: IUnitConfiguration, index?: number, column?: IColumn) => {
                return (
                    <>
                        {
                            <IconButton style={{ color: theme.palette.redDark }} iconProps={{ iconName: 'Delete' }} onClick={() => deleteUnitConfiguration(item)}
                            />
                        }
                    </>
                )
            }
        });

    }


    async function setColumns() {
        const unitConfig: IUnitBaseConfiguration = {};

        Object.keys(unitConfig).forEach(c => {
            const capitalizedVal = c.charAt(0).toUpperCase() + c.slice(1);
            if (c != 'breakdownOfProcessStage' && c != 'samplingId' && c != 'prefferedUnit' && c != 'Id') {
                columns.push({
                    key: c, //deCapitalizeFirstLetter(c),
                    name: capitalizedVal.replace(/([A-Z])/g, ' $1').trim(),
                    minWidth: 100,
                    maxWidth: 150,
                    fieldName: c,// deCapitalizeFirstLetter(c),
                    onRender: (item?: any, index?: number, column?: IColumn) => {
                        if (column!.key.toLowerCase().includes('inputs') || column!.key.toLowerCase().includes('nonusedproperties')) {
                            let output: string = `${item![column!.key]}`;
                            return (
                                <TooltipHost content={output}>
                                    <p>
                                        {output}
                                    </p>
                                </TooltipHost>
                            );
                        }
                        else {
                            return (
                                `${item![column!.key]}`
                            )
                        }
                    }
                })
            }
        });

        setDataColumns(columns);
    }

    useEffect(() => {

        if (dataColumns === undefined) {
            setColumns();
        }

        async function setPHETypeComboBoxOptions() {
            let options: IComboBoxOption[] = [];
            let selectAll: IComboBoxOption = { key: 'selectAll', text: 'Select All', itemType: SelectableOptionMenuItemType.SelectAll };
            let header1: IComboBoxOption = { key: 'Header1', text: 'PHE Types', itemType: SelectableOptionMenuItemType.Header };
            options.push(selectAll);
            options.push(header1);
            const response = await apiService.getAsync('settings/pheplatetypes');
            const data = await response.json();

            const pheData: IPHEPlateType[] = data;
            Object.values(pheData).filter(k => isNaN(Number(k))).map(r => r as IPHEPlateType).forEach(c => {
                let field: IComboBoxOption = {
                    key: c.key,
                    text: c.text
                }
                options.push(field)
            });

        }

        getAllUnitConfigs();
        setPHETypeComboBoxOptions();

    }, [dataColumns, unitConfigurationFilter]);

    const getAllUnitConfigs = async () => {
        setIsLoading(true);
        let sessionData: IUnitConfiguration[];
        let filteredData: IUnitConfiguration[];

        if (!isInitialApiCallComplete) {
            const response = await apiService.getAsync('settings/unitconfigurations');
            const data = await response.json();

            sessionData = data;
            setIsInitialApiCallComplete(true);
        }
        else {
            sessionData = currentData;
        }

        filteredData = filterUnitConfigurations(sessionData);

        setCurrentData(sessionData);
        setFilteredData(filteredData);
        setIsLoading(false);
    }

    const filterUnitConfigurations = (sessionData: IUnitConfiguration[]) => {
        let filteredData = sessionData;

        if (unitConfigurationFilter.industry !== '') {
            filteredData = filteredData.filter(c => c.industry === unitConfigurationFilter.industry);
        }
        if (unitConfigurationFilter.subIndustry !== '') {
            filteredData = filteredData.filter(c => c.subIndustry === unitConfigurationFilter.subIndustry);
        }
        if (unitConfigurationFilter.application !== '') {
            filteredData = filteredData.filter(c => c.application === unitConfigurationFilter.application);
        }
        if (unitConfigurationFilter.subApplication !== '') {
            filteredData = filteredData.filter(c => c.subApplication === unitConfigurationFilter.subApplication);
        }
        if (unitConfigurationFilter.productLine !== '') {
            filteredData = filteredData.filter(c => c.productLine === unitConfigurationFilter.productLine);
        }
        if (unitConfigurationFilter.processStage !== '') {
            filteredData = filteredData.filter(c => c.processStage === unitConfigurationFilter.processStage);
            console.log('filtered data count after processStage filter:' + filteredData.length)
        }
        if (unitConfigurationFilter.alfaLavalPosition !== '') {
            filteredData = filteredData.filter(c => c.alfaLavalPosition === unitConfigurationFilter.alfaLavalPosition);
            console.log('filtered data count after alfaLavalPosition filter:' + filteredData.length)
        }
        if (unitConfigurationFilter.singleSectionUnits === false) {
            filteredData = filteredData.filter(c => c.isMultiSection === true);
        }
        if (unitConfigurationFilter.multiSectionUnits === false) {
            filteredData = filteredData.filter(c => c.isMultiSection === false);
        }
        if (unitConfigurationFilter.disabledUnits === true) {
            filteredData = filteredData.filter(c => c.isDisabled === true);
        }
        //filter out unit configurations that do not have a phe type that is selected
        if (unitConfigurationFilter.pheTypes.length > 0) {
            filteredData = filteredData.filter(c => c.pheTypes?.some(pt => unitConfigurationFilter.pheTypes.includes(pt)));
        }

        return filteredData;
    }

    async function getSectionsAsync(uc: IUnitConfiguration) {
        if (uc.isMultiSection) {
            let configSections: IMultiSectionUnitConfiguration[] = [];
            console.log(uc);
            const response = await apiService.getAsync("settings/unitconfigurations/sections", uc);
            if (!response.ok) {
                return;
            }

            const data: IMultiSectionUnitConfiguration[] = await response.json();
            configSections.push(...data);

            setIsLoading(false);
            setSections([...configSections]);
        }
        else {
            setSections([]);
        }
    }
    const toggleShowFilters = () => {
        setShowFilters(!showFilters);
    }

    const handleFilterChange = (filter: IUnitConfigurationFilter, isFiltering: boolean) => {
        setUnitConfigurationFilter({ ...filter });

        setIsFiltering(isFiltering);
    }

    const onRenderRow = (props: IDetailsRowProps | undefined): JSX.Element | null => {
        if (!props) return null;
        const customStyles: IStyleFunctionOrObject<IDetailsRowStyleProps, IDetailsRowStyles> | undefined = {};
        if (props.item.isDisabled) {
            customStyles.root = { backgroundColor: '#f3f2f1', border: '1px solid #f3f2f1' };
        }
        return <DetailsRow {...props} styles={customStyles} />;
    };

    return (
        <Stack horizontal>
            <Stack.Item style={{ padding: 10 }}>
                <FilterToggle backPath='/blindtests/dashboard'
                    isFiltering={isFiltering}
                    showFilters={showFilters}
                    isLoading={isLoading}
                    toggleShowFilters={() => toggleShowFilters()}
                    resultCount={filteredData?.length}
                />
                {
                    showFilters &&
                    <UCManagementFilter onChange={handleFilterChange}
                        filter={unitConfigurationFilter}
                        isFiltering={isFiltering}
                        filteredData={filteredData}
                        currentData={currentData}
                    />
                }
                <br></br>
                <PrimaryButton
                    text='Create new configuration'
                    iconProps={{ iconName: 'Add' }}
                    onClick={() => { navigate('unitconfigurationmanagement/unitconfigurationeditor') }}
                    disabled={isLoading} />

                {
                    isLoading === true ?
                        <Spinner className='loading-spinner' size={SpinnerSize.large} label={'Loading...'} />
                        :
                        <Stack horizontalAlign='center'>
                            {
                                filteredData.length === 0 &&
                                <Label>No unit configutations match the specified filters</Label>
                            }
                            {
                                filteredData.length > 0 &&
                                <DetailsList items={filteredData ?? []}
                                    styles={gridStyles}
                                    columns={tableColumns}
                                    checkboxVisibility={CheckboxVisibility.hidden}
                                    onActiveItemChanged={(i) => getSectionsAsync(i)}
                                    onRenderRow={onRenderRow}
                                />
                            }


                            {
                                sections !== undefined && sections.length > 0 &&
                                <MultiSectionTiles sections={sections} />
                            }
                        </Stack>
                }

            </Stack.Item>
        </Stack>
    );

}
