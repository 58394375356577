import { IMsalContext, useMsal } from "@azure/msal-react";
import { Checkbox, ComboBox, DefaultButton, Dropdown, IComboBoxOption, IDropdownOption, Label, PrimaryButton, SelectableOptionMenuItemType, Spinner, SpinnerSize, Stack, useTheme } from "@fluentui/react";
import { useEffect, useMemo, useState } from "react";
import { ApiService } from "../../../Services/ApiService";
import { generalStackStyles } from "../../../Models/StackStyling";
import { IUnitConfigurationFilter } from "../../../Models/IUnitConfigurationFilter";
import { getLastSplitFromKey, newUnitConfigurationFilter } from "../../../Utils/UnitConfigurationHelper";
import { IUnitConfiguration } from "../../../Models/IUnitConfiguration";
import { PropertyConstants } from "../../../Models/Constants";

export const UCManagementFilter: React.FC<{ onChange: any, filter: IUnitConfigurationFilter, isFiltering: boolean, filteredData: IUnitConfiguration[], currentData: IUnitConfiguration[] }> = (props) => {
    const ctx: IMsalContext = useMsal();
    const apiService = useMemo(() => new ApiService(ctx), [ctx]);
    let theme = useTheme();
    const filteredData = props.filteredData;
    const currentData = props.currentData;

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filter, setFilter] = useState<IUnitConfigurationFilter>(props.filter);
    const [isFiltering, setIsFiltering] = useState<boolean>(props.isFiltering);

    const [selectedIndustry, setSelectedIndustry] = useState<string>(filter.industry);
    const [selectedSubIndustry, setSelectedSubIndustry] = useState<string>(filter.subIndustry);
    const [selectedApplication, setSelectedApplication] = useState<string>(filter.application);
    const [selectedSubApplication, setSelectedSubApplication] = useState<string>(filter.subApplication);
    const [selectedProductLine, setSelectedProductLine] = useState<string>(filter.productLine);
    const [selectedProcessStage, setSelectedProcessStage] = useState<string>((filter.processStage));
    const [selectedAlfaLavalPosition, setSelectedAlfaLavalPosition] = useState<string>((filter.alfaLavalPosition));
    const [singleSectionUnits, setSingleSectionUnits] = useState<boolean>(filter.singleSectionUnits);
    const [multiSectionUnits, setMultiSectionUnits] = useState<boolean>(filter.multiSectionUnits);
    const [disabledUnits, setDisabledUnits] = useState<boolean>(filter.disabledUnits);
    const [selectedPheTypes, setSelectedPheTypes] = useState<string[]>(filter.pheTypes);

    const [industries, setIndustries] = useState<IDropdownOption<any>[]>();
    const [subIndustries, setSubIndustries] = useState<IDropdownOption<any>[]>();
    const [applications, setApplications] = useState<IDropdownOption<any>[]>();
    const [subApplications, setSubApplications] = useState<IDropdownOption<any>[]>();
    const [productLines, setProductLines] = useState<IDropdownOption<any>[]>();
    const [processStages, setProcessStages] = useState<IDropdownOption<any>[]>();
    const [alfaLavalPositions, setAlfaLavalPositions] = useState<IDropdownOption<any>[]>();
    const [pheTypesOptions, setPheTypesOptions] = useState<IComboBoxOption[]>([]);

    useEffect(() => {
        getUnitConfiguration();
    }, [filteredData]);

    async function getUnitConfiguration() {
        setIsLoading(true);

        if (filteredData.length === 0) {
            setIsLoading(false);
            return;
        }
        let inds: IDropdownOption[] = [];
        let subInds: IDropdownOption[] = [];
        let apps: IDropdownOption[] = [];
        let subApps: IDropdownOption[] = [];
        let lines: IDropdownOption[] = [];
        let stages: IDropdownOption[] = [];
        let positions: IDropdownOption[] = [];
        let pheTypes: IComboBoxOption[] = [];

        let pheHeader: IComboBoxOption = { key: "Header1", text: "PHE Types", itemType: SelectableOptionMenuItemType.Header };
        pheTypes.push(pheHeader);

        let relevantUnits = currentData;
        let applicableUnits = currentData;
        let notApplicableUnits: IUnitConfiguration[] = [];

        console.log('current data:', currentData);
        applicableUnits = currentData;

        if (filter.singleSectionUnits == false) {
            applicableUnits = applicableUnits.filter((x) => x.isMultiSection === true);
        }
        if (filter.multiSectionUnits == false) {
            applicableUnits = applicableUnits.filter((x) => x.isMultiSection === false);
        }
        if (filter.disabledUnits == false) {
            applicableUnits = applicableUnits.filter((x) => x.isDisabled === false);
        }
        if (filter.pheTypes.length > 0) {
            applicableUnits = applicableUnits.filter(c => c.pheTypes?.some(pt => filter.pheTypes.includes(pt)));
        }

        relevantUnits = applicableUnits;

        //filter applicable units to only include ones with a matching phetype from the filter
        if (filter.pheTypes.length > 0) {
            applicableUnits = applicableUnits.filter(c => c.pheTypes?.some(pt => filter.pheTypes.includes(pt)));
            notApplicableUnits = currentData.filter(c => !c.pheTypes?.some(pt => filter.pheTypes.includes(pt)));
        }

        //Handle industry filter
        applicableUnits.forEach((unit: IUnitConfiguration) => {
            if (!inds.some((i) => i.text === unit.industry)) {
                inds.push({
                    key: unit.industry,
                    text: unit.industry
                });
            }
        });

        notApplicableUnits.forEach((unit: IUnitConfiguration) => {
            if (!inds.some((i) => i.text === unit.industry)) {
                inds.push({
                    key: unit.industry,
                    text: unit.industry,
                    disabled: true
                });
            }
        });
        if (selectedIndustry !== '' || applicableUnits.length === 0) {
            applicableUnits = applicableUnits.filter((x) => x.industry == filter.industry);
            notApplicableUnits = currentData.filter((x) => x.industry != filter.industry);
        }


        //Handle sub industry filter

        applicableUnits.forEach((unit: IUnitConfiguration) => {
            if (!subInds.some((s) => s.text === unit.subIndustry)) {
                subInds.push({
                    key: `${unit.industry}-${unit.subIndustry}`,
                    text: unit.subIndustry
                });
            }
        });

        notApplicableUnits.forEach((unit: IUnitConfiguration) => {
            if (!subInds.some((s) => s.text === unit.subIndustry)) {
                subInds.push({
                    key: `${unit.industry}-${unit.subIndustry}`,
                    text: unit.subIndustry,
                    disabled: true
                });
            }
        });

        if (selectedSubIndustry !== '') {
            //relevantUnits = relevantUnits.filter((x) => x.subIndustry != filter.industry);
            applicableUnits = applicableUnits.filter((x) => x.subIndustry == filter.subIndustry);
            notApplicableUnits = currentData.filter((x) => x.subIndustry != filter.subIndustry);
        }

        //Handle application filter
        applicableUnits.forEach((unit: IUnitConfiguration) => {
            if (!apps.some((a) => a.text === unit.application)) {
                apps.push({
                    key: `${unit.industry}-${unit.subIndustry}-${unit.application}`,
                    text: unit.application
                });
            }
        });

        notApplicableUnits.forEach((unit: IUnitConfiguration) => {
            if (!apps.some((a) => a.text === unit.application)) {
                apps.push({
                    key: `${unit.industry}-${unit.subIndustry}-${unit.application}`,
                    text: unit.application,
                    disabled: true
                });
            }
        });

        if (selectedApplication !== '') {
            notApplicableUnits = currentData.filter((x) => x.application !== filter.application);
            applicableUnits = applicableUnits.filter((x) => x.application === filter.application);
        }

        //Handle sub application filter
        applicableUnits.forEach((unit: IUnitConfiguration) => {
            if (!subApps.some((s) => s.text === unit.subApplication)) {
                subApps.push({
                    key: `${unit.industry}-${unit.subIndustry}-${unit.application}-${unit.subApplication}`,
                    text: unit.subApplication
                });
            }
        });

        notApplicableUnits.forEach((unit: IUnitConfiguration) => {
            if (!subApps.some((s) => s.text === unit.subApplication)) {
                subApps.push({
                    key: `${unit.industry}-${unit.subIndustry}-${unit.application}-${unit.subApplication}`,
                    text: unit.subApplication,
                    disabled: true
                });
            }
        });

        if (selectedSubApplication !== '') {
            notApplicableUnits = currentData.filter((x) => x.subApplication !== filter.subApplication);
            applicableUnits = applicableUnits.filter((x) => x.subApplication === filter.subApplication);
        }

        //Handle product line filter
        applicableUnits.forEach((unit: IUnitConfiguration) => {
            if (!lines.some((l) => l.text === unit.productLine)) {
                lines.push({
                    key: `${unit.industry}-${unit.subIndustry}-${unit.application}-${unit.subApplication}-${unit.productLine}`,
                    text: unit.productLine
                });
            }
        });

        notApplicableUnits.forEach((unit: IUnitConfiguration) => {
            if (!lines.some((l) => l.text === unit.productLine)) {
                lines.push({
                    key: `${unit.industry}-${unit.subIndustry}-${unit.application}-${unit.subApplication}-${unit.productLine}`,
                    text: unit.productLine,
                    disabled: true
                });
            }
        });

        if (selectedProductLine !== '') {
            notApplicableUnits = currentData.filter((x) => x.productLine !== filter.productLine);
            applicableUnits = applicableUnits.filter((x) => x.productLine === filter.productLine);
        }

        //Handle process stage filter
        applicableUnits.forEach((unit: IUnitConfiguration) => {
            if (!stages.some((s) => s.text === unit.processStage)) {
                stages.push({
                    key: `${unit.industry}-${unit.subIndustry}-${unit.application}-${unit.subApplication}-${unit.productLine}-${unit.processStage}`,
                    text: unit.processStage
                });
            }
        });

        notApplicableUnits.forEach((unit: IUnitConfiguration) => {
            if (!stages.some((s) => s.text === unit.processStage)) {
                stages.push({
                    key: `${unit.industry}-${unit.subIndustry}-${unit.application}-${unit.subApplication}-${unit.productLine}-${unit.processStage}`,
                    text: unit.processStage,
                    disabled: true
                });
            }
        });

        if (selectedProcessStage !== '') {
            notApplicableUnits = currentData.filter((x) => x.processStage !== filter.processStage);
            applicableUnits = applicableUnits.filter((x) => x.processStage === filter.processStage);
        }

        //Handle alfa laval position filter
        applicableUnits.forEach((unit: IUnitConfiguration) => {
            if (!positions.some((p) => p.text === unit.alfaLavalPosition)) {
                positions.push({
                    key: `${unit.industry}-${unit.subIndustry}-${unit.application}-${unit.subApplication}-${unit.productLine}-${unit.processStage}-${unit.alfaLavalPosition}`,
                    text: unit.alfaLavalPosition
                });
            }
        });

        notApplicableUnits.forEach((unit: IUnitConfiguration) => {
            if (!positions.some((p) => p.text === unit.alfaLavalPosition)) {
                positions.push({
                    key: `${unit.industry}-${unit.subIndustry}-${unit.application}-${unit.subApplication}-${unit.productLine}-${unit.processStage}-${unit.alfaLavalPosition}`,
                    text: unit.alfaLavalPosition,
                    disabled: true
                });
            }
        });

        if (selectedAlfaLavalPosition !== '') {
            notApplicableUnits = currentData.filter((x) => x.alfaLavalPosition !== filter.alfaLavalPosition);
            applicableUnits = applicableUnits.filter((x) => x.alfaLavalPosition === filter.alfaLavalPosition);
        }


        //Get PHE types from all units
        applicableUnits.forEach((unit: IUnitConfiguration) => {
            unit.pheTypes?.forEach((pheType) => {
                //check phetype is not already added
                if (!pheTypes.some((p) => p.text === pheType)) {
                    pheTypes.push({
                        key: pheType,
                        text: pheType
                    });
                }
            });
        });

        notApplicableUnits.forEach((unit: IUnitConfiguration) => {
            unit.pheTypes?.forEach((pheType) => {
                //check phetype is not already added
                if (!pheTypes.some((p) => p.text === pheType)) {
                    pheTypes.push({
                        key: pheType,
                        text: pheType,
                        disabled: true
                    });
                }
            });
        });

        setIndustries(inds);
        setSubIndustries(subInds);
        setApplications(apps);
        setSubApplications(subApps);
        setProductLines(lines);
        setProcessStages(stages);
        setAlfaLavalPositions(positions);
        setPheTypesOptions(pheTypes);

        setIsLoading(false);
    }


    useEffect(() => {
        const newFilter = filter;
        newFilter.industry = getLastSplitFromKey(selectedIndustry);
        newFilter.subIndustry = getLastSplitFromKey(selectedSubIndustry);
        newFilter.application = getLastSplitFromKey(selectedApplication);
        newFilter.subApplication = getLastSplitFromKey(selectedSubApplication);
        newFilter.productLine = getLastSplitFromKey(selectedProductLine);
        newFilter.processStage = getLastSplitFromKey(selectedProcessStage);
        newFilter.alfaLavalPosition = getLastSplitFromKey(selectedAlfaLavalPosition);
        newFilter.singleSectionUnits = singleSectionUnits;
        newFilter.multiSectionUnits = multiSectionUnits;
        newFilter.disabledUnits = disabledUnits;
        newFilter.pheTypes = selectedPheTypes;
        setFilter(newFilter);
        props.onChange(newFilter, isFiltering);

    },
        [selectedIndustry, selectedSubIndustry, selectedApplication, selectedSubApplication, selectedProcessStage, selectedProductLine, selectedAlfaLavalPosition, singleSectionUnits, multiSectionUnits, disabledUnits, isFiltering, selectedPheTypes]);

    const handleChange = (option?: IDropdownOption<any>, name?: string) => {
        if (option === undefined) return;

        switch (name) {
            case 'industry':
                console.log('industry selected');
                setSelectedIndustry(option.key as string);
                setSelectedSubIndustry('');
                setSelectedApplication('');
                setSelectedSubApplication('');
                setSelectedProductLine('');
                setSelectedProcessStage('');
                setSelectedAlfaLavalPosition('');
                break;
            case 'subIndustry':
                setSelectedSubIndustry(option.key as string);
                setSelectedApplication('');
                setSelectedSubApplication('');
                setSelectedProductLine('');
                setSelectedProcessStage('');
                setSelectedAlfaLavalPosition('');
                break;
            case 'application':
                setSelectedApplication(option.key as string);
                setSelectedSubApplication('');
                setSelectedProductLine('');
                setSelectedProcessStage('');
                setSelectedAlfaLavalPosition('');
                break;
            case 'subApplication':
                setSelectedSubApplication(option.key as string);
                setSelectedProductLine('');
                setSelectedProcessStage('');
                setSelectedAlfaLavalPosition('');
                break;
            case 'productLine':
                setSelectedProductLine(option.key as string);
                setSelectedProcessStage('');
                setSelectedAlfaLavalPosition('');
                break;
            case 'processStage':
                setSelectedProcessStage(option.key as string);
                setSelectedAlfaLavalPosition('');
                break;
            case 'alfaLavalPosition':
                setSelectedAlfaLavalPosition(option.key as string);
                break;
            default:
                break;
        }

        setIsFiltering(true);

    }

    const handleCheckboxChange = (name: string) => {
        switch (name) {
            case 'singleSectionUnits':
                setSingleSectionUnits(!singleSectionUnits);
                break;
            case 'multiSectionUnits':
                setMultiSectionUnits(!multiSectionUnits);
                break;
            case 'disabledUnits':
                setDisabledUnits(!disabledUnits);
                break;
            default:
                break;
        }

        setIsFiltering(true);
    }

    const handleComboBoxChange = (e: any, property: string) => {
        if (property === PropertyConstants.PHEType) {
            // Ensure currentPHETypes is always an array
            let currentPHETypes = [...(selectedPheTypes ?? [])];

            if (e.selected === true) {
                // Add the new type if it's not already in the array
                if (!currentPHETypes.includes(e.key)) {
                    currentPHETypes.push(e.key);
                }
            } else {
                // Remove the type if it exists in the array
                const index = currentPHETypes.indexOf(e.key);
                if (index > -1) {
                    currentPHETypes.splice(index, 1);
                }
            }

            console.log('selected PHE Types:');
            console.log(currentPHETypes);

            // Update the state with the new array
            setSelectedPheTypes(currentPHETypes);
            setIsFiltering(true);
        }
    };


    const resetFilter = () => {
        const blankFilter = newUnitConfigurationFilter();
        setSelectedIndustry('');
        setSelectedSubIndustry('');
        setSelectedApplication('');
        setSelectedSubApplication('');
        setSelectedProductLine('');
        setSelectedProcessStage('');
        setSelectedAlfaLavalPosition('');
        setSingleSectionUnits(true);
        setMultiSectionUnits(true);
        setDisabledUnits(false);
        setSelectedPheTypes([]);
        setFilter(blankFilter);
        setIsFiltering(false);
        props.onChange(blankFilter, false);
    }

    return (
        <Stack horizontal style={{ padding: 10 }}>

            {
                isLoading &&
                <Spinner size={SpinnerSize.large} label="Loading filters..." />
            }
            {
                !isLoading &&
                <>

                    <Stack horizontal styles={generalStackStyles(theme)} style={{ display: 'block' }}>
                        <Stack.Item style={{ minWidth: 250, padding: 5, float: 'left' }}>
                            <Label>Show:</Label>
                            <Checkbox styles={{ root: { marginLeft: 20, marginTop: 5 } }} label='Single section units' checked={singleSectionUnits} onChange={(e) => { handleCheckboxChange('singleSectionUnits') }} />
                            <Checkbox styles={{ root: { marginLeft: 20, marginTop: 5 } }} label='Multisection units' checked={multiSectionUnits} onChange={(e) => { handleCheckboxChange('multiSectionUnits') }} />
                            <Checkbox styles={{ root: { marginLeft: 20, marginTop: 5 } }} label='Disabled configurations' checked={disabledUnits} onChange={(e) => { handleCheckboxChange('disabledUnits') }} />

                        </Stack.Item>
                        <Stack.Item style={{ minWidth: 250, padding: 5, float: 'left' }}>
                            <Label>Only PHE Types</Label>
                            <ComboBox
                                id="PHEtype"
                                multiSelect
                                options={pheTypesOptions!}
                                onChange={(e, i) => handleComboBoxChange(i, PropertyConstants.PHEType)}
                                selectedKey={selectedPheTypes}
                            //defaultSelectedKey={unitConfig?.pheTypes?.map((x) => { return x.split(',')[0] })}
                            />
                        </Stack.Item>
                    </Stack>
                    <Stack horizontal styles={generalStackStyles(theme)} style={{ display: 'block' }}>

                        <Stack.Item style={{ minWidth: 250, padding: 5, float: 'left' }}>
                            <Label>Industry</Label>
                            <Dropdown disabled={isLoading} options={industries!} onChange={(e, item) => handleChange(item, 'industry')} selectedKey={selectedIndustry} />
                        </Stack.Item>
                        <Stack.Item style={{ minWidth: 250, padding: 5, float: 'left' }}>
                            <Label>Sub Industry</Label>
                            <Dropdown disabled={isLoading} options={subIndustries!} onChange={(e, item) => handleChange(item, 'subIndustry')} selectedKey={selectedSubIndustry} />
                        </Stack.Item>
                        <Stack.Item style={{ minWidth: 250, padding: 5, float: 'left' }}>
                            <Label>Application</Label>
                            <Dropdown disabled={isLoading} options={applications!} onChange={(e, item) => handleChange(item, 'application')} selectedKey={selectedApplication} />
                        </Stack.Item>
                        <Stack.Item style={{ minWidth: 250, padding: 5, float: 'left' }}>
                            <Label>Sub application</Label>
                            <Dropdown disabled={isLoading} options={subApplications!} onChange={(e, item) => handleChange(item, 'subApplication')} selectedKey={selectedSubApplication} />
                        </Stack.Item>
                        <Stack.Item style={{ minWidth: 250, padding: 5, float: 'left' }}>
                            <Label>Product line</Label>
                            <Dropdown disabled={isLoading} options={productLines!} onChange={(e, item) => handleChange(item, 'productLine')} selectedKey={selectedProductLine} />
                        </Stack.Item>
                        <Stack.Item style={{ minWidth: 250, padding: 5, float: 'left' }}>
                            <Label>Process stage</Label>
                            <Dropdown disabled={isLoading} options={processStages!} onChange={(e, item) => handleChange(item, 'processStage')} selectedKey={selectedProcessStage} />
                        </Stack.Item>
                        <Stack.Item style={{ minWidth: 250, padding: 5, float: 'left' }}>
                            <Label>Position</Label>
                            <Dropdown disabled={isLoading} options={alfaLavalPositions!} onChange={(e, item) => handleChange(item, 'alfaLavalPosition')} selectedKey={selectedAlfaLavalPosition} />
                        </Stack.Item>

                    </Stack>
                    <Stack>

                        <Stack.Item>
                            <DefaultButton style={{ marginLeft: 20, marginTop: 20 }}
                                onClick={() => resetFilter()}>
                                Reset filters
                            </DefaultButton>
                        </Stack.Item>
                    </Stack>
                    <br />
                </>
            }
        </Stack>
    );
}