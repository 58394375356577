import { Constants, PHEType, UnitTargetValueNames } from "../Models/Constants";
import { IMultiSectionUnitConfiguration, IUnitConfiguration } from "../Models/IUnitConfiguration";
import { IUnitConfigurationFilter } from "../Models/IUnitConfigurationFilter";

export function isRelevantInputField(field: string, unitConfiguration: IUnitConfiguration | IMultiSectionUnitConfiguration) {
    return unitConfiguration?.inputs?.find(x => x === field) !== undefined && unitConfiguration?.nonUsedProperties?.find(x => x === field) === undefined;
}

export function isRelevantOutputField(field: string, unitConfiguration: IUnitConfiguration | IMultiSectionUnitConfiguration) {

    return unitConfiguration?.inputs?.find(x => x.toLowerCase() === field.toLowerCase()) === undefined && unitConfiguration?.nonUsedProperties?.find(x => x.toLowerCase() === field.toLowerCase()) === undefined;
}
export function findCommonMedicaProperties(unitConfiguration: IUnitConfiguration | IMultiSectionUnitConfiguration) {
    let outputs: string[] = [];
    if (!unitConfiguration.inputs) return;
    if (!unitConfiguration.nonUsedProperties) return;
    unitConfiguration.inputs.forEach(input => {
        if (unitConfiguration.nonUsedProperties!.find(nonUsedProp => nonUsedProp === input) !== undefined || unitConfiguration.nonUsedProperties!.length === 0)
            outputs.push(input)
    });
    unitConfiguration.nonUsedProperties.forEach(nonusedProp => {
        if (unitConfiguration.inputs!.find(input => input === nonusedProp) !== undefined || unitConfiguration.inputs!.length === 0)
            outputs.push(nonusedProp)
    });
    unitConfiguration.inputs?.filter(v => unitConfiguration.nonUsedProperties!.includes(v)).forEach(val => outputs.push(val))
    return outputs;
}
export function haveHotMediaOutputFields(unitConfiguration: IUnitConfiguration | IMultiSectionUnitConfiguration) {
    let commonMedia = findCommonMedicaProperties(unitConfiguration);
    if (commonMedia !== undefined && commonMedia.length > 0) {
        let filteredCollectoin = commonMedia.filter(c => c.substring(0, 3) === 'Hot');
        return filteredCollectoin!.length > 0;
    }
    return false;
}

export function haveColdMediaOutPutFields(unitConfiguration: IUnitConfiguration | IMultiSectionUnitConfiguration) {
    let commonMedia = findCommonMedicaProperties(unitConfiguration);
    if (commonMedia !== undefined && commonMedia.length > 0) {

        let filteredCollectoin = commonMedia.filter(c => c.substring(0, 4) === 'Cold');
        return filteredCollectoin!.length > 0;
    }
    return false;
}

export function getFormattedColumnValue(columnName: string, unitConfiguration: IUnitConfiguration) {
    return `${columnName.includes('Hot') ? unitConfiguration.hotMediaType : unitConfiguration.coldMediaType}${columnName.replace('HotMedia', ' ').replace('ColdMedia', ' ')}`;
}

export function getPageKeyName(unitConfiguration: IUnitConfiguration, fromPage: string) {
    return unitConfiguration.industry + '_' + unitConfiguration.subIndustry
        + '_' + unitConfiguration.application + '_' + unitConfiguration.subApplication + '_' + unitConfiguration.processStage
        + '_' + unitConfiguration.productLine + '_' + unitConfiguration.alfaLavalPosition + '_' + fromPage;
}

export function getUnitConfigurationKeyAsDashedString(unitConfiguration: IUnitConfiguration) {
    return unitConfiguration.industry + '-' +
        unitConfiguration.subIndustry + '-' +
        unitConfiguration.application + '-' +
        unitConfiguration.subApplication + '-' +
        unitConfiguration.processStage + '-' +
        unitConfiguration.productLine + '-' +
        unitConfiguration.alfaLavalPosition;
}

export function getPositionAndProductLineFromConfigurationKey(key: string): { productLine: string, position: string } {
    let splitted = key.split('-');
    return { productLine: splitted[5], position: splitted[6] };
}

export function getUnitConfigurationFromConfigurationKey(key: string): IUnitConfiguration {
    let splitted = key.split('-');
    return {
        industry: splitted[0],
        subIndustry: splitted[1],
        application: splitted[2],
        subApplication: splitted[3],
        productLine: splitted[4],
        processStage: splitted[5],
        alfaLavalPosition: splitted[6],
        inputs: [], nonUsedProperties: [], outputs: [], breakdownOfProcessStage: '', coldMediaType: '', hotMediaType: ''
    }
}

export function getLastSplitFromKey(key: string | null): string {
    if (key === null) {
        return '';
    }

    let splitted = key.split('-');
    return splitted.length > 0 ? splitted[splitted.length - 1] : '';
}


export function newUnitConfigurationFilter(): IUnitConfigurationFilter {
    return {
        industry: '',
        subIndustry: '',
        application: '',
        subApplication: '',
        productLine: '',
        processStage: '',
        alfaLavalPosition: '',
        singleSectionUnits: true,
        multiSectionUnits: true,
        disabledUnits: false,
        pheTypes: []
    }

}

export function generateHeader(input: string, inputItem: IUnitConfiguration) {
    let result = '';
    switch (input) {
        case Constants.HotMediaFlowrate:
            result = inputItem?.hotMediaType! + ' mass flow rate';
            break;
        case Constants.HotMediaTempIn:
            result = inputItem?.hotMediaType! + ' temp inlet';
            break;
        case Constants.HotMediaTempOut:
            result = inputItem?.hotMediaType! + ' temp outlet';
            break;
        case Constants.HotMediaPressureDrop:
            result = inputItem?.hotMediaType! + 'pressure drop';
            break;
        case Constants.ColdMediaFlowrate:
            result = inputItem?.coldMediaType! + ' mass flow rate';
            break;
        case Constants.ColdMediaTempIn:
            result = inputItem?.coldMediaType! + ' temp inlet';
            break;
        case Constants.ColdMediaTempOut:
            result = inputItem?.coldMediaType! + ' temp outlet';
            break;
        case Constants.ColdMediaPressureDrop:
            result = inputItem?.coldMediaType! + ' pressure drop';
            break;
        case Constants.ColdMediaWallTemp:
            result = inputItem?.coldMediaType! + ' wall temp';
            break;
        case Constants.HotMediaWallTemp:
            result = inputItem?.hotMediaType! + ' wall temp';
            break;
        default:
            result = ' ';
            break;
    }

    return result;
}

export function getUnitOperationTargetValueName(unitOperationTargetValue: string) {
    return UnitTargetValueNames[unitOperationTargetValue?.toLowerCase()] ?? unitOperationTargetValue
}

export function getPHEType(pheType: any) {
    return PHEType[pheType?.toLowerCase()] ?? pheType
}