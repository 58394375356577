import { IMsalContext, useMsal } from '@azure/msal-react';
import { Checkbox, CheckboxVisibility, ChoiceGroup, DefaultButton, DetailsList, Dialog, DialogType, Dropdown, FontSizes, FontWeights, IColumn, Icon, IconButton, IDetailsListStyles, IDropdownOption, Label, mergeStyleSets, MessageBar, MessageBarType, Modal, PrimaryButton, Spinner, SpinnerSize, Stack, TextField, TooltipHost, useTheme } from '@fluentui/react';
import { useEffect, useMemo, useState } from 'react';
import { ApiService } from '../../../Services/ApiService';
import { IPHEPlateType } from '../../../Models/IPHEPlateType';
import { AvailableProductLine, Roles } from '../../../Models/Enums';
import { IUnitConfiguration } from '../../../Models/IUnitConfiguration';
import { PositionBadge } from '../../Atom/PositionBadge/PositionBadge';
import { getPositionAndProductLineFromConfigurationKey, getUnitConfigurationFromConfigurationKey, getUnitConfigurationKeyAsDashedString } from '../../../Utils/UnitConfigurationHelper';
import { generalStackStyles } from '../../../Models/StackStyling';
import { Badge } from '@fluentui/react-components';
import { Constants } from '../../../Models/Constants';

export const PHEPlateTypeManagement: React.FC<{}> = () => {
    const ctx: IMsalContext = useMsal();
    let theme = useTheme();

    const [isModalOpen, setIsModalOpen] = useState<boolean>();
    const [pheTypeActive, setActivePHEType] = useState<IPHEPlateType>();

    const [currentData, setCurrentData] = useState<IPHEPlateType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isDeleting, setIsDeleting] = useState('');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState('');

    const apiService = useMemo(() => new ApiService(ctx), [ctx]);

    const [availablePositions, setAvailablePositions] = useState<string[]>([]);
    const [masterListOfAvailablePositions, setMasterListOfAvailablePostions] = useState<string[]>([]);
    const [selectedPositions, setSelectedPositions] = useState<string[]>([]);
    const [disabledForPositions, setDisabledForPositions] = useState<any[]>([]);
    const [text, setText] = useState('');
    const [key, setKeys] = useState('');
    const [infoForALICE, setInfoForALICE] = useState('');

    const [allowedPositionTexts, setAllowedTexts] = useState<string[]>([]);
    const [tableColumns, setTableColumns] = useState<IColumn[]>();
    const [availableInALICE, setAvailableInALICE] = useState(false);
    const [industrial, setIndustrial] = useState(false);
    const [hygienic, setHygienic] = useState(false);

    const contentStyles = modalStyles(theme);

    function modalStyles(theme: any) {
        return mergeStyleSets({
            container: {
                display: 'flex',
                flexFlow: 'column nowrap',
                alignItems: 'stretch',
            },
            header: [
                theme.fonts.xLargePlus,
                {
                    flex: '1 1 auto',
                    flexDirection: 'row',
                    borderTop: `4px solid ${theme.palette.themePrimary}`,
                    color: theme.palette.neutralPrimary,
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: FontWeights.bold,
                    padding: '12px 12px 14px 24px',
                    backgroundColor: 'lightgray',
                    flexdirection: 'row'
                },
            ],
            body: {
                display: 'table',
                flex: '4 4 auto',
                borderSpacing: 4,
                padding: '0 24px 24px 24px',
                margin: '4px',
                overflowY: 'hidden',
                selectors: {
                    p: { margin: '14px 0' },
                    'p:first-child': { marginTop: 0 },
                    'p:last-child': { marginBottom: 0 },
                },
            }
        });
    }
    const gridStyles: Partial<IDetailsListStyles> = {
        root: {
            overflowX: 'auto',
            borderRadius: 5,
            marginTop: 20,
            width: '100%',
            boxShadow: theme.effects.elevation4
        }
    }
    const iconButtonStyles = iconModalStyles(theme);
    function iconModalStyles(theme: any) {
        return {
            root: {
                color: theme.palette.neutralPrimary,
                marginLeft: 'auto',
                marginTop: '4px',
                marginRight: '2px',
            },
            rootHovered: {
                color: theme.palette.neutralDark,
            },
        };
    }

    function showModal() {
        setIsModalOpen(true);
    }

    function hideModal() {
        const selected: string[] = [];
        setActivePHEType(undefined);
        setText('');
        setKeys('');
        setSelectedPositions(selected)
        setAvailablePositions(masterListOfAvailablePositions);
        setAllowedTexts(masterListOfAvailablePositions);
        setIsModalOpen(false);
    }

    const editPHEtype = async (item: IPHEPlateType, index: number) => {
        setActivePHEType(item);
        if (!item.isAvailableInALICE)
            setInfoForALICE(item.informationForUser);

        setAvailableInALICE(item.isAvailableInALICE);
        const disDataResponse = await apiService.getAsync(`settings/pheplatetypes/availability/${item.key}`);
        const disData = await disDataResponse.json();
        let disAllowedpos: string[] = [];
        let allowedPos: string[] = [];
        if (disData.length > 0) {
            for (let i = 0; i < disData.length; i++) {
                const element = disData[i];
                let config: IUnitConfiguration = {
                    industry: element.industry,
                    subIndustry: element.subIndustry,
                    application: element.application,
                    subApplication: element.subApplication,
                    productLine: element.productLine,
                    processStage: element.processStage,
                    alfaLavalPosition: element.alfaLavalPosition,
                    hotMediaType: '',
                    coldMediaType: '',
                    breakdownOfProcessStage: '',
                    inputs: [],
                    nonUsedProperties: [],
                    flowType: '',
                    outputs: [],
                    prefferedUnit: '',
                    samplingId: ''
                };
                const configString: string = getUnitConfigurationKeyAsDashedString(config);
                if (!element.isAvailable) {
                    disAllowedpos.push(configString);
                }
                else {
                    allowedPos.push(configString);
                }
            }
            setSelectedPositions(disAllowedpos);
            setAvailablePositions(allowedPos);
            setAllowedTexts(allowedPos);

            setIndustrial(item.availableProductLine == AvailableProductLine.Industrial);
            setHygienic(item.availableProductLine == AvailableProductLine.Hygienic);
        }

        showModal();
    }

    const deletePheType = async (item: IPHEPlateType) => {
        setIsDeleting(item.key);

        const response = await apiService.delete(`settings/pheplatetypes/delete/${item.key}`, {
            industry: item.industry,
            subIndustry: item.subIndustry,
            application: item.application,
            subApplication: item.subApplication,
            processStage: item.processStage,
            productLine: item.productLine,
            alfaLavalPosition: item.alfaLavalPosition,
            breakdownOfProcessStage: '',
            coldMediaType: '',
            hotMediaType: '',
            inputs: [],
            nonUsedProperties: []
        });
        if (!response.ok) {
            setIsDeleting('');
            setShowDeleteConfirmation('');
            const text = response.text();
            throw new Error('Could not delete PHE plate type. ' + text);
        }

        const newPhePlateTypes = currentData.filter((p: IPHEPlateType) => p.key !== item.key);
        setCurrentData(newPhePlateTypes);
        setShowDeleteConfirmation('');
        setIsDeleting('');
    }

    useEffect(() => {

        const getAllUnitConfigs = async () => {
            const response = await apiService.getAsync('settings/unitconfigurations');
            const unitConfigData: IUnitConfiguration[] = await response.json();

            let pos: string[] = [];
            for (let i = 0; i < unitConfigData.length; i++) {
                pos.push(getUnitConfigurationKeyAsDashedString(unitConfigData[i]));
            }
            // unitConfigData.forEach(config => {
            //     pos.push(getUnitConfigurationKeyAsDashedString(config));
            // });

            setMasterListOfAvailablePostions(pos);
            setAvailablePositions(pos);
        }
        getAllPHETypes();
        getAllUnitConfigs();

    }, []);

    const getAllPHETypes = async () => {
        setIsLoading(true);
        const response = await apiService.getAsync('settings/pheplatetypes');
        const data: IPHEPlateType[] = await response.json();

        let disabledFor: any[] = []
        for (let i = 0; i < data.length; i++) {
            const disDataResponse = await apiService.getAsync(`settings/pheplatetypes/availability/${data[i].key}`);
            const disData = await disDataResponse.json();
            disabledFor.push(disData);
            setDisabledForPositions({ ...disabledForPositions, ...disData });
        }

        let allTableColumns: IColumn[] = [
            {
                key: 'text',
                minWidth: 200,
                maxWidth: 200,
                name: 'Display name',
                fieldName: 'text',
                onRender: (item?: IPHEPlateType, index?: number, column?: IColumn) => {
                    return (
                        <TooltipHost content={item?.text}>
                            <code style={{ padding: 4 }}>
                                {item?.text}
                            </code>
                        </TooltipHost>
                    )
                }
            }, {
                key: 'key',
                minWidth: 150,
                maxWidth: 150,
                name: 'Key',
                fieldName: 'key',
                onRender: (item?: IPHEPlateType, index?: number, column?: IColumn) => {
                    return (
                        <TooltipHost content={item?.key}>

                            <code style={{ padding: 4 }}>
                                {item?.key}
                            </code>

                        </TooltipHost>

                    )
                }
            },
            {
                key: 'isAvailableInALICE',
                minWidth: 150,
                maxWidth: 150,
                name: 'Available in ALICE',
                fieldName: 'isAvailableInALICE',
                onRender: (item?: IPHEPlateType, index?: number, column?: IColumn) => {
                    return (
                        <>{item?.isAvailableInALICE ? <span style={{ color: theme.palette.green }} >&#x2714;</span >
                            : <span style={{ color: theme.palette.red }}>&#x2718;</span>
                        }</>
                    )
                }
            },
            {
                key: 'availableProductLine',
                minWidth: 150,
                maxWidth: 150,
                name: 'Alice Configurator',
                fieldName: 'availableProductLine',
                onRender: (item?: IPHEPlateType, index?: number, column?: IColumn) => {
                    return (
                        <>
                            {item?.availableProductLine != null && (
                                <Badge key={`${item?.availableProductLine}-${index}`} appearance='outline' style={{ fontSize: FontSizes.small, marginTop: 1 }}>
                                    {item?.availableProductLine === AvailableProductLine.Hygienic && <code style={{ border: 'none' }}>Hygienic</code>}
                                    {item?.availableProductLine === AvailableProductLine.Industrial && <code style={{ border: 'none' }}>Industrial</code>}
                                </Badge>
                            )}
                        </>
                    )
                }
            },
            {
                key: 'disabledFor',
                minWidth: 150,
                maxWidth: 150,
                name: 'Disabled for',
                fieldName: 'disabledFor',
                isMultiline: true,
                onRender: (item?: any, index?: number, column?: IColumn) => {
                    return (
                        <>
                            {
                                disabledFor[index!].map((x: any) => {
                                    if (x.isAvailable)
                                        return;
                                    return <Badge key={`${x.alfaLavalPosition}-${x.productLine}-${index}`} appearance='outline' style={{ fontSize: FontSizes.small, marginTop: 1 }}>
                                        <code style={{ border: 'none' }}>{x.productLine.substring(0, 3)}</code>| {x.alfaLavalPosition}
                                    </Badge>
                                })
                            }
                        </>
                    )
                }
            }, {
                key: 'edit',
                minWidth: 50,
                maxWidth: 50,
                name: '',
                onRender: (item?: IPHEPlateType, index?: number, column?: IColumn) => {
                    return (
                        <>
                            {
                                <IconButton style={{ color: theme.palette.themePrimary }} iconProps={{ iconName: 'Edit' }} onClick={() => editPHEtype(item!, index!)} />
                            }
                        </>
                    )
                }
            }, {
                key: '',
                minWidth: 50,
                maxWidth: 50,
                name: '',
                onRender: (item?: IPHEPlateType, index?: number, column?: IColumn) => {
                    return (
                        <>
                            {
                                showDeleteConfirmation === item?.key ?
                                    <Spinner size={SpinnerSize.small} />
                                    :
                                    <IconButton style={{ color: theme.palette.red }} iconProps={{ iconName: 'Delete' }} onClick={() => setShowDeleteConfirmation(item?.key!)} />
                            }
                        </>
                    )
                }
            }
        ]

        setTableColumns(allTableColumns);
        setCurrentData(data);
        setIsLoading(false);
    }

    const addNewPHEPlateType = async () => {
        let body: any = {}
        setIsLoading(true);
        let availableProductLine: any;
        if (industrial) {
            availableProductLine = AvailableProductLine.Industrial;
        }
        else if (hygienic) {
            availableProductLine = AvailableProductLine.Hygienic;
        }

        if (pheTypeActive === undefined) {
            body.PhePlateData = {
                text: text,
                key: key,
                isAvailableInALICE: availableInALICE,
                informationForUser: infoForALICE,
                AvailableProductLine: availableProductLine
            }
        } else {
            body.PhePlateData = {
                id: pheTypeActive.id,
                text: pheTypeActive.text,
                key: pheTypeActive.key,
                isAvailableInALICE: availableInALICE,
                informationForUser: infoForALICE,
                AvailableProductLine: availableProductLine
            }
        }

        body.UnitConfigurationsToDisallow = selectedPositions.map((x) => {
            const unitConfigFromKey: IUnitConfiguration = getUnitConfigurationFromConfigurationKey(x);
            return {
                industry: unitConfigFromKey.industry,
                subIndustry: unitConfigFromKey.subIndustry,
                application: unitConfigFromKey.application,
                subApplication: unitConfigFromKey.subApplication,
                processStage: unitConfigFromKey.processStage,
                productLine: unitConfigFromKey.productLine,
                alfaLavalPosition: unitConfigFromKey.alfaLavalPosition
            }
        })

        hideModal();

        const response = await apiService.postAsync(body, `settings/pheplatetypes`);
        if (!response.ok) {
            const res = await response.text();
            setIsLoading(false);
            throw Error(`Could not send response. ${response.status} ${res}`);
        }

        getAllPHETypes();
    }



    const handleSelection = (item: string, selected: boolean) => {
        if (selected) {
            setSelectedPositions([...selectedPositions, item]);
            setAvailablePositions(availablePositions.filter((pos: string) => pos !== item));
        }
        else {
            setSelectedPositions(selectedPositions.filter((pos: string) => pos !== item));
            setAvailablePositions([...availablePositions, item]);
        }
    }

    function setDisableSubmit() {
        return ((pheTypeActive === undefined && (text === '' || key === '')) || (pheTypeActive !== undefined && JSON.stringify(allowedPositionTexts.sort()) == JSON.stringify(availablePositions.sort())))
    }

    const handleIndustrialChange = () => {
        setIndustrial(!industrial);
        setHygienic(false); // Uncheck the other checkbox
    };

    const handleHygienicChange = () => {
        setHygienic(!hygienic);
        setIndustrial(false); // Uncheck the other checkbox
    };

    return (
        <Stack horizontal>
            <Stack.Item style={{ padding: '10px' }}>
                <PrimaryButton
                    text='Create new PHE plate type'
                    iconProps={{ iconName: 'Add' }}
                    onClick={() => { showModal() }}
                    disabled={isLoading} />
                <Modal titleAriaId="id" isOpen={isModalOpen === true} isModeless={false} onDismiss={hideModal} isBlocking={true} isDarkOverlay={true}
                    containerClassName={contentStyles.container} >
                    <Stack style={{ minWidth: 400, maxWidth: 1200, width: '80vw' }}>

                        <Stack grow className={contentStyles.header}>
                            {
                                pheTypeActive !== undefined ?
                                    <Label>Edit {pheTypeActive.key} PHE type</Label>
                                    :
                                    <Label>New PHE Plate type</Label>
                            }

                            <IconButton styles={iconButtonStyles} iconProps={{ iconName: 'Cancel' }} ariaLabel="Close"
                                style={{ float: 'right' }} onClick={hideModal} />
                        </Stack>

                        <Stack style={{ padding: 5 }}>
                            <Stack.Item>
                                <Label>PHE type <code>disabled</code> for</Label>
                            </Stack.Item>
                            <Stack horizontal style={{ overflowX: 'scroll' }}>
                                {
                                    selectedPositions.length !== 0 ?
                                        selectedPositions.map((pos: string, index: number) => {
                                            return (
                                                <PositionBadge
                                                    key={`${pos}-${index}`}
                                                    onClick={() => handleSelection(pos, false)}
                                                    type={getPositionAndProductLineFromConfigurationKey(pos).productLine}
                                                    name={getPositionAndProductLineFromConfigurationKey(pos).position}
                                                    background={theme.palette.themeLight} fontColor='white' />
                                            )
                                        })
                                        :
                                        <p style={{ fontSize: FontSizes.small }}>None selected</p>
                                }
                            </Stack>
                            <Stack.Item>
                                <Label>PHE type selectable for</Label>
                            </Stack.Item>
                            <Stack horizontal style={{ overflowX: 'scroll' }}>
                                {
                                    availablePositions.map((pos: string, index: number) => {
                                        return (
                                            <PositionBadge
                                                key={`${pos}-${index}`}
                                                onClick={() => handleSelection(pos, true)}
                                                type={getPositionAndProductLineFromConfigurationKey(pos).productLine}
                                                name={getPositionAndProductLineFromConfigurationKey(pos).position}
                                                background={theme.palette.themePrimary} fontColor='white' />
                                        )
                                    })
                                }
                            </Stack>
                            <Stack horizontal={false} styles={generalStackStyles(theme)}>
                                <Stack horizontal>
                                    <Stack.Item grow style={{ padding: 5 }}>
                                        <Label required>Text (display name)</Label>
                                        {pheTypeActive !== undefined ?
                                            <TextField style={{ height: '30px', fontSize: '14' }} value={pheTypeActive.text} readOnly={true}></TextField>
                                            : <TextField style={{ height: '30px', fontSize: '14' }} onChange={(e, i) => setText(i!.toString())}></TextField>
                                        }
                                    </Stack.Item>
                                    <Stack.Item grow style={{ padding: 5 }}>
                                        <Label required>Key (database key)</Label>
                                        {pheTypeActive !== undefined ?
                                            <TextField style={{ height: '30px', fontSize: '14' }} value={pheTypeActive.key} readOnly={true} ></TextField>
                                            :
                                            <TextField style={{ height: '30px', fontSize: '14' }} onChange={(e, i) => setKeys(i!.toString())}></TextField>
                                        }
                                    </Stack.Item>
                                </Stack>
                                <Stack horizontal>
                                    <Stack.Item grow style={{ padding: 5 }} >

                                    </Stack.Item>
                                </Stack>
                                <Stack horizontal>

                                    <Stack.Item grow style={{ padding: 5 }}>
                                        <Stack horizontal grow>
                                            <Checkbox styles={{ root: { marginTop: 5 } }} label='Is available in ALICE?' checked={availableInALICE} onChange={(e) => {
                                                setAvailableInALICE(!availableInALICE);
                                            }} />
                                            <Stack horizontal grow horizontalAlign='end'>
                                                <Checkbox
                                                    styles={{ root: { marginTop: 5, marginRight: 5 } }}
                                                    label='Is Industrial'
                                                    checked={industrial}
                                                    onChange={handleIndustrialChange}
                                                />
                                                <Checkbox
                                                    styles={{ root: { marginTop: 5, marginRight: 5 } }}
                                                    label='Is Hygienic'
                                                    checked={hygienic}
                                                    onChange={handleHygienicChange}
                                                />
                                            </Stack>
                                        </Stack>
                                        <Stack horizontal>
                                            {!availableInALICE &&
                                                <Stack.Item grow style={{ padding: 5 }}>
                                                    <Label>General message for user</Label>
                                                    <TextField
                                                        value={infoForALICE}
                                                        multiline={true}
                                                        style={{ height: '30px', fontSize: 14 }}
                                                        onChange={(e, i) => setInfoForALICE(i!.toString())} />
                                                    <Stack>
                                                        <Label style={{ fontSize: 12 }}>Default message</Label>
                                                        <p style={{ fontSize: 12 }}>{`${Constants.XMLCopyDefaultMessage.replace('{pheType}', pheTypeActive?.text ?? text)}`}</p>
                                                    </Stack>
                                                </Stack.Item>
                                            }
                                        </Stack>

                                    </Stack.Item>
                                </Stack>
                            </Stack>
                            <div style={{ textAlign: 'center', marginBottom: 12 }}>
                                <PrimaryButton
                                    onClick={addNewPHEPlateType} type='submit' text='Submit' iconProps={{ iconName: 'CheckMark' }} />
                            </div>
                        </Stack>
                    </Stack>

                </Modal>
                {
                    isLoading === true ?
                        <Spinner className='loading-spinner' size={SpinnerSize.large} label={'Loading...'} />
                        :
                        <>
                            <DetailsList items={currentData ?? []}
                                styles={gridStyles}
                                columns={tableColumns}
                                checkboxVisibility={CheckboxVisibility.hidden} />
                        </>
                }
                {
                    showDeleteConfirmation &&
                    <Dialog hidden={false} dialogContentProps={{
                        type: DialogType.normal,
                        title: 'Delete PHE Type "' + showDeleteConfirmation + '"',
                    }}>
                        <Stack>
                            <Stack.Item>
                                Are you sure you want to delete PHE type {showDeleteConfirmation}? This action cannot be undone.
                            </Stack.Item>
                            <Stack horizontalAlign='center' horizontal style={{ padding: 10, marginTop: 15 }}>
                                {
                                    isDeleting !== '' ?
                                        <Spinner size={SpinnerSize.large} label='Deleting PHE type...' />
                                        :
                                        <>
                                            <Stack.Item>
                                                <DefaultButton text='Cancel' onClick={() => setShowDeleteConfirmation('')} />
                                            </Stack.Item>
                                            <Stack.Item style={{ marginLeft: 5 }}>
                                                <PrimaryButton text='Delete' onClick={() => deletePheType(currentData.find(x => x.key === showDeleteConfirmation)!)} />
                                            </Stack.Item>
                                        </>
                                }
                            </Stack>
                        </Stack>
                    </Dialog>
                }
            </Stack.Item>
        </Stack>

    );
}
